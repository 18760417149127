import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


import { LayoutComponent } from 'src/app/components/layout/layout.component';
import { SsoComponent } from 'src/app/components/sso/sso.component';
import { HomeComponent } from 'src/app/components/routecontent/home/home.component';
import { RegisterComponent } from 'src/app/components/routecontent/register/register.component';
import { ErrorComponent } from 'src/app/components/error/error.component';

import { AuthGuard, NoSSOGuard, RegistrationStepGuard } from 'src/app/utility/auth.guard';
import { CheckcoverageComponent } from './components/routecontent/checkcoverage/checkcoverage.component';
import { coverageRoutes, coverageRoutes2, manageprescribers, prescriberprofile, userprovision } from './modules/checkcoverage/checkcoverage.module';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { TermsofuseComponent } from './components/termsofuse/termsofuse.component';
import { ContactusComponent } from './components/contactus/contactus.component';
import { registrationRoutes } from './modules/registration/registration.module';
import { NotificationspageComponent } from './components/notificationspage/notificationspage.component';
import { UserProvisioningComponent } from './components/user-provisioning/user-provisioning.component';
import { SearchMemberComponent } from './modules/history/search-member/search-member.component';
//import { searchmemberRoute } from './modules/history/history.module';
import { MemberDetailComponent } from './components/member-detail/member-detail.component';
//import { ViewaallComponent } from './components/routecontent/viewaall/viewaall.component';
// Remove the duplicate import statement for 'PaDetailsComponent'
import { ViewallpaComponent } from './components/routecontent/viewallpa/viewallpa.component';
import { SubmittedpahistoryComponent } from './components/submittedpahistory/submittedpahistory.component';
import { SavedpahistoryComponent } from './components/savedpahistory/savedpahistory.component';
import { InitiaPAComponent } from './components/initia-pa/initia-pa.component';
import { initiatePriorAuthorizationPARoutes } from './modules/ePA/epa.module';
import { EpaComponent } from './components/routecontent/epa/epa.component';
import { PaDetailsComponent } from './components/pa-details/pa-details.component';
import { BenefitinquiryhomepageComponent } from './components/benefitinquiryhomepage/benefitinquiryhomepage.component';
import { BenefitinquirymanualflowComponent } from './components/benefitinquirymanualflow/benefitinquirymanualflow.component';
import { benefitinquiryroutes } from './modules/benefitinquiry/benefitinquiry.module';
import { BenefitinquiryComponent } from './components/benefitinquiry/benefitinquiry.component';
import { ManageprescribersComponent } from './components/manageprescribers/manageprescribers.component';
import { AddressbkComponent } from './components/addressbk/addressbk.component';
import { ProvisioningComponent } from './components/provisioning/provisioning.component';
// Remove the duplicate import statement for 'MemberDetailComponent'
// import { MemberDetailComponent } from './components/member-detail/member-detail.component';
import { SearchMedicationClaimsComponent } from './components/search-medication-claims/search-medication-claims.component';
import { InternalcontrolpannelComponent } from './components/internalcontrolpannel/internalcontrolpannel.component';
import { PrescriberprofileComponent } from './components/prescriberprofile/prescriberprofile.component';
import { VerifyPrescribersComponent } from './components/verify-prescribers/verify-prescribers.component';
import { searchMediactionClaimsRoutes } from './modules/history/history.module';
import { notificationsRoutes } from './modules/notifications/notifications.module';
import { DeletednotificationpageComponent } from './components/deletednotificationpage/deletednotificationpage.component';
import { NotificationsComponent } from './components/routecontent/notifications/notifications.component';
import { BannermsgComponent } from './components/bannermsg/bannermsg.component';
//import { SrchmemberComponent } from './components/srchmember/srchmember.component';

const routes: Routes = [
  {
    path: 'sso',
    component: SsoComponent,
    canActivate: [NoSSOGuard]
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    data: { breadcrumb: { label: 'Home' } },
    children: [
      { path: '', redirectTo: '/home', pathMatch: 'full' },
      { path: 'Home', redirectTo: '/home', pathMatch: 'full' },
      { path: 'home', component: HomeComponent, data: { breadcrumb: { skip: true } } },
      { path: 'PriorAuthorizationDetails', component: PaDetailsComponent, data: { breadcrumb: { label: 'Prior authorization history' } } },
      { path: 'ViewAllSubmittedPAs', component: ViewallpaComponent, data: { breadcrumb: { label: 'View All Submitted PAs' } } },
      { path: 'ViewAllSavedPAs', component: ViewallpaComponent, data: { breadcrumb: { label: 'View All Saved PAs' } } },
      { path: 'bannerPannel', component: BannermsgComponent, data: { breadcrumb: { label: 'Banner Pannel' } } },

      {
        path: 'PriorAuthChecker',
        data: { breadcrumb: { skip: true } },
        component: CheckcoverageComponent,
        //loadChildren is ideal way of loading children especially child routes are defined in its own module.
        //But here still children array is being used since this loadchildren not working with our custom breadcrum component.
        children: [
          ...coverageRoutes
        ],

        // loadChildren: () => CheckcoverageModule
      },
      {
        path: 'PriorAuthchecker',
        data: { breadcrumb: { skip: true } },
        component: CheckcoverageComponent,
        //loadChildren is ideal way of loading children especially child routes are defined in its own module.
        //But here still children array is being used since this loadchildren not working with our custom breadcrum component.
        children: [
          ...coverageRoutes2
        ],
        // loadChildren: () => CheckcoverageModule
      },
      {
        path: 'notifications',
        component: NotificationsComponent,
        data: { breadcrumb: { skip: true } },
        children: [
          ...notificationsRoutes
        ],
      },

      // Removed as per the user story US7534822     
      // {
      //   path: 'benefitinquiry',
      //   data: { breadcrumb: { skip: true } },
      //   component: BenefitinquiryComponent,
      //   children: [
      //     ...benefitinquiryroutes
      //   ],
      // },
      {
        path: 'ePA',
        data: { breadcrumb: { skip: true } },
        component: EpaComponent,
        //loadChildren is ideal way of loading children especially child routes are defined in its own module.
        //But here still children array is being used since this loadchildren not working with our custom breadcrum component.
        children: [
          ...initiatePriorAuthorizationPARoutes
        ],
        // loadChildren: () => CheckcoverageModule
      },
      // { path: 'ViewAll', component: NotificationspageComponent },
      // {path:'benefitinquiry/home',component:BenefitinquiryhomepageComponent},      
      {
        path: 'benefitinquiry',
        data: { breadcrumb: { skip: true } },
        component: BenefitinquiryComponent,
      },
      {
        //path:'MemberSearch',
        path: 'searchMedicationClaims',
        data: { breadcrumb: { skip: true } },
        component: SearchMedicationClaimsComponent,
        children: [
          ...searchMediactionClaimsRoutes
        ],
      },

      {
        path: 'provision',
        data: { breadcrumb: { skip: true } },
        component: ProvisioningComponent,
        //loadChildren is ideal way of loading children especially child routes are defined in its own module.
        //But here still children array is being used since this loadchildren not working with our custom breadcrum component.
        children: [
          ...userprovision
        ],
        // loadChildren: () => CheckcoverageModule
      },
      {
        path: 'prescribers',
        data: { breadcrumb: { skip: true } },
        component: AddressbkComponent,
        children: [
          ...manageprescribers
        ]
      },
      { path: 'ControlPanel', component: InternalcontrolpannelComponent, data: { breadcrumb: { label:'Control Panel' } } },
      {
        path: 'prescriber',
        data: { breadcrumb: { skip: true } },
        component: PrescriberprofileComponent,
        children: [
          ...prescriberprofile
        ]
      },
    ]
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [RegistrationStepGuard],
    //loadChildren: ()=>import('src/app/modules/registration/registration.module').then(module=>module.RegistrationModule)
    children: [
      ...registrationRoutes
    ],
  },
  { path: 'verifyProvider', component: VerifyPrescribersComponent, canActivate: [AuthGuard], },
  {
    path: 'error',
    component: ErrorComponent,
    canActivate: [NoSSOGuard]
  },
  { path: 'contactus', component: ContactusComponent,canActivate: [AuthGuard], },
  { path: 'terms', component: TermsofuseComponent,canActivate: [AuthGuard], },
  { path: 'privacy', component: PrivacyComponent,canActivate: [AuthGuard], },
  // ,
  // {
  //   path: 'privacy',
  //   component: PrivacyComponent
  // },
  // {
  //   path: 'terms',
  //   component: TermsofuseComponent
  // },
  // {
  //   path: 'contactus',
  //   component: ContactusComponent
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
