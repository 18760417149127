import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MemberPAHistorywebrequest } from 'src/app/modals/pahistory/pahistorywebrequest';
import { environment } from 'src/environments/environment';

import { Router } from '@angular/router';
import { DataTableType, TableCellData, TableClickEvent, TableHeaderCellData, TableRowData } from 'src/app/modals/datatable/datatable';
import { PAHistorywebresponse, PAResponseHistory } from 'src/app/modals/pahistory/pahistorywebresponse';
import { Drugsearchwebrequest, MemberSearchItem } from 'src/app/modals/drugSearch/drugsearchwebrequest';
import { MemberSearchV5Item } from 'src/app/modals/membersearch/membersearchwebresponse';
import { VIEWALL_SUBMITTEDPA_STATUS, VIEWALL_SUBMITTEDPA_STATUS_LABELS} from 'src/app/constants/appconstants';
import { PahistoryService } from 'src/app/services/pahistory/pahistory.service';
import { MembersearchService } from 'src/app/services/membersearch/membersearch.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { MemberDetailService } from 'src/app/services/memberdetail/memberdetail.service';
import { DatetoDisplayString, StandardDateToServertime } from 'src/app/pipes/dateconversion/dateconversion.pipe';
import { FilterobjectsByTextPipe } from 'src/app/pipes/filterobjects/filterobjects.pipe';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { StringToSentenceCase } from 'src/app/pipes/stringconvertions/stringconversions.pipe';
import { Drugsearchwebresponse } from 'src/app/modals/drugSearch/drugsearchwebresponse';
import { SavedPAResponse } from 'src/app/modals/epa/priorauthresponse';

@Component({
  selector: 'membersubmittedpahistory',
  templateUrl: './member-submitted-pahistory.component.html',
  styleUrls: ['./member-submitted-pahistory.component.css']
})
export class MemberSubmittedPAHistoryComponent implements OnInit {

  submittedPaHeaders: TableHeaderCellData[];
  submittedPaHistory: TableRowData<PAResponseHistory>[];
  private sourceData: PAResponseHistory[];
  datatableType: DataTableType = DataTableType.TRANSPARENT;
  blnLoading: boolean;
  blnServerError: boolean;

  totalPages: number;
  currentPage: number;
  recordsPerPage: number = environment.paginationRecordsPerPage;
  totalEntries: number=0;;

  quesArry:any;
  questionArry:any;
  answArry:any;
  attachmentArry:any;
  private firstHeader: TableHeaderCellData = new TableHeaderCellData();
  private secondHeader: TableHeaderCellData = new TableHeaderCellData();
  private thirdHeader: TableHeaderCellData = new TableHeaderCellData();
  private forthHeader: TableHeaderCellData = new TableHeaderCellData();
  private fifthHeader: TableHeaderCellData = new TableHeaderCellData();
 // private sixthHeader: TableHeaderCellData = new TableHeaderCellData();

  memberSearchResult!: MemberSearchV5Item | MemberSearchItem | undefined;


  @ViewChild("memberNameTemplate", { static: true })
  memberNameTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("drugNameTemplate", { static: true })
  drugNameTemplateRef?: TemplateRef<HTMLElement>

  @ViewChild("prescriberNameTemplate", { static: true })
  prescriberNameTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("caseIdTemplate", { static: true })
  caseIdTemplateRef?: TemplateRef<HTMLElement>;
  
  @ViewChild("dateCreatedTemplate",{static:true})
  dateCreatedTemplateRef?:TemplateRef<HTMLElement>;

  @ViewChild("startDateTemplate", { static: true })
  startDateTemplate?: TemplateRef<HTMLElement>;

  @ViewChild("endDateTemplate", { static: true })
  endDateTemplate?: TemplateRef<HTMLElement>;

  @ViewChild("paCancelledTemplate", { static: true })
  paCancelledTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("paApprovedTemplate", { static: true })
  paApprovedTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("paDeniedTemplate", { static: true })
  paDeniedTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("paPendingTemplate", { static: true })
  paPendingTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("paPaidTemplate", { static: true })
  paPaidTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("paReversedTemplate", { static: true })
  paReversedTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("historyServerErrorTemplate", { static: true })
  historyServerErrorTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("errorPopup", { static: true })
  errorPopupTemplateRef?: TemplateRef<HTMLElement>;

  @Input()
  memberId!: string;

  @Input()
  searchText: string = "";
  private fieldsToConsiderForFilteration: string[];

  @Input()
  filterByStatus: VIEWALL_SUBMITTEDPA_STATUS = VIEWALL_SUBMITTEDPA_STATUS.ALL;

  private _fromDate: Date;
 
  public get fromDate(): Date {
    return this._fromDate;
  }
  @Input()
  public set fromDate(v: Date) {
    this._fromDate = v;
  }


  private _toDate: Date;
  public get toDate(): Date {
    return this._toDate;
  }
  @Input()
  public set toDate(v: Date) {
    this._toDate = v;
  }
  changeTimeformat(time: string) {
    var year = time.slice(0, 4);
    var month = time.slice(4, 6);
    var day = time.slice(7, 10);
    return month + '/' + day + '/' + year;
  }

  @Input()
  maxResults!: number;

  constructor(private pahistory: PahistoryService,
    private memberSearchHelper: MembersearchService,
    private loginProfile: ProfileService,
    private drugSearchHelper: MemberDetailService,
    private dateToDisplayStringConversion: DatetoDisplayString,
    private standardDateToServertime:StandardDateToServertime,
    private filterobjectsByTextPipe: FilterobjectsByTextPipe,
    private logger:LoggerService,
    private router: Router,
    private stringToSentenceCase: StringToSentenceCase, private modalService: NgbModal) {

      this.firstHeader.content = "Medication/productID";
      this.firstHeader.sortable = true;
  
      this.secondHeader.content = "Case ID";
  
      this.thirdHeader.content = "Start Date";
      this.thirdHeader.sortable = true;
      this.thirdHeader.selectedForSort = true;
  
      this.forthHeader.content = "End Date";
      this.forthHeader.sortable = true;
      this.thirdHeader.selectedForSort = true;
  
      this.fifthHeader.content = "Status";

    this.submittedPaHeaders = [];
    this.submittedPaHeaders.push(this.firstHeader);
    this.submittedPaHeaders.push(this.secondHeader);
    this.submittedPaHeaders.push(this.thirdHeader);
    this.submittedPaHeaders.push(this.forthHeader);
    this.submittedPaHeaders.push(this.fifthHeader);
   // this.submittedPaHeaders.push(this.sixthHeader);

    this.submittedPaHistory = [];



    //Following dates are used to fetch history from server.
    let currentDate: Date = new Date();
    //Represent 12AM of next date. Meaning, It considers complete 24h of Today.
    currentDate.setDate(currentDate.getDate() + 1);
    this._toDate = currentDate;

    //Resenting to represent today's date.
    currentDate = new Date();
    //This history will be defaulted to last 30 days from now if no dates are supplied.
    currentDate.setDate(currentDate.getDate() - 179);
    this._fromDate = currentDate;

    this.totalPages = 1;
    this.currentPage = 1
    this.blnLoading = false;
    this.blnServerError = false;
    this.sourceData = [];
    this.fieldsToConsiderForFilteration = [];
    this.fieldsToConsiderForFilteration.push("lastName");
    this.fieldsToConsiderForFilteration.push("firstName");
    this.fieldsToConsiderForFilteration.push("drugName");
    this.fieldsToConsiderForFilteration.push("presbrLstName");
    this.fieldsToConsiderForFilteration.push("presbrFirstName");
    this.fieldsToConsiderForFilteration.push("caseId");
    this.fieldsToConsiderForFilteration.push("dateSubmitted");
    this.fieldsToConsiderForFilteration.push("paStatus");
  }

  ngOnInit(): void {
    this.memberSearchResult = this.memberSearchHelper.getSelectedMember();
    this.reload();
  }

  reload(blnHardReload: boolean = true): void {
    if (blnHardReload) {
      let webrequest: MemberPAHistorywebrequest = new MemberPAHistorywebrequest();
      webrequest.fromDate = this.dateToDisplayStringConversion.transform(this.fromDate);
      webrequest.toDate = this.dateToDisplayStringConversion.transform(this.toDate);
      webrequest.memberDOB = this.memberSearchResult?.dateOfBirth.indexOf('/')!=-1?this.standardDateToServertime.transform(""+this.memberSearchResult?.dateOfBirth):this.memberSearchResult?.dateOfBirth;
      webrequest.memberFirstName = this.memberSearchResult?.firstName;
      webrequest.memberLastName = this.memberSearchResult?.lastName;
      webrequest.memberId = this.memberSearchResult?.memberId;
      webrequest.instanceId = this.memberSearchResult?.instanceId;
      webrequest.carrierId = this.memberSearchResult?.carrierId;
      webrequest.groupId = this.memberSearchResult?.groupId;
      webrequest.accountId = this.memberSearchResult?.accountId;
      webrequest.maxResults = this.maxResults; 
      webrequest.resultsFromPAS = true;
      webrequest.providerId = ""+this.loginProfile.loginuser?.prescriber?.uuid;
      if(this.loginProfile.loginuser.prescriber?.roleTypeId==20){
            webrequest.adminPresbrID= this.loginProfile.loginuser.prescriber?.presbrId; 
      }
      webrequest.providerNPI= ""+this.loginProfile.loginuser?.prescriber?.npi;
      this.blnLoading = true;
      this.blnServerError = false;
      this.sourceData = [];
      this.resetPaginationDefaults();
      this.pahistory.getMemberSubmittedPAHistory(webrequest).then((response: PAHistorywebresponse) => {
        this.blnLoading = false;
        for (let i = 0; i < response.paResponseHistory.length; i++) {
        if (this.memberId != undefined) {
          this.sourceData = response.paResponseHistory.filter((item) => item.memberId == this.memberId);
          response.paResponseHistory[i].firstName=''+this.memberSearchResult?.firstName?.toLowerCase();
          response.paResponseHistory[i].lastName=''+this.memberSearchResult?.lastName?.toLowerCase();
          response.paResponseHistory[i].dateSubmitted = this.changeTimeformat(response.paResponseHistory[i].dateSubmitted)
          response.paResponseHistory[i].presbrLstName = response.paResponseHistory[i].prescriberName;
          if(this.sourceData[i].paStatus=='Resolved-Denied'){
            this.sourceData[i].paStatus='Denied';
          }
        } else {
          this.sourceData = response.paResponseHistory;
          response.paResponseHistory[i].firstName=''+this.memberSearchResult?.firstName?.toLowerCase();
          response.paResponseHistory[i].lastName=''+this.memberSearchResult?.lastName?.toLowerCase();
          response.paResponseHistory[i].dateSubmitted = this.changeTimeformat(response.paResponseHistory[i].dateSubmitted)
          response.paResponseHistory[i].presbrLstName = response.paResponseHistory[i].prescriberName;
          if(this.sourceData[i].paStatus=='Resolved-Denied'){
            this.sourceData[i].paStatus='Denied';
          }
        }
      }
        //console.log("Member PA history:");
        //console.log(this.sourceData);
         this.maxResults=this.sourceData.length;
        for(let i=0;i<this.sourceData.length;i++) {

          let request: Drugsearchwebrequest = new Drugsearchwebrequest();
          request.ndc = this.sourceData[i].productId;
          request.instanceId = ""+this.memberSearchHelper.getSelectedMember()?.instanceId;
          this.drugSearchHelper.getDrugSearchItems(request).then((response: Drugsearchwebresponse) => {
            debugger;
            //console.log("Selected ITem:"+response.response.drugSearchItems[0].detailedDrugName);
            //console.log(response);
            //console.log(this.sourceData[i]);
            this.sourceData[i].drugName =''+response.response.drugSearchItems[0].detailedDrugName;
            this.sourceData[i].firstName=''+this.memberSearchResult?.firstName;
            this.sourceData[i].lastName=''+this.memberSearchResult?.lastName;
          });
        }
        this.loadHistory();
      }, (error: any) => {
        //console.log("Member PA history Error:");
        this.submittedPaHistory = [];
        this.blnLoading = false;
        this.blnServerError = true;
        this.loadHistory();
      }).catch((reason: any) => {
        //console.log("Member PA historyCatch:");
        this.submittedPaHistory = [];
        this.blnLoading = false;
        this.blnServerError = true;
        this.loadHistory();
      });
    } else {
      this.loadHistory();
    }
  }
  private filterSourceDataByStatus(): PAResponseHistory[] {
    return this.sourceData.filter((history: PAResponseHistory) => {
      if (parseInt(this.filterByStatus.toString()) == VIEWALL_SUBMITTEDPA_STATUS.ALL)
        return true;
      if (history.paStatus != undefined && history.paStatus.length > 0)
        return VIEWALL_SUBMITTEDPA_STATUS_LABELS.get(parseInt(this.filterByStatus.toString())) == history.paStatus
          || VIEWALL_SUBMITTEDPA_STATUS_LABELS.get(parseInt(this.filterByStatus.toString())) == this.stringToSentenceCase.transform(history.paStatus);
      else
        return false;
    });
  }
  private resetPaginationDefaults(): void {
    this.currentPage = 1;
    this.totalPages = 1;
  }
  private readCurrentPagePaHistory(): PAResponseHistory[] {
    const filteredSourceData: PAResponseHistory[] = this.filterobjectsByTextPipe.transform(this.filterSourceDataByStatus(), this.searchText, this.fieldsToConsiderForFilteration);

    if (this.maxResults == undefined) {
      const startIndex: number = (this.currentPage - 1) * this.recordsPerPage;
      const endIndex: number = (this.currentPage * this.recordsPerPage);
      this.totalPages = (filteredSourceData.length % this.recordsPerPage) != 0 ? Math.floor(filteredSourceData.length / this.recordsPerPage) + 1 : Math.floor(filteredSourceData.length / this.recordsPerPage);

      return filteredSourceData.slice(startIndex, endIndex);
    } else {
      return filteredSourceData;
    }

  }
  private loadHistory(): void {
    this.submittedPaHistory = [];
    if (!this.blnServerError) {
      this.readCurrentPagePaHistory().forEach((history: PAResponseHistory) => {

        const row: TableRowData<PAResponseHistory> = new TableRowData<PAResponseHistory>();

        const firstCell: TableCellData<PAResponseHistory> = new TableCellData();
        firstCell.data = history;
        firstCell.sortable = true;
        firstCell.template = this.drugNameTemplateRef;
        row.cells.push(firstCell);

        const secondCell: TableCellData<PAResponseHistory> = new TableCellData();
        secondCell.data = history;
        secondCell.template = this.caseIdTemplateRef;
        row.cells.push(secondCell);

        const thirdCell: TableCellData<PAResponseHistory> = new TableCellData();
        thirdCell.data = history;
        thirdCell.template =  this.dateCreatedTemplateRef;
        row.cells.push(thirdCell);

        const fourCell: TableCellData<PAResponseHistory> = new TableCellData();
        fourCell.data = history;
        fourCell.template = this.endDateTemplate;
        row.cells.push(fourCell);
        const fifthCell: TableCellData<PAResponseHistory> = new TableCellData();
        fifthCell.data = history;
        switch (history.paStatus?.toLowerCase()) {
          case "cancelled":
          case "closed":
            fifthCell.template = this.paCancelledTemplateRef;
            break;
          case "approved":
            fifthCell.template = this.paApprovedTemplateRef;
            break;
          case "denied":
            fifthCell.template = this.paDeniedTemplateRef;
            break;
          case "resolved-denied":
            fifthCell.template = this.paDeniedTemplateRef;
            break;
          case "pending":
            fifthCell.template = this.paPaidTemplateRef;
            break;
          case "paid":
            fifthCell.template = this.paPaidTemplateRef;
            break;
          case "reversed":
            fifthCell.template = this.paReversedTemplateRef;
            break;
        }
        row.cells.push(fifthCell);

        this.submittedPaHistory.push(row);
      });
    } else {
      this.datatableType = DataTableType.TRANSPARENT;
      const row: TableRowData<PAResponseHistory> = new TableRowData<PAResponseHistory>();
      row.disabled = true;
      row.cssClass = "norow";

      const firstCell: TableCellData<PAResponseHistory> = new TableCellData<PAResponseHistory>();
      firstCell.template = this.historyServerErrorTemplateRef;
      firstCell.clickable = false;
      firstCell.colspan = this.submittedPaHeaders.length;
      row.cells.push(firstCell);
      this.submittedPaHistory.push(row);
    }

    //console.log("TableData");
    //console.log(this.submittedPaHistory);


  }
  onHeaderClick(headerData: TableClickEvent<TableHeaderCellData>) {
  }
 /* onCellClick(clickEvent: TableClickEvent<PAResponseHistory>) {
    if (clickEvent.event.target.getAttribute("name") == "tryagain") {
      this.reload();
    }
  }*/
  onCellClick(clickEvent:TableClickEvent<PAResponseHistory>){
    // debugger;
    if(clickEvent.event.target.getAttribute("name")=="tryagain"){
      this.reload();
    }
    // if(clickEvent.column == 0 || clickEvent.column == 1 || clickEvent.column == 2 || clickEvent.column == 3 || clickEvent.column == 4 || clickEvent.column == 5){
     
     this.logger.userAction("PA Selected");
      sessionStorage.setItem("pAdetails",JSON.stringify(clickEvent.cell?.data));
      this.drugSearchHelper.resumeCellClickPA(""+clickEvent.cell?.data?.caseId).then((response:SavedPAResponse) => {
        
        if(response.paInitiationResponseWeb.body==undefined){
          
          this.quesArry = [];
        }
        else {
     this.quesArry= response.paInitiationResponseWeb.body.parequest.request.solicitedModel.questionSet.question;
       }
     //console.log("data",response);
     if(response.paResumeDetails!=undefined){
       
     this.attachmentArry = response.paResumeDetails.paAttachment;
     //console.log("attachments",this.attachmentArry);
     }
     else{
       this.attachmentArry=[];
     }
     //console.log("quesArry",this.quesArry);
     sessionStorage.setItem("Array",JSON.stringify(this.quesArry));
    //sessionStorage.setItem("Modal",JSON.stringify(response.paInitiationResponseWeb.body.parequest.request.solicitedModel.questionSet));
     sessionStorage.setItem("Attachements",JSON.stringify(this.attachmentArry));
     this.router.navigateByUrl("/PriorAuthorizationDetails");
      
  });
  // }
  
}
onPageChange(pageNumber: any) {
  this.currentPage = pageNumber;
  this.loadHistory();
}

onChangeEntriesPerPage(entriesPerPage: any){
  this.recordsPerPage = entriesPerPage;
  this.currentPage = 1;
  this.loadHistory();
}
}
