import { Component, OnInit } from '@angular/core';

import { ProfileService, Features } from 'src/app/services/profile/profile.service';
import { USER_ROLE } from 'src/app/constants/appconstants';
import { Router } from '@angular/router';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'globalmenu',
  templateUrl: './globalmenu.component.html',
  styleUrls: ['./globalmenu.component.css']
})
export class GlobalmenuComponent {
  blnOpened: boolean = false;
  blnMenuRequired: boolean = true;
  blnAdvisor:boolean=false;
  features!: Features;
  constructor(private profile: ProfileService, private loggerService: LoggerService, protected router: Router, private http: HttpClient) {
    this.features = profile.features;
    // this.blnMenuRequired = profile.USER_ROLE == USER_ROLE.ADVISOR ? false : true;
    this.blnAdvisor=profile.USER_ROLE == USER_ROLE.ADVISOR ? true : false;
  }

  goto(routeUrl: string) {
    debugger;
    this.blnOpened = false;
    this.loggerService.userAction("Going to " + routeUrl);
    this.router.navigateByUrl("/" + routeUrl);
  }
  goToContactusLink() {
    // this.router.navigateByUrl(url);
    window.open(environment.contactusurl, "_blank");
  }
  userLogout() {
    this.profile.logoutUser().then((data: any) => {
      this.loggerService.logout();
      window.location.href = data;
    });
  }
}
