import { NgModule, APP_INITIALIZER,NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './components/layout/layout.component';
import { SsoComponent } from './components/sso/sso.component';
import { SharedModule } from './modules/shared/shared.module';
import { FavouritesModule } from './modules/favourites/favourites/favourites.module';
import { HistoryModule } from './modules/history/history.module';
//import { PaginationComponent } from './components/pagination/pagination/pagination.component';
import { ProfileService} from 'src/app/services/profile/profile.service';
import {initializeProfile} from 'src/app/utility/auth.guard';
import { SearchMemberComponent } from './modules/history/search-member/search-member.component';
import { RouterModule } from '@angular/router';
import { PaDetailsComponent } from './components/pa-details/pa-details.component';
import {HomeModule} from 'src/app/modules/home/home.module';
import {BreadcrumbComponent} from 'src/app/components/breadcrumb/breadcrumb.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CheckcoverageModule } from './modules/checkcoverage/checkcoverage.module';
import { CommonModule } from './modules/common/common.module';
import { share } from 'rxjs';
import { MemberresultComponent } from './components/memberresult/memberresult.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { TermsofuseComponent } from './components/termsofuse/termsofuse.component';
import { ContactusComponent } from './components/contactus/contactus.component';
import { RegistrationModule } from './modules/registration/registration.module';
import { NotificationsModule } from './modules/notifications/notifications.module';
import { UserProvisioningComponent } from './components/user-provisioning/user-provisioning.component';
import { AddUserProvisioningComponent } from './components/add-user-provisioning/add-user-provisioning.component';
import { EPAModule } from './modules/ePA/epa.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { BenefitinquiryModule } from './modules/benefitinquiry/benefitinquiry.module';
import { ManageprescribersComponent } from './components/manageprescribers/manageprescribers.component';
import { ClaimsummaryComponent } from './components/claimsummary/claimsummary.component';
import { MemberSummaryComponent } from './components/member-summary/member-summary.component';
import { PaybleClaimSummaryComponent } from './components/payble-claim-summary/payble-claim-summary.component';
import { PresbrBookDetailsComponent } from './components/presbr-book-details/presbr-book-details.component';
import { PresbrChangeAddressComponent } from './components/presbr-change-address/presbr-change-address.component';
import { AddPrescribersComponent } from './components/add-prescribers/add-prescribers.component';
import { AddressbkComponent } from './components/addressbk/addressbk.component';
import { SelectPrescriberComponent } from './components/select-prescriber/select-prescriber.component';
import { ProvisionreportComponent } from './components/provisionreport/provisionreport.component';
import { ProvisioningComponent } from './components/provisioning/provisioning.component';
import { MemberDetailComponent } from './components/member-detail/member-detail.component';
// import { SearchForaMemberComponent } from './components/search-fora-member/search-fora-member.component';
// import { HistoryModule } from './modules/history/history.module';
import { MemberdatatableComponent } from './components/memberdatatable/memberdatatable.component';
import { ViewalldatatableModule } from './modules/viewalldatatable/viewalldatatable.module';


import { InternalcontrolpannelComponent } from './components/internalcontrolpannel/internalcontrolpannel.component';
import { PrescriberprofileComponent } from './components/prescriberprofile/prescriberprofile.component';
import { VerifyPrescribersComponent } from './components/verify-prescribers/verify-prescribers.component';
import { PharmacysearchComponent } from './components/pharmacysearch/pharmacysearch.component';
import { ErrorComponent } from './components/error/error.component';
import { AddPharmacyComponent } from './components/add-pharmacy/add-pharmacy.component';
import { SpecialtyPharmacyhomeComponent } from './components/routecontent/specialty-pharmacyhome/specialty-pharmacyhome.component';

import { DatatableModule } from './modules/datatable/datatable.module';
import { NotificationsComponent } from './components/routecontent/notifications/notifications.component';
import { CookieService } from 'ngx-cookie-service';
import { BannermsgComponent } from './components/bannermsg/bannermsg.component';
//import { MemberSubmittedPAHistoryComponent } from './member-submitted-pahistory/member-submitted-pahistory.component';
@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    SsoComponent,
    BreadcrumbComponent,
    PrivacyComponent,
    TermsofuseComponent,
    ContactusComponent,
    UserProvisioningComponent,
    AddUserProvisioningComponent,
    ManageprescribersComponent,
    PresbrBookDetailsComponent,
    PresbrChangeAddressComponent,
    AddPrescribersComponent,
    AddressbkComponent,
    ProvisionreportComponent,
    ProvisioningComponent,   
    InternalcontrolpannelComponent,
    PrescriberprofileComponent,
    VerifyPrescribersComponent,
    PharmacysearchComponent,
    ErrorComponent,
    AddPharmacyComponent,
    NotificationsComponent,
    BannermsgComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    HomeModule,
    NgbModule,
    CommonModule,
    RegistrationModule,
    BenefitinquiryModule,

    // SharedModule
    // CheckcoverageModule,
    // SharedModule,
    // FavouritesModule,
    CheckcoverageModule,
    EPAModule,
    SharedModule,
    FavouritesModule,
    BrowserAnimationsModule
  ],
  providers: [
    ProfileService,CookieService,{provide: APP_INITIALIZER,useFactory: initializeProfile, deps: [HttpClient, ProfileService],multi:true}
  ],

  bootstrap: [AppComponent],
  schemas:[NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
