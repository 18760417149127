import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PrescriberInfoList } from 'src/app/modals/prescriberSearch/prescriberSearchwebresponse';
import { APPCONSTANTS, MANAGE_PRESCRIBER_STATUS } from 'src/app/constants/appconstants';
import { AlternateAddress } from 'src/app/modals/registration/SteponePrescriberDetailsResponse';
import { Address, Contact, changeDefaultdAdressRequest } from 'src/app/modals/manageprescriberscalls/changeDefaultAddressRequest';
import { changeDefaultAddressResponse } from 'src/app/modals/manageprescriberscalls/changeDefaultAddressResponse';
import { ManageprescribersService } from 'src/app/services/prescribermanage/manageprescribers.service';
@Component({
  selector: 'app-presbr-book-details',
  templateUrl: './presbr-book-details.component.html',
  styleUrls: ['./presbr-book-details.component.css']
})
export class PresbrBookDetailsComponent {

  @Input() item!: PrescriberInfoList;
  @Input() addressChangeID!: string;
  @Output() onChangeSelected: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() onChangeDefaultPrescriber: EventEmitter<void> = new EventEmitter<void>();
  @Output() onReactivatePrescriber = new EventEmitter<any>();
  @Output() onCancelRequest = new EventEmitter<any>();
  @Output() onRequestNewPINRequest = new EventEmitter<any>();
  @Output() onResendNewPINRequest = new EventEmitter<any>();
  @Output() onRemoveAddedPresc = new EventEmitter<any>();
  @Output() onResetTileSize = new EventEmitter<any>();
  @Output() onAddressChangeSuccess = new EventEmitter<any>();

  constructor(private manageprescriberservice: ManageprescribersService) { }



  addedPresc = MANAGE_PRESCRIBER_STATUS.ADDED_PRESCRIBER;
  deactPresc = MANAGE_PRESCRIBER_STATUS.DEACTIVATED;
  expPendPresc = MANAGE_PRESCRIBER_STATUS.PENDING_EXPIRED;
  deniedPersc = MANAGE_PRESCRIBER_STATUS.DENIED;
  changeAddressFlag: boolean = false;


  changeDefaultPrescriber() {
    this.onChangeDefaultPrescriber.emit();
  }
  changeAddress() {
    this.changeAddressFlag = true;
    this.onChangeSelected.emit();
  }
  reactivatePrescriber() {
    this.onReactivatePrescriber.emit(this.item.presbrId);
  }
  onCancelRequestClick() {
    debugger;
    this.onCancelRequest.emit(this.item.presbrId);
  }
  //convert fax from 1234567890 to (123) 456-7890
  faxFormat1(fax: string) {
    return '(' + fax.slice(0, 3) + ') ' + fax.slice(3, 6) + '-' + fax.slice(6);
  }
  //convert fax from 1234567890 to 123-456-7890
  faxFormat2(fax: string) {
    return fax.slice(0, 3) + '-' + fax.slice(3, 6) + '-' + fax.slice(6);
  }
  onRequestNewPIN() {
    this.onRequestNewPINRequest.emit(this.item.npi);
  }
  onResendNewPIN() {
    this.onResendNewPINRequest.emit(this.item.npi);
  }
  removeAddedPresc() {
    this.onRemoveAddedPresc.emit(this.item.presbrId);
  }
  onResetTile() {
    this.onResetTileSize.emit(true);
    //clear the value if newaddress
    this.newaddress = undefined;
  }
  newaddress: AlternateAddress | undefined;
  storeNewAddress($event: AlternateAddress) {
    this.newaddress = $event;
  }
  onSelectnewAddress() {
    if (this.newaddress != undefined) {
      var webrequest = new changeDefaultdAdressRequest();
      webrequest.address = new Address();
      webrequest.contact = new Contact();

      webrequest.presbrId = this.item.presbrId;
      webrequest.address.address1 = this.newaddress.address.address1;
      webrequest.address.address2 = this.newaddress.address.address2;
      webrequest.address.city = this.newaddress.address.city;
      webrequest.address.state = this.newaddress.address.state;
      webrequest.address.zip1 = this.newaddress.address.zip1;
      webrequest.address.zip2 = this.newaddress.address.zip2;
      webrequest.address.zip3 = this.newaddress.address.zip3;
      webrequest.address.country = this.newaddress.address.country;
      webrequest.contact.phone1 = this.newaddress.contact.phone1;
      webrequest.contact.fax = this.newaddress.contact.fax;
      webrequest.contact.email = this.newaddress.contact.email;

      this.manageprescriberservice.postChangeDefaultAddress(webrequest).then((data: changeDefaultAddressResponse) => {
        if (data.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
          this.onAddressChangeSuccess.emit(this.item.firstName + ' ' + this.item.lastName);
        }
        else {
          this.onAddressChangeSuccess.emit('error');
        }
      });
    }
    else{
      this.onResetTile();
    }
  }
}
