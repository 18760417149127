<div class="media container-fluid nopadding">
    <div class="row">
        <div class="col-lg-1 col-md-1 text-center">
            <h1 id="memberResultsCount" tabindex="-1">{{members.length}}</h1>
        </div>
        <div id="memberResultsTxt" class="col-lg-4 col-md-10 text-start">
            <span class="float-start">Member<ng-container *ngIf="members.length>1">s</ng-container> found</span>
            <hr/>
        </div>
        <div class="col-lg-1 col-md-1 text-center">
            <span>Show:</span>
        </div>
        <div class="col-lg-2 col-md-4 text-center">
            <label (click)="activeMembersCount!=0 && filterStatus=1" class="radioInput" [class.disabled]="activeMembersCount==0">
                <input type="radio" name="filtermembers" value="1" [checked]="filterStatus===1"/>
                <i class="fa fa-circle-o" *ngIf="filterStatus!==1"></i>
                <i class="fa fa-dot-circle-o" *ngIf="filterStatus===1"></i>
                <span class="text">&nbsp;Active</span>&nbsp;<span>(&nbsp;{{activeMembersCount}}&nbsp;)</span>
            </label>
        </div>
        <div class="col-lg-2 col-md-4 text-center">
            <label (click)="inactiveMembersCount!=0 && filterStatus=2" class="radioInput" [class.disabled]="inactiveMembersCount==0">
                <input type="radio" name="filtermembers" value="2" [checked]="filterStatus===2"/>
                <i class="fa fa-circle-o" *ngIf="filterStatus!==2"></i>
                <i class="fa fa-dot-circle-o" *ngIf="filterStatus===2"></i>
                <span class="text">&nbsp;Inactive</span>&nbsp;<span>(&nbsp;{{inactiveMembersCount}}&nbsp;)</span>
            </label>
        </div>
        <div class="col-lg-2 col-md-4 text-center">
            <label (click)="filterStatus=3" class="radioInput">
                <input type="radio" name="filtermembers" value="3" [checked]="filterStatus===3"/>
                <i class="fa fa-circle-o" *ngIf="filterStatus!==3"></i>
                <i class="fa fa-dot-circle-o" *ngIf="filterStatus===3"></i>
                <span class="text">&nbsp;All</span>&nbsp;<span>(&nbsp;{{members.length}}&nbsp;)</span>
            </label>
        </div>
    </div>
</div>

<datatable [headers]="headers" [rows]="results" [type]="datatableType"
    (onHeaderClicked)="onHeaderClicked($event)" (onCellClicked)="onCellClicked($event)">
</datatable>
<div class="container-fluid" *ngIf="totalPagesCount>1">
    <div class="row">
        <div class="col text-center">
            <pagination [totalPages]="totalPagesCount" (onPageChange)="onPageChange($event)"></pagination>
        </div>
    </div>
</div>
<ng-template #memberNameTemplate let-data="data">
    <div *ngIf="data.isMedD" class="medicareInd" style="font-size: 11px !important;">Medicare</div>
    <div class="memberData">
        <span [innerHtml]="data.lastName" class="text-capitalize fontbold"></span>
        <br/>
        <span [innerHtml]="data.firstName" class="text-capitalize"></span>
    </div>
</ng-template>

<ng-template #memberAddressTemplate let-data="data">
    <div class="memberData">
        {{data.address.address1}}&nbsp;{{data.address.address2}}&nbsp;{{data.address.address3}}
    </div>
</ng-template>

<ng-template #memberIdTemplate let-data="data">
    <div class="memberData">
        {{data.memberId}}
    </div>
</ng-template>

<ng-template #memberGroupIdTemplate let-data="data">
    <div class="memberData">
        {{data.groupId}}
    </div>
</ng-template>

<ng-template #memberEligibilityTemplate let-data="data">
    <div class="memberData">
        <span class="alert alert-success text-capitalize resultsflag" *ngIf="data.activeInd?.toLowerCase()=='a'">active</span> 
        <span class="alert alert-danger text-capitalize resultsflag" *ngIf="data.activeInd?.toLowerCase()=='i'">inactive</span>
    </div>
</ng-template>


<ng-template #memberSaveTemplate let-data="data">
    <a type="button" name="favorite" class="btn invisiblebtn" enableAction="true">
        <span style="display: none;" aria-hidden>Favorite</span>
        <span name="favorite" *ngIf="!data.isFavorite" class="notfavorite" [attr.enableAction]="data.activeInd?.toLowerCase()=='a'" [ngStyle]="{'cursor':(data.activeInd?.toLowerCase()=='i')?'not-allowed':'pointer'}"></span>
        <span name="favorite" *ngIf="data.isFavorite" class="favorite" [attr.enableAction]="data.activeInd?.toLowerCase()=='a'" [ngStyle]="{'cursor':(data.activeInd?.toLowerCase()=='i')?'not-allowed':'pointer'}"></span>
    </a>
</ng-template>

<ng-template #memberSelectSymbolTemplate let-data="data">
    <a class="btn invisiblebtn" *ngIf="data.activeInd?.toLowerCase()=='a'">
        <i class="fa fa-angle-right"></i>
    </a>
</ng-template>

<ng-template #favouriteErrorTemplate let-modal>
    <div class="container-fluid favouriteActionError">
        <div class="row">
            <div class="col text-center">
                <i class="fa fa-ban" aria-hidden="true"></i>
            </div>
        </div>
        <div class="row" [hidden]="blnFavouriteActionError">
            <div class="col paragraph large text-center">
                Your saved member limit is ten. Please remove an existing saved member to continue.
            </div>
        </div>
        <div class="row" [hidden]="!blnFavouriteActionError">
            <div class="col paragraph large text-center">
                An error occured. Please try again later.
            </div>
        </div>
        <div class="row">
            <div class="col text-center">
                <button type="button" class="btn btn-secondary" (click)="modal.dismiss()">Ok</button>
            </div>
        </div>
    </div>
</ng-template>