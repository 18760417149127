import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { APPCONSTANTS, MANAGE_PRESCRIBER_STATUS, PRESCRIBER_QUALIFIER } from 'src/app/constants/appconstants';
import { PrescriberInfoList } from 'src/app/modals/prescriberSearch/prescriberSearchwebresponse';
import { SteponePrescriberDetailsRequest } from 'src/app/modals/registration/SteponePrescriberDetailsRequest';
import { SteponePrescriberDetailsResponse, AlternateAddress } from 'src/app/modals/registration/SteponePrescriberDetailsResponse';
import { registrationService } from 'src/app/services/registrationSteps/registration.service';

@Component({
  selector: 'presbr-change-address',
  templateUrl: './presbr-change-address.component.html',
  styleUrls: ['./presbr-change-address.component.css']
})
export class PresbrChangeAddressComponent implements OnInit {
  @Output() onChangeDefaultPrescriber: EventEmitter<void> = new EventEmitter<void>();
  @Input() prescID!: string;
  @Input() instanceID!: string;
  @Output() onSelectNewaddress = new EventEmitter<any>();
  addresses: AlternateAddress[] = [];
  noAdditionalAddresses: boolean | undefined = undefined;
  selectedAddressIndex!: number;
  currentpageindex: number = 2;
  minpageindex: number = 2;
  maxpageindex!: number;
  constructor(private registrationService: registrationService) { }
  ngOnInit(): void {

    this.clearResults();
    let webrequest: SteponePrescriberDetailsRequest = new SteponePrescriberDetailsRequest();
    webrequest.prescriberId = this.prescID;
    webrequest.instanceId = this.instanceID;
    webrequest.prescriberIdQualifier = PRESCRIBER_QUALIFIER.NPI;
    this.registrationService.postPrescriberDetails(webrequest).then((data: SteponePrescriberDetailsResponse) => {
      if (data.status.respMessage?.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
        //convert address1, address2 and city to lower case
        for (var i = 0; i < data.response.prescriberDetailItem.alternateAddresses.length; i++) {
          data.response.prescriberDetailItem.alternateAddresses[i].address.address1 = data.response.prescriberDetailItem.alternateAddresses[i].address.address1?.toLowerCase();
          data.response.prescriberDetailItem.alternateAddresses[i].address.address2 = data.response.prescriberDetailItem.alternateAddresses[i].address.address2?.toLowerCase();
          data.response.prescriberDetailItem.alternateAddresses[i].address.city = data.response.prescriberDetailItem.alternateAddresses[i].address.city?.toLowerCase();
        }

        //push primary addresses first
        for (var i = 0; i < data.response.prescriberDetailItem.alternateAddresses.length; i++) {
          if (data.response.prescriberDetailItem.alternateAddresses[i].qualifier?.toLowerCase() == MANAGE_PRESCRIBER_STATUS.PRIMARY_ADDRESS) {
            this.addresses.push(data.response.prescriberDetailItem.alternateAddresses[i]);
          }
        }

        //push alterante addresses
        for (var i = 0; i < data.response.prescriberDetailItem.alternateAddresses.length; i++) {
          if (data.response.prescriberDetailItem.alternateAddresses[i].qualifier?.toLowerCase() != MANAGE_PRESCRIBER_STATUS.PRIMARY_ADDRESS) {
            this.addresses.push(data.response.prescriberDetailItem.alternateAddresses[i]);
            
          }
        }
        //set length of addresses
        if (this.addresses.length > 2) {
          this.maxpageindex = this.addresses.length;
        }
        this.noAdditionalAddresses = false;
      }
      else {
        this.noAdditionalAddresses = true;

      }

    });
    console.log(this.addresses);
    console.log(this.minpageindex,this.maxpageindex,this.currentpageindex);
  }


  onAddressSelect() {
    this.onChangeDefaultPrescriber.emit();
  }
  clearResults() {
    this.noAdditionalAddresses = undefined;
    this.addresses = [];
    this.maxpageindex = 0;
    this.currentpageindex = 2;

  }
  faxFormat1(fax: string) {
    return '(' + fax.slice(0, 3) + ') ' + fax.slice(3, 6) + '-' + fax.slice(6);
  }
  selectAddress(index:number,item: AlternateAddress) {
    this.selectedAddressIndex=index;
    this.onSelectNewaddress.emit(item);

  }
  incrpage(){
    debugger;
    if((this.currentpageindex+1)<this.maxpageindex){
      this.currentpageindex=this.currentpageindex+2;
    }
    else if((this.currentpageindex+1)==this.maxpageindex){
      this.currentpageindex=this.currentpageindex+1;
    }
    console.log(this.minpageindex,this.maxpageindex,this.currentpageindex);
  }
  decrpage(){
    debugger;
    if(this.currentpageindex%2==1){
      this.currentpageindex=this.currentpageindex-1;
    }    
    else if((this.currentpageindex-1)>this.minpageindex){
      this.currentpageindex=this.currentpageindex-2;
    }
    console.log(this.minpageindex,this.maxpageindex,this.currentpageindex);
  }
}
