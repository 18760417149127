import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { APPCONSTANTS } from '../../constants/appconstants';
import { LoggerService } from '../logger/logger.service';
import { optumIDSearchResponse } from '../../modals/internalcontrol/optumIDSearchResponse';
import { updatePresbrDetailsRequest } from '../../modals/internalcontrol/updatePresbrDetailsRequest';
import { updatePresbrDetailsResponse } from '../../modals/internalcontrol/updatePresbrDetailsResponse';
import { ProfileService } from '../profile/profile.service';

@Injectable({
  providedIn: 'root'
})
export class InternalcontrolService {

  constructor(private http: HttpClient,private logger: LoggerService, private profileService:ProfileService ) { }

  postOptumIdSearch(optumID: string): Promise<optumIDSearchResponse> {

    const promise: Promise<optumIDSearchResponse> = new Promise<optumIDSearchResponse>((resolve, reject) => {
      //this.http.get('./assets/mockresponses/resumeSavedPAResponse.json').subscribe((response:any)=>{
        debugger
      let header = new HttpHeaders({ "endpoint": environment.optumIdSearch.replace("{optumID}", optumID),"X-CSRF-Token":this.profileService.getCsrfToken() });

      this.http.post(environment.provisoningproxyurl, null, { headers: header }).subscribe((response: any) => {
        if (response != undefined && response.status != undefined
          && response.status.statusCode != undefined && response.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
          resolve(response);
        } else {
          this.logger.log(response);
          reject(response);
        }
      }, (error: any) => {
        this.logger.error(error);
        reject(error);
      });
    });
    return promise;
  }
  postupdatePresbrDetails(webrequest: updatePresbrDetailsRequest): Promise<updatePresbrDetailsResponse> {
    const promise: Promise<updatePresbrDetailsResponse> = new Promise<updatePresbrDetailsResponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.updatePresbrDetailsUrl,"X-CSRF-Token":this.profileService.getCsrfToken()});
      this.http.post(environment.provisoningproxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        
        if (response.response!=undefined &&response.status != undefined
          && response.status.statusCode != undefined && response.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
          resolve(response);
        } else {
          resolve(response);
        }
      }, (error: any) => {
        reject(error);
      });
    });
    return promise;
  }
}
