import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APPCONSTANTS } from 'src/app/constants/appconstants';
import { PrescriberAddressBookRequest, changeDefaultPrescriberRequest } from 'src/app/modals/prescriberSearch/prescriberSearchweberequest';
import { PrescriberAddressBook } from 'src/app/modals/prescriberSearch/prescriberSearchwebresponse';
import { environment } from 'src/environments/environment';
import { Status } from 'src/app/modals/webresponsestatus';
import { LoggerService } from '../logger/logger.service';
import { StringToSentenceCase } from 'src/app/pipes/stringconvertions/stringconversions.pipe';
import { reactiveAllPrescribersRequest } from 'src/app/modals/manageprescriberscalls/reactiveAllPrescribersRequest';
import { reactiveAllPrescribersResponse } from 'src/app/modals/manageprescriberscalls/reactiveAllPrescribersResponse';
import { updatePrescriberbkStatusRequest } from 'src/app/modals/manageprescriberscalls/updatePrescriberbkStatusRequest';
import { updatePrescriberbkStatusResponse } from 'src/app/modals/manageprescriberscalls/updatePrescriberbkStatusResponse';
import { changeDefaultdAdressRequest } from 'src/app/modals/manageprescriberscalls/changeDefaultAddressRequest';
import { changeDefaultAddressResponse } from 'src/app/modals/manageprescriberscalls/changeDefaultAddressResponse';
import { ProfileService } from '../profile/profile.service';

@Injectable({
  providedIn: 'root'
})
export class ManageprescribersService {

  constructor(private http: HttpClient, private sentenseCasePipe:StringToSentenceCase, private logger: LoggerService, private profileService:ProfileService) {}
  getAdrBkPrescribers(webrequest:PrescriberAddressBookRequest): Promise<PrescriberAddressBook> {
    const promise: Promise<PrescriberAddressBook> = new Promise<PrescriberAddressBook>((resolve, reject) => {
        let header=new HttpHeaders({"endpoint":environment.getAdrBkPrescribersUrl.replace("{adminPresbrId}", webrequest.adminPresbrId),"X-CSRF-Token":this.profileService.getCsrfToken()});
        this.http.get(environment.proxyurl,{headers:header}).subscribe((response: any) => {
      // this.resumePASubscription = this.http.post(environment.getSavedPADetailsUrl.replace("{parefId}", paReferenceId).replace("{uuid}", uuid), null).subscribe((response: any) => {
        if (response != undefined && response.status != undefined
          && response.status.statusCode != undefined && response.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
          resolve(response);
        } else {
          this.logger.log(response);
          reject(response);
        }
      }, (error: any) => {
        this.logger.error(error);
        reject(error);
      });
    });
    return promise;
  } 

  changeDefaultPrescriberForAdmin(webrequest:changeDefaultPrescriberRequest):Promise<Status>{
    const promise:Promise<Status> = new Promise<Status>((resolve,reject)=>{
      let header=new HttpHeaders({"endpoint":environment.changeDefaultprescriberUrl,"X-CSRF-Token":this.profileService.getCsrfToken()});
      this.http.post(environment.proxyurl,webrequest,{headers:header}).subscribe((response: any) => {
        debugger;
        if(response!=undefined && response.status!=undefined 
          && response.status.statusCode!=undefined
          && response.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT?.toLowerCase()){
            resolve(response);
          } else {
          reject(response);
          }
      },(error:any)=>{
          reject(error);
      });
    });
    return promise;
  }

  // postReactiveAllPrescribers(webrequest: reactiveAllPrescribersRequest): Promise<reactiveAllPrescribersResponse> {
  //   const promise: Promise<reactiveAllPrescribersResponse> = new Promise<reactiveAllPrescribersResponse>((resolve, reject) => {
  //     let header=new HttpHeaders({"endpoint":environment.reactiveAllPrescribersUrl});
  //     this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        
  //       if (response.response!=undefined &&response.status != undefined
  //         && response.statusCode != undefined && response.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
  //         resolve(response);
  //       } else {
  //         resolve(response);
  //       }
  //     }, (error: any) => {
  //       reject(error);
  //     });
  //   });
  //   return promise;
  // }
  postReactiveAllPrescribers(webrequest: reactiveAllPrescribersRequest): Promise<reactiveAllPrescribersResponse> {
    const promise: Promise<reactiveAllPrescribersResponse> = new Promise<reactiveAllPrescribersResponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.reactiveAllPrescribersUrl,"X-CSRF-Token":this.profileService.getCsrfToken()});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        
        if (response.response!=undefined &&response.status != undefined
          && response.status.statusCode != undefined && response.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
          resolve(response);
        } else {
          resolve(response);
        }
      }, (error: any) => {
        reject(error);
      });
    });
    return promise;
  }

  postupdatePresbrAdbkStatus(webrequest: updatePrescriberbkStatusRequest): Promise<updatePrescriberbkStatusResponse> {
    const promise: Promise<updatePrescriberbkStatusResponse> = new Promise<updatePrescriberbkStatusResponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.updatePresbrAdbkStatusUrl,"X-CSRF-Token":this.profileService.getCsrfToken()});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        
        if (response.response!=undefined &&response.status != undefined
          && response.statusCode != undefined && response.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
          resolve(response);
        } else {
          resolve(response);
        }
      }, (error: any) => {
        reject(error);
      });
    });
    return promise;
  }

  postChangeDefaultAddress(webrequest: changeDefaultdAdressRequest): Promise<changeDefaultAddressResponse> {
    const promise: Promise<changeDefaultAddressResponse> = new Promise<changeDefaultAddressResponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.changeDefaultAddressUrl,"X-CSRF-Token":this.profileService.getCsrfToken()});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        
        if (response.response!=undefined &&response.status != undefined
          && response.statusCode != undefined && response.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
          resolve(response);
        } else {
          resolve(response);
        }
      }, (error: any) => {
        reject(error);
      });
    });
    return promise;
  }

}
