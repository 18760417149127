import { Pipe, PipeTransform } from '@angular/core';
import { DrugSearchItem } from 'src/app/modals/drugSearch/SpecialityProductInterfaces';

@Pipe({
  name: 'drugnamesort'
})
export class DrugnamesortPipe implements PipeTransform {

  transform(value: Array<DrugSearchItem>, ...args: any[]): Array<DrugSearchItem> {
    if(value != null){
      return value.sort((item1:DrugSearchItem,item2:DrugSearchItem)=>{
        //commented after new display logic
			//if strengths are unequal, then following is always valid.
			if(parseFloat(item1.strength)!=0 && parseFloat(item2.strength) !=0 && parseFloat(item1.strength) != parseFloat(item2.strength))
			{
				if ( parseFloat(item1.strength) < parseFloat(item2.strength) )
						return -1;
				if ( parseFloat(item1.strength) > parseFloat(item2.strength) )
						return 1;	
			}

			//if child drugs have equal strength then doing text compare. Even text compare is wrong as it may result in incorrect sorting.
			if(typeof item1.detailedDrugName !=undefined && typeof item2.detailedDrugName != undefined)
			{
				let lengthcovered=0;
				while(lengthcovered < item1.detailedDrugName.length && lengthcovered < item2.detailedDrugName.length)
				{
					if(item1.detailedDrugName?.toLowerCase().charCodeAt(lengthcovered) < item2.detailedDrugName?.toLowerCase().charCodeAt(lengthcovered))
						return -1;
					else
					{
						if(item1.detailedDrugName?.toLowerCase().charCodeAt(lengthcovered) > item2.detailedDrugName?.toLowerCase().charCodeAt(lengthcovered))
							return 1;
					}
					lengthcovered++;
				}
				if(lengthcovered == item1.detailedDrugName.length && lengthcovered < item2.detailedDrugName.length)
					return -1;
				else
				{
					if(lengthcovered == item2.detailedDrugName.length && lengthcovered < item1.detailedDrugName.length)
						return 1;
					else
					{	 
							return 0;
					}
				}
			}
			return 0;
      });
    } else {
      return [];
    }
  }

}
