import { HttpEvent, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { APPCONSTANTS, MANAGE_PRESCRIBER_STATUS } from 'src/app/constants/appconstants';
import { Address, Contact, changeDefaultdAdressRequest } from 'src/app/modals/manageprescriberscalls/changeDefaultAddressRequest';
import { changeDefaultAddressResponse } from 'src/app/modals/manageprescriberscalls/changeDefaultAddressResponse';
import { reactiveAllPrescribersRequest } from 'src/app/modals/manageprescriberscalls/reactiveAllPrescribersRequest';
import { reactiveAllPrescribersResponse } from 'src/app/modals/manageprescriberscalls/reactiveAllPrescribersResponse';
import { updatePrescriberbkStatusRequest } from 'src/app/modals/manageprescriberscalls/updatePrescriberbkStatusRequest';
import { updatePrescriberbkStatusResponse } from 'src/app/modals/manageprescriberscalls/updatePrescriberbkStatusResponse';
import { PrescriberAddressBookRequest, changeDefaultPrescriberRequest } from 'src/app/modals/prescriberSearch/prescriberSearchweberequest';
import { PrescriberAddressBook, PrescriberInfoList } from 'src/app/modals/prescriberSearch/prescriberSearchwebresponse';
import { StepthreeInitiateFaxRequest } from 'src/app/modals/registration/StepthreeInitiateFaxRequest';
import { StepthreeInitiateFaxResponse } from 'src/app/modals/registration/StepthreeInitiateFaxResponse';
import { Status } from 'src/app/modals/webresponsestatus';
import { FormatPhoneOrFax, StringToSentenceCase } from 'src/app/pipes/stringconvertions/stringconversions.pipe';
import { ManageprescribersService } from 'src/app/services/prescribermanage/manageprescribers.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { registrationService } from 'src/app/services/registrationSteps/registration.service';
import { VerifyProviderService } from 'src/app/services/verifyProvider/verify-provider.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-manageprescribers',
  templateUrl: './manageprescribers.component.html',
  styleUrls: ['./manageprescribers.component.css']
})
export class ManageprescribersComponent implements OnInit {
  @ViewChild("successPINResendPopup", { static: true })
  successPINResendPopupTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("cancelRequestPopup", { static: true })
  cancelRequestPopupTemplateRef?: TemplateRef<HTMLElement>;


  @ViewChild("canceladdedprescPopup", { static: true })
  canceladdedprescPopupTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("removedeniedprescPopup", { static: true })
  removedeniedprescPopupTemplateRef?: TemplateRef<HTMLElement>;



  @ViewChild("noprescriber", { static: true })
  noprescriberPopupTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("unableToActivate", { static: true })
  unableToActivatePopupTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("unableTocancel", { static: true })
  unableToProcessPopupTemplateRef?: TemplateRef<HTMLElement>;


  @ViewChild("unableToResendPIN", { static: true })
  unableToResendPINPopupTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("resendlimitexceeded", { static: true })
  resendlimitexceededPopupTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("resendlimitexceededFull", { static: true })
  resendlimitexceededFullPopupTemplateRef?: TemplateRef<HTMLElement>;


  @Input() item!: PrescriberInfoList;

  @ViewChild('vanish') vanish!: ElementRef;


  prescriberDetails: PrescriberInfoList[] = [];
  addedPrescriberscopy: PrescriberInfoList[][] = [];
  pendingPrescriberDetails: PrescriberInfoList[] = [];
  expiredPrescriberDetails: PrescriberInfoList[] = [];
  deniedPrescriberDetails: PrescriberInfoList[] = [];
  deactivatedPrescriberDetails: PrescriberInfoList[] = [];

  blnShowAltAddress: boolean = false;
  isFavorite!: boolean;
  isNotfavorite!: boolean;
  index!: number;
  adminID!: string
  cancelSuccessful: boolean = false;
  removeSuccessful: boolean = false;
  addressChangeSuccessful: boolean = false;
  cancelPrescID!: string;
  resendPINNPI: string = '';
  cancelPrescriberName!: string;
  cancelprescriberNPI!: string;
  resendPrescName: string = '';
  reactivatePrescID: string = '';
  cancelUpdateStatus!: number;
  selectedChangeaddressID!: string;
  pinResentToAll:boolean=false;
  totalPrescriberCount!:number;

  constructor(private registrationservice: registrationService, private renderer: Renderer2, protected router: Router, private manageprescriberservice: ManageprescribersService, private profileService: ProfileService, private modalService: NgbModal) {
    this.adminID = "" + this.profileService.loginuser?.prescriber?.presbrId;

  }

  ngOnInit(): void {
    sessionStorage.setItem("lastPageBeforeReload", this.router.url);

    let webrequest: PrescriberAddressBookRequest = new PrescriberAddressBookRequest();
    webrequest.adminPresbrId = "" + this.profileService.loginuser?.prescriber?.presbrId;
    this.manageprescriberservice.getAdrBkPrescribers(webrequest).then((response: PrescriberAddressBook) => {

      //to know total prescribers requests
      this.totalPrescriberCount=response.prescriberInfoList.length;
      console.log(this.totalPrescriberCount);
      sessionStorage.setItem("totalPrescriberCount",""+this.totalPrescriberCount);
      //to make capital to lower case
      for (let i = 0; i < response.prescriberInfoList.length; i++) {
        response.prescriberInfoList[i].firstName = ""+response.prescriberInfoList[i].firstName?.toLowerCase();
        response.prescriberInfoList[i].lastName = ""+response.prescriberInfoList[i].lastName?.toLowerCase();
        response.prescriberInfoList[i].addressLine1 = ""+response.prescriberInfoList[i].addressLine1?.toLowerCase();
        response.prescriberInfoList[i].addressLine2 = ""+response.prescriberInfoList[i].addressLine2?.toLowerCase();
        response.prescriberInfoList[i].city = ""+response.prescriberInfoList[i].city?.toLowerCase();
      }

      //to seperate prescriber based on their status
      for (let i = 0; i < response.prescriberInfoList.length; i++) {
        
        if (response.prescriberInfoList[i].presbrStatus == MANAGE_PRESCRIBER_STATUS.ADDED_PRESCRIBER) {
          this.prescriberDetails.push(response.prescriberInfoList[i]);
        }
        else if (response.prescriberInfoList[i].presbrStatus == MANAGE_PRESCRIBER_STATUS.PENDING_EXPIRED && response.prescriberInfoList[i].daysLeft > 0) {
          this.pendingPrescriberDetails.push(response.prescriberInfoList[i]);
        }
        else if (response.prescriberInfoList[i].presbrStatus == MANAGE_PRESCRIBER_STATUS.PENDING_EXPIRED && response.prescriberInfoList[i].daysLeft <= 0) {
          this.expiredPrescriberDetails.push(response.prescriberInfoList[i]);
        }
        else if (response.prescriberInfoList[i].presbrStatus == MANAGE_PRESCRIBER_STATUS.DENIED) {
          this.deniedPrescriberDetails.push(response.prescriberInfoList[i]);
        }
        else if (response.prescriberInfoList[i].presbrStatus == MANAGE_PRESCRIBER_STATUS.DEACTIVATED) {
          this.deactivatedPrescriberDetails.push(response.prescriberInfoList[i]);
        }
      }
      sessionStorage.setItem("savedAddressBookPresdetails", JSON.stringify(this.prescriberDetails));

      //call the function to make added prescribers a 2D array
      this.convertToMatrix(this.prescriberDetails, 3);
    });


  }
  clearPrescData() {
    this.prescriberDetails = [];
    this.addedPrescriberscopy = [];
    this.pendingPrescriberDetails = [];
    this.expiredPrescriberDetails = [];
    this.deniedPrescriberDetails = [];
    this.deactivatedPrescriberDetails = [];
  }

  convertToMatrix(arr: PrescriberInfoList[], n: number) {
    if (arr.length == 1) {
      this.addedPrescriberscopy.push(arr.slice(0, 1));
    }
    else if (arr.length >= 2) {
      this.addedPrescriberscopy.push(arr.slice(0, 2));
    }
    for (let i = 2; i < arr.length; i += n) {
      if (arr.length)
        this.addedPrescriberscopy.push(arr.slice(i, i + n));
    }
  }
  changeDefaultPrescriber(value: string) {
    let webrequest: changeDefaultPrescriberRequest = new changeDefaultPrescriberRequest();
    webrequest.adminPrescriberId = "" + this.profileService.loginuser?.prescriber?.presbrId;
    for (let i = 0; i < this.prescriberDetails.length; i++) {
      if (this.prescriberDetails[i].defaultInd == 'Y')
        webrequest.prescriberIdOld = this.prescriberDetails[i].presbrId;
      webrequest.prescriberIdNew = value;
    }
    this.manageprescriberservice.changeDefaultPrescriberForAdmin(webrequest).then((response: any) => {
      debugger;
      if (response.status.statusCode != undefined && response.status.statusCode?.toLowerCase() == "success") {
        this.isFavorite = true;
      }else{
        this.isFavorite = false;
      }
      this.addedPrescriberscopy=[];
      this.prescriberDetails=[];
      this.pendingPrescriberDetails=[];
      this.expiredPrescriberDetails=[];
      this.deniedPrescriberDetails=[];
      this.deactivatedPrescriberDetails=[];
      this.ngOnInit();
    });
    //add error conditions
  }

  changeAddress(value: string, index: number) {
    this.index = index;
    this.blnShowAltAddress = true;
    this.selectedChangeaddressID = value;
    //sessionStorage.setItem("prescriberBkDlts",this.)

  }

  selectedTilerow!: number;
  selectedTilecol!: number;
  selectedTile(i: number, j: number) {
    this.selectedTilerow = i;
    this.selectedTilecol = j;
  }


  goto(routeUrl: string) {
    this.router.navigateByUrl("/" + routeUrl);
  }

  reActivatePrescribers($event: string) {
    this.reactivatePrescID = $event;
    this.reActivatePrescriberscall();
  }
  reActivatePrescriberscall() {
    //code to store the first name and last name of prescriber by searching the list using prescID
    for (let i = 0; i < this.deactivatedPrescriberDetails.length; i++) {
      if (this.deactivatedPrescriberDetails[i].presbrId === this.reactivatePrescID) {
        this.resendPrescName = this.deactivatedPrescriberDetails[i].firstName + ' ' + this.deactivatedPrescriberDetails[i].lastName;
        break;
      }
    }
    var webrequest = new reactiveAllPrescribersRequest();
    webrequest.presbrId = this.reactivatePrescID;
    webrequest.adminPresbrId = this.adminID;
    webrequest.verifyUrl = environment.verifyProviderUrl;
    this.manageprescriberservice.postReactiveAllPrescribers(webrequest).then((data: reactiveAllPrescribersResponse) => {
      if (data.statusDesc == MANAGE_PRESCRIBER_STATUS.REACTIVATE_STATUS1) {
        this.modalService.open(this.successPINResendPopupTemplateRef, { size: 'md',backdrop:'static' });

      }
      else if (data.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_ERROR_TEXT) {
        this.modalService.open(this.unableToActivatePopupTemplateRef, { size: 'md',backdrop:'static' });
      }
    });

  }
  clearData() {
    this.resendPrescName = '';
    this.reactivatePrescID = '';
    this.cancelPrescID = '';
    this.resendPINNPI = '';
    this.cancelPrescriberName = '';
    this.cancelprescriberNPI = '';
    this.requestPinFlagvalue = 0;
    this.cancelUpdateStatus = 0;
    this.pinResentToAll==false;


  }
  reActivateAllPrescribers() {
    this.reactivatePrescID = '0';
    this.reActivatePrescriberscall();
    this.pinResentToAll=true;
  }

  onPendingCancel($event: string) {
    this.cancelPrescID = $event;
    this.cancelUpdateStatus = MANAGE_PRESCRIBER_STATUS.NORMAL;
    this.onCancelPendingShowModal();
  }
  onExpiredRemove($event: string) {
    this.cancelPrescID = $event;
    this.cancelUpdateStatus = MANAGE_PRESCRIBER_STATUS.NORMAL;
    this.onCancelexpiredShowModal();
  }
  onDeniedRemove($event: string) {
    this.cancelPrescID = $event;
    this.cancelUpdateStatus = MANAGE_PRESCRIBER_STATUS.DENIED_UPDATE_STATUS;
    this.onCancelDeniedShowModal();
  }
  onAddedRemove($event: string) {
    this.cancelPrescID = $event;
    this.cancelUpdateStatus = MANAGE_PRESCRIBER_STATUS.REMOVED_ADDED_PRESCRIBER;
    this.onCancelAddedShowModal();
  }



  onCancelPendingShowModal() {
    for (let i = 0; i < this.pendingPrescriberDetails.length; i++) {
      if (this.pendingPrescriberDetails[i].presbrId == this.cancelPrescID) {
        this.cancelPrescriberName = this.pendingPrescriberDetails[i].firstName + ' ' + this.pendingPrescriberDetails[i].lastName;
        this.cancelprescriberNPI = this.pendingPrescriberDetails[i].npi;
      }
    }
    this.modalService.open(this.cancelRequestPopupTemplateRef, { size: 'md' ,backdrop:'static'});
  }
  onCancelexpiredShowModal() {
    for (let i = 0; i < this.expiredPrescriberDetails.length; i++) {
      if (this.expiredPrescriberDetails[i].presbrId == this.cancelPrescID) {
        this.cancelPrescriberName = this.expiredPrescriberDetails[i].firstName + ' ' + this.expiredPrescriberDetails[i].lastName;
        this.cancelprescriberNPI = this.expiredPrescriberDetails[i].npi;
      }
    }
    this.modalService.open(this.cancelRequestPopupTemplateRef, { size: 'md' ,backdrop:'static'});
  }
  onCancelDeniedShowModal() {
    for (let i = 0; i < this.deniedPrescriberDetails.length; i++) {
      if (this.deniedPrescriberDetails[i].presbrId == this.cancelPrescID) {
        this.cancelPrescriberName = this.deniedPrescriberDetails[i].firstName + ' ' + this.deniedPrescriberDetails[i].lastName;
        this.cancelprescriberNPI = this.deniedPrescriberDetails[i].npi;
      }
    }
    this.modalService.open(this.removedeniedprescPopupTemplateRef, { size: 'md' ,backdrop:'static'});
  }
  onCancelAddedShowModal() {
    for (let i = 0; i < this.prescriberDetails.length; i++) {
      if (this.prescriberDetails[i].presbrId == this.cancelPrescID) {
        this.cancelPrescriberName = this.prescriberDetails[i].firstName + ' ' + this.prescriberDetails[i].lastName;
        this.cancelprescriberNPI = this.prescriberDetails[i].npi;
      }
    }
    this.modalService.open(this.canceladdedprescPopupTemplateRef, { size: 'md',backdrop:'static' });
  }

  cancelPrescriberRequest() {
    var webrequest = new updatePrescriberbkStatusRequest();
    webrequest.presbrId = this.cancelPrescID;
    webrequest.adminPresbrId = this.adminID;

    if (this.cancelUpdateStatus == MANAGE_PRESCRIBER_STATUS.NORMAL) {
      webrequest.updateStatus = MANAGE_PRESCRIBER_STATUS.NORMAL.toString()
      webrequest.currentStatus = MANAGE_PRESCRIBER_STATUS.PENDING_EXPIRED.toString();
    }
    else if (this.cancelUpdateStatus == MANAGE_PRESCRIBER_STATUS.DENIED_UPDATE_STATUS) {
      webrequest.updateStatus = MANAGE_PRESCRIBER_STATUS.DENIED_UPDATE_STATUS.toString()
      webrequest.currentStatus = MANAGE_PRESCRIBER_STATUS.DENIED.toString();
    }
    else if (this.cancelUpdateStatus == MANAGE_PRESCRIBER_STATUS.REMOVED_ADDED_PRESCRIBER) {
      webrequest.updateStatus = MANAGE_PRESCRIBER_STATUS.REMOVED_ADDED_PRESCRIBER.toString()
      webrequest.currentStatus = MANAGE_PRESCRIBER_STATUS.ADDED_PRESCRIBER.toString();
    }
    this.manageprescriberservice.postupdatePresbrAdbkStatus(webrequest).then((data: updatePrescriberbkStatusResponse) => {
      if (data.dbStatusDesc == MANAGE_PRESCRIBER_STATUS.CANCEL_SUCCESS) {
        if (this.cancelUpdateStatus == MANAGE_PRESCRIBER_STATUS.REMOVED_ADDED_PRESCRIBER) {
          this.successaddedRemovePopup();
        }
        else {
          this.successExpPendDenRemovePopup();
        }

      }
      else if (data.dbStatusDesc == MANAGE_PRESCRIBER_STATUS.PRESCRIBER_NOTFOUND) {
        this.modalService.open(this.noprescriberPopupTemplateRef, { size: 'md',backdrop:'static' });
      }
      else if (data.statusCode = APPCONSTANTS.API_RESPONSE_ERROR_TEXT) {
        this.modalService.open(this.unableToProcessPopupTemplateRef, { size: 'md',backdrop:'static' });
      }
    });
  }

  // totalPrescriberRequests(){
  //   this.totalPrescriberCount=this. prescriberDetails.length+
  //   this.pendingPrescriberDetails.length+
  //   this.expiredPrescriberDetails.length+
  //   this.deniedPrescriberDetails.length+
  //   this.deactivatedPrescriberDetails.length

  // }

  successExpPendDenRemovePopup() {
    this.cancelSuccessful = true;
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    setTimeout(() => {
      this.renderer.removeChild(this.vanish.nativeElement.parentNode, this.vanish.nativeElement);
      this.cancelSuccessful = false;

    }, 3000);
    //remove this user from the list
    this.removeNotFoundPrescriber();
    this.clearData();

  }
  successaddedRemovePopup() {
    this.resetSelectedTile();
    this.reloadPrescData();
    this.removeSuccessful = true;
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    setTimeout(() => {
      this.renderer.removeChild(this.vanish.nativeElement.parentNode, this.vanish.nativeElement);
      this.removeSuccessful = false;
      //remove this user from the list
      this.removeNotFoundPrescriber();
      this.clearData();
    }, 3000);

  }
  successAddressChangePopup() {
    this.addressChangeSuccessful = true;
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    setTimeout(() => {
      this.renderer.removeChild(this.vanish.nativeElement.parentNode, this.vanish.nativeElement);
      this.addressChangeSuccessful = false;
      this.removeNotFoundPrescriber();
      this.clearData();
    }, 3000);
    //remove this user from the list

  }
  removeNotFoundPrescriber() {
    this.pendingPrescriberDetails = this.pendingPrescriberDetails.filter((item) => item.presbrId !== this.cancelPrescID);
    this.expiredPrescriberDetails = this.expiredPrescriberDetails.filter((item) => item.presbrId !== this.cancelPrescID);
    this.deniedPrescriberDetails = this.deniedPrescriberDetails.filter((item) => item.presbrId !== this.cancelPrescID);
    this.prescriberDetails = this.prescriberDetails.filter((item) => item.presbrId !== this.cancelPrescID);

  }
  requestPinFlagvalue!: number;
  onRequestPin($event: string) {
    this.resendPINNPI = $event;
    this.requestPinFlagvalue = MANAGE_PRESCRIBER_STATUS.REQUESTPINFLAG4;
    //code to store the first name and last name of prescriber by searching the list using prescID
    for (let i = 0; i < this.expiredPrescriberDetails.length; i++) {
      if (this.expiredPrescriberDetails[i].npi == this.resendPINNPI) {
        this.resendPrescName = this.expiredPrescriberDetails[i].firstName + ' ' + this.expiredPrescriberDetails[i].lastName;
        break;
      }
    }

    this.onRequestPinCall();
  }
  onResendPin($event: string) {
    this.resendPINNPI = $event;
    this.requestPinFlagvalue = MANAGE_PRESCRIBER_STATUS.REQUESTPINFLAG3;
    //code to store the first name and last name of prescriber by searching the list using prescID
    for (let i = 0; i < this.pendingPrescriberDetails.length; i++) {
      if (this.pendingPrescriberDetails[i].npi == this.resendPINNPI) {
        this.resendPrescName = this.pendingPrescriberDetails[i].firstName + ' ' + this.pendingPrescriberDetails[i].lastName;
        break;
      }
    }
    this.onRequestPinCall();
  }

  onRequestPinCall() {
    var webrequest = new StepthreeInitiateFaxRequest();
    webrequest.linkReqId = '';
    webrequest.npi = this.resendPINNPI;
    // webrequest.optumId = '' + this.profileService.loginuser?.prescriber?.optumId;
    webrequest.optumId = '';
    webrequest.uuid = '' + this.profileService.loginuser?.prescriber?.uuid;
    if (this.requestPinFlagvalue == MANAGE_PRESCRIBER_STATUS.REQUESTPINFLAG4) {
      webrequest.requestPinFlag = MANAGE_PRESCRIBER_STATUS.REQUESTPINFLAG4;
    }
    else if (this.requestPinFlagvalue == MANAGE_PRESCRIBER_STATUS.REQUESTPINFLAG3) {
      webrequest.requestPinFlag = MANAGE_PRESCRIBER_STATUS.REQUESTPINFLAG3;
    }

    webrequest.verifyUrl = environment.verifyProviderUrl;
    this.registrationservice.postInitiateFax(webrequest).then((data: StepthreeInitiateFaxResponse) => {
      if (data.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
        this.modalService.open(this.successPINResendPopupTemplateRef, { size: 'md' ,backdrop:'static'});
        if (this.requestPinFlagvalue == MANAGE_PRESCRIBER_STATUS.REQUESTPINFLAG4) {
          this.resetSelectedTile();
          this.reloadPrescData();
        }
      }
      else if (data.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_ERROR_TEXT && data.status.statusDesc == MANAGE_PRESCRIBER_STATUS.PIN_EXCEEDED_DESC
        && data.status.customErrorCode == MANAGE_PRESCRIBER_STATUS.CUSTOM_CODE0) {
        this.modalService.open(this.resendlimitexceededPopupTemplateRef, { size: 'md' ,backdrop:'static'});
        this.requestPinFlagvalue = MANAGE_PRESCRIBER_STATUS.REQUESTPINFLAG4;
      }
      else if (data.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_ERROR_TEXT && data.status.statusDesc == MANAGE_PRESCRIBER_STATUS.PIN_EXCEEDED_DESC
        && data.status.customErrorCode == MANAGE_PRESCRIBER_STATUS.CUSTOM_CODE2) {
        this.modalService.open(this.resendlimitexceededFullPopupTemplateRef, { size: 'md',backdrop:'static' });
        this.clearData();
      }
      else {
        this.modalService.open(this.unableToResendPINPopupTemplateRef, { size: 'md' ,backdrop:'static'});
      }
    });
  }
  resetSelectedTile() {
    this.selectedTilerow = -1;
    this.selectedTilecol = -1;
    this.selectedChangeaddressID = '';
  }
  onNewAddressChange($event: string) {
    if ($event == 'error') {
      this.addressChangeSuccessful = false;
    }
    else {
      this.cancelPrescriberName = $event;
      // this.addressChangeSuccessful=true;
      this.successAddressChangePopup();
      this.resetSelectedTile();
      this.reloadPrescData();
    }
  }
  reloadPrescData() {
    this.clearPrescData();
    let webrequest: PrescriberAddressBookRequest = new PrescriberAddressBookRequest();
    webrequest.adminPresbrId = "" + this.profileService.loginuser?.prescriber?.presbrId;
    this.manageprescriberservice.getAdrBkPrescribers(webrequest).then((response: PrescriberAddressBook) => {
      
      //to know total prescribers requests
      this.totalPrescriberCount=response.prescriberInfoList.length;
      
      console.log(this.totalPrescriberCount);
      sessionStorage.setItem("totalPrescriberCount",""+this.totalPrescriberCount);



      //to make capital to lower case
      for (let i = 0; i < response.prescriberInfoList.length; i++) {
        response.prescriberInfoList[i].firstName = response.prescriberInfoList[i].firstName?.toLowerCase();
        response.prescriberInfoList[i].lastName = response.prescriberInfoList[i].lastName?.toLowerCase();
        response.prescriberInfoList[i].addressLine1 = response.prescriberInfoList[i].addressLine1?.toLowerCase();
        response.prescriberInfoList[i].addressLine2 = response.prescriberInfoList[i].addressLine2?.toLowerCase();
        response.prescriberInfoList[i].city = response.prescriberInfoList[i].city?.toLowerCase();
      }


      //to seperate prescriber based on their status
      for (let i = 0; i < response.prescriberInfoList.length; i++) {
        if (response.prescriberInfoList[i].presbrStatus == MANAGE_PRESCRIBER_STATUS.ADDED_PRESCRIBER) {
          this.prescriberDetails.push(response.prescriberInfoList[i]);
        }
        else if (response.prescriberInfoList[i].presbrStatus == MANAGE_PRESCRIBER_STATUS.PENDING_EXPIRED && response.prescriberInfoList[i].daysLeft > 0) {
          this.pendingPrescriberDetails.push(response.prescriberInfoList[i]);
        }
        else if (response.prescriberInfoList[i].presbrStatus == MANAGE_PRESCRIBER_STATUS.PENDING_EXPIRED && response.prescriberInfoList[i].daysLeft <= 0) {
          this.expiredPrescriberDetails.push(response.prescriberInfoList[i]);
        }
        else if (response.prescriberInfoList[i].presbrStatus == MANAGE_PRESCRIBER_STATUS.DENIED) {
          this.deniedPrescriberDetails.push(response.prescriberInfoList[i]);
        }
        else if (response.prescriberInfoList[i].presbrStatus == MANAGE_PRESCRIBER_STATUS.DEACTIVATED) {
          this.deactivatedPrescriberDetails.push(response.prescriberInfoList[i]);
        }
      }
      //call the function to make added prescribers a 2D array
      this.convertToMatrix(this.prescriberDetails, 3);
    });
  }
}

