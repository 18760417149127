import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterobjectsbytext'
})
export class FilterobjectsByTextPipe implements PipeTransform {

  transform(values: Object[], searchtext:string, fields:string[], ...args: unknown[]): any[] {
    if(searchtext==undefined || searchtext.length==0){
      return values;
    } else{
      return values.filter((item:Object)=>{
        for(const [k,v] of Object.entries(item)){
          if(fields.indexOf(k.toString())!=-1 && v!=undefined && v.toString()?.toLowerCase().indexOf(searchtext?.toLowerCase())!=-1)
            return true;
        }
        return false;
      });
    }
  }

}
