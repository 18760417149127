import { Component, ElementRef, EventEmitter, OnInit, Output, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { APPCONSTANTS, PRESCRIBER_QUALIFIER } from 'src/app/constants/appconstants';
import { TableCellData, TableClickEvent, TableHeaderCellData, TableRowData } from 'src/app/modals/datatable/datatable';
import { Address } from 'src/app/modals/drugSearch/drugsearchwebrequest';
import { changeDefaultdAdressRequest, Contact } from 'src/app/modals/manageprescriberscalls/changeDefaultAddressRequest';
import { changeDefaultAddressResponse } from 'src/app/modals/manageprescriberscalls/changeDefaultAddressResponse';
import { PrescriberDetailWebRequest, changeAdminRequest, supportStaffRequest } from 'src/app/modals/prescriberSearch/prescriberSearchweberequest';
import { PresbrSupportStaff, supportStaffResponse } from 'src/app/modals/prescriberSearch/prescriberSearchwebresponse';
import { provisionUser } from 'src/app/modals/provisioning/UploadHistoryWebResponse';
import { AlternateAddress } from 'src/app/modals/registration/SteponePrescriberDetailsResponse';
import { Status } from 'src/app/modals/webresponsestatus';
import { ServertimeToStandardDate } from 'src/app/pipes/dateconversion/dateconversion.pipe';
import { PrescriberSearchService } from 'src/app/services/prescriberSearch/prescriber-search.service';
import { ManageprescribersService } from 'src/app/services/prescribermanage/manageprescribers.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { VerifyProviderService } from 'src/app/services/verifyProvider/verify-provider.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-prescriberprofile',
  templateUrl: './prescriberprofile.component.html',
  styleUrls: ['./prescriberprofile.component.css']
})
export class PrescriberprofileComponent implements OnInit {
  firstName!: string;
  lastName!: string;
  NPI!: string;
  prefferedAddress1!: string;
  prefferedAddress2!: string;
  phone!: string;
  fax!: string;
  instancId!: string;
  blnLoader: boolean = true;
  blnShowAlternateAddress = false
  supportStaffList!: supportStaffResponse;
  approvalPendingsupportStaffList!: supportStaffResponse;
  private currentPageNumber!: number;
  recordsPerPage: number = environment.paginationRecordsPerPage;
  totalPagesCount!: number;
  newAddressPrescriberName!: string;
  addressChangeSuccessful: boolean | undefined;
  removeMember: PresbrSupportStaff | undefined;
  popup: boolean | undefined;
  tryagainType: number=0;
  tryagainData: any;
  
  headers!: TableHeaderCellData[];
  headers2!: TableHeaderCellData[];
  entries!: TableRowData<PresbrSupportStaff>[];
  entries2!: TableRowData<PresbrSupportStaff>[];
  firstHeader!: TableHeaderCellData;
  secondHeader!: TableHeaderCellData;
  thirdHeader!: TableHeaderCellData;
  forthHeader!: TableHeaderCellData;
  fifthHeader!: TableHeaderCellData;
  sixthHeader!: TableHeaderCellData;
  seventhHeader!: TableHeaderCellData;

  @ViewChild("nameTemplate", { static: true })
  nameTemplateRef!: TemplateRef<HTMLElement>;

  @ViewChild("emailTemplate", { static: true })
  emailTemplateRef!: TemplateRef<HTMLElement>;

  @ViewChild("phoneTemplate", { static: true })
  phoneTemplateRef!: TemplateRef<HTMLElement>;

  @ViewChild("dateGrantedTemplate", { static: true })
  dateGrantedTemplateRef!: TemplateRef<HTMLElement>;

  @ViewChild("timeRemainingTemplate", { static: true })
  timeRemainingTemplateRef!: TemplateRef<HTMLElement>;

  @ViewChild("removeTemplate", { static: true })
  removeTemplateRef!: TemplateRef<HTMLElement>;

  @ViewChild("approveDenyTemplate", { static: true })
  approveDenyTemplateRef!: TemplateRef<HTMLElement>;

  @ViewChild("noSavedHistoryTemplate", { static: true })
  noSavedHistoryTemplateRef!: TemplateRef<HTMLElement>;

  @ViewChild('vanish') vanish!: ElementRef;
  @ViewChild('vanishFailedScenario') vanishFailedScenario!: ElementRef;
  @ViewChild('vanishSuccessScenario2') vanishSuccessScenario2!: ElementRef;

  @ViewChild("removeConfirmPopup", { static: true })
  removeConfirmPopupTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("tryAgainPopup", { static: true })
  tryAgainPopupTemplateRef?: TemplateRef<HTMLElement>;

  constructor(private router: Router,
    private renderer: Renderer2,
    private profileService: ProfileService,
    private presciberHelper: PrescriberSearchService,
    private servertimetostandarddate: ServertimeToStandardDate,
    private manageprescriberservice: ManageprescribersService,
    private modalService: NgbModal) {

    this.headers = [];
    this.headers2 = [];

    this.firstHeader = new TableHeaderCellData();
    this.firstHeader.content = "Name";
    this.firstHeader.sortable = true;
    this.headers.push(this.firstHeader);

    this.secondHeader = new TableHeaderCellData();
    this.secondHeader.content = "Email";
    this.headers.push(this.secondHeader);

    this.thirdHeader = new TableHeaderCellData();
    this.thirdHeader.content = "Phone";
    this.headers.push(this.thirdHeader);

    this.forthHeader = new TableHeaderCellData();
    this.forthHeader.content = "Date Granted";
    this.forthHeader.sortable = true;
    this.forthHeader.sortAscending = false;
    this.forthHeader.selectedForSort = true;
    this.headers.push(this.forthHeader);

    this.fifthHeader = new TableHeaderCellData();
    this.fifthHeader.content = "Remove Access";
    this.fifthHeader.clickable = true;
    this.headers.push(this.fifthHeader);

    this.sixthHeader = new TableHeaderCellData();
    this.sixthHeader.content = "Time Remaining";
    this.sixthHeader.sortable = true;
    this.sixthHeader.selectedForSort = true;
    this.sixthHeader.clickable = true;

    this.seventhHeader = new TableHeaderCellData();
    this.seventhHeader.content = "Approve/Deny";
    this.seventhHeader.clickable = true;

    this.headers2.push(this.firstHeader);
    this.headers2.push(this.secondHeader);
    this.headers2.push(this.thirdHeader);
    this.headers2.push(this.sixthHeader);
    this.headers2.push(this.seventhHeader);

  }

  ngOnInit(): void {
    sessionStorage.setItem("lastPageBeforeReload", this.router.url);
    console.log(this.profileService);

    this.firstName = "" + this.profileService.loginuser?.prescriber?.firstName;
    this.lastName = "" + this.profileService.loginuser?.prescriber?.lastName;
    this.NPI = "" + this.profileService.loginuser?.prescriber?.npi;
    this.prefferedAddress1 = "" + this.profileService.loginuser?.prescriber?.addressLine1 + " " + this.profileService.loginuser?.prescriber?.addressLine2
    this.prefferedAddress2 = "" + this.profileService.loginuser?.prescriber?.city + ", " + this.profileService.loginuser?.prescriber?.stateProvince + " " + this.profileService.loginuser?.prescriber?.postalCode;
    this.fax = "(" + this.profileService.loginuser?.prescriber?.faxNumber.substring(0, 3) + ") " + this.profileService.loginuser?.prescriber?.faxNumber.substring(3, 6) + "-" + this.profileService.loginuser?.prescriber?.faxNumber.substring(6, 10);
    this.phone = "(" + this.profileService.loginuser?.prescriber?.primaryTelephoneNumber.substring(0, 3) + ") " + this.profileService.loginuser?.prescriber?.primaryTelephoneNumber.substring(3, 6) + "-" + this.profileService.loginuser?.prescriber?.primaryTelephoneNumber.substring(6, 10);


    let request = new supportStaffRequest();
    request.isPendingAccessRqst = "N";
    request.npi = "" + this.profileService.loginuser?.prescriber?.npi;
    this.presciberHelper.getSupportStaff(request).then((response: supportStaffResponse) => {
      this.blnLoader = false;
      if (response.status.dbStatusCode == APPCONSTANTS.API_RESPONSE_DB_SUCCESS_CODE) {
        this.supportStaffList = response;
        this.supportStaffList.presbrSupportStaff.sort((a, b) => a.dateGranted < b.dateGranted ? 1 : -1);
        this.generateStaffDatatable();
      } else {
        if (response.status.respCode == APPCONSTANTS.API_RESPONSE_DB_FAILURE_CODE) {

        }
        else {

        }
      }
    }, (error: any) => {
      this.blnLoader = false;
    }).catch((error: any) => {
      this.blnLoader = false;
    });

    request.isPendingAccessRqst = "Y";

    this.presciberHelper.getSupportStaff(request).then((response: supportStaffResponse) => {
      this.blnLoader = false;
      if (response.status.dbStatusCode == APPCONSTANTS.API_RESPONSE_DB_SUCCESS_CODE) {
        this.approvalPendingsupportStaffList = response;
        this.approvalPendingsupportStaffList.presbrSupportStaff.sort((a, b) => ((a.remainingHrs*60)+a.remainingMins > (b.remainingHrs*60)+b.remainingMins) ? 1 : -1);
        this.generateApprovalPendingStaffDatatable();
      } else {
        if (response.status.respCode == APPCONSTANTS.API_RESPONSE_DB_FAILURE_CODE) { }
        else { }
      }
    }, (error: any) => {
      this.blnLoader = false;
    }).catch((error: any) => {
      this.blnLoader = false;
    });

    // Prescriber Address api
    this.instancId = "" + this.profileService.loginuser?.prescriber?.instanceId;


  }

  generateStaffDatatable() {
    this.entries = [];
    debugger;
    if (this.supportStaffList.presbrSupportStaff.length > 0) {
      //  this.provisionHistory=this.provisionHistory.reverse();
      // this.updateTotalPagesCount();
      this.supportStaffList.presbrSupportStaff.forEach((history: PresbrSupportStaff) => {
        const row: TableRowData<PresbrSupportStaff> = new TableRowData<PresbrSupportStaff>();

        const firstCell: TableCellData<PresbrSupportStaff> = new TableCellData<PresbrSupportStaff>();
        firstCell.data = history;
        firstCell.sortable = true;
        firstCell.template = this.nameTemplateRef;
        firstCell.cssClass = "td1";
        row.cells.push(firstCell);

        const secondCell: TableCellData<PresbrSupportStaff> = new TableCellData<PresbrSupportStaff>();
        secondCell.data = history;
        secondCell.template = this.emailTemplateRef;
        secondCell.cssClass = "td1";
        row.cells.push(secondCell);

        const thirdCell: TableCellData<PresbrSupportStaff> = new TableCellData<PresbrSupportStaff>();
        thirdCell.data = history;
        thirdCell.template = this.phoneTemplateRef;
        secondCell.cssClass = "td1";
        row.cells.push(thirdCell);

        const forthCell: TableCellData<PresbrSupportStaff> = new TableCellData<PresbrSupportStaff>();
        forthCell.data = history;
        forthCell.template = this.dateGrantedTemplateRef;
        secondCell.cssClass = "td1";
        row.cells.push(forthCell);

        const fifthCell: TableCellData<PresbrSupportStaff> = new TableCellData<PresbrSupportStaff>();
        fifthCell.data = history;
        fifthCell.template = this.removeTemplateRef;
        secondCell.cssClass = "td1";
        row.cssClass = "td1";
        row.cells.push(fifthCell);
        this.entries.push(row);
      });
    } else {
      const row: TableRowData<PresbrSupportStaff> = new TableRowData<PresbrSupportStaff>();
      row.cssClass = "norow";
      const firstCell: TableCellData<PresbrSupportStaff> = new TableCellData<PresbrSupportStaff>();
      firstCell.data = undefined;
      firstCell.colspan = this.headers.length;
      firstCell.clickable = false;
      firstCell.sortable = true;
      firstCell.template = this.noSavedHistoryTemplateRef;
      row.cells.push(firstCell);
      this.entries.push(row);
    }
  }


  generateApprovalPendingStaffDatatable() {
    this.entries2 = [];
    debugger;
    if (this.approvalPendingsupportStaffList.presbrSupportStaff.length > 0) {
      this.approvalPendingsupportStaffList.presbrSupportStaff.forEach((history: PresbrSupportStaff) => {
        const row: TableRowData<PresbrSupportStaff> = new TableRowData<PresbrSupportStaff>();

        const firstCell: TableCellData<PresbrSupportStaff> = new TableCellData<PresbrSupportStaff>();
        firstCell.data = history;
        firstCell.sortable = true;
        firstCell.template = this.nameTemplateRef;
        row.cells.push(firstCell);

        const secondCell: TableCellData<PresbrSupportStaff> = new TableCellData<PresbrSupportStaff>();
        secondCell.data = history;
        secondCell.template = this.emailTemplateRef;
        row.cells.push(secondCell);

        const thirdCell: TableCellData<PresbrSupportStaff> = new TableCellData<PresbrSupportStaff>();
        thirdCell.data = history;
        thirdCell.template = this.phoneTemplateRef;
        row.cells.push(thirdCell);

        const forthCell: TableCellData<PresbrSupportStaff> = new TableCellData<PresbrSupportStaff>();
        forthCell.data = history;
        forthCell.template = this.timeRemainingTemplateRef;
        row.cells.push(forthCell);

        const fifthCell: TableCellData<PresbrSupportStaff> = new TableCellData<PresbrSupportStaff>();
        fifthCell.data = history;
        fifthCell.template = this.approveDenyTemplateRef;
        row.cells.push(fifthCell);
        this.entries2.push(row);
      });
    } else {
      const row: TableRowData<PresbrSupportStaff> = new TableRowData<PresbrSupportStaff>();
      row.cssClass = "norow";
      const firstCell: TableCellData<PresbrSupportStaff> = new TableCellData<PresbrSupportStaff>();
      firstCell.data = undefined;
      firstCell.colspan = this.headers.length;
      firstCell.clickable = false;
      firstCell.sortable = true;
      firstCell.template = this.noSavedHistoryTemplateRef;
      row.cells.push(firstCell);
      this.entries2.push(row);
    }
  }

  // private getCurrentPageMembers(): PresbrSupportStaff[] {
  //   debugger;
  //   // if(this.supportStaffList.presbrSupportStaff.length<=this.recordsPerPage){
  //   return this.supportStaffList.presbrSupportStaff;
  //   // }
  //   // else {
  //   //   const startIndex:number = (this.currentPageNumber-1) * this.recordsPerPage;
  //   //   const endIndex:number = (this.currentPageNumber * this.recordsPerPage);
  //   //   return this.supportStaffList.presbrSupportStaff.slice(startIndex, endIndex);
  //   // }
  // }

  // private updateTotalPagesCount(): void {
  //   debugger;
  //   this.totalPagesCount = (this.supportStaffList.presbrSupportStaff.length % this.recordsPerPage) != 0 ? Math.floor(this.supportStaffList.presbrSupportStaff.length / this.recordsPerPage) + 1 : Math.floor(this.supportStaffList.presbrSupportStaff.length / this.recordsPerPage);
  // }

  onCellClicked(event: TableClickEvent<any>) {
    console.log(event);
    debugger;
    if (event.column == 4) {
      this.removeMember = event.cell?.data;
      this.modalService.dismissAll();
      this.modalService.open(this.removeConfirmPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' ,backdrop:'static'});
    }
  }
  onHeaderClicked(event: TableClickEvent<any>) {
    console.log(event);
    debugger;
  }

  updateAddress() {

  }

  newaddress: AlternateAddress | undefined;
  storeNewAddress($event: AlternateAddress) {
    this.newaddress = $event;
  }

  onSelectnewAddress() {
    if (this.newaddress != undefined) {
      var webrequest = new changeDefaultdAdressRequest();
      webrequest.address = new Address();
      webrequest.contact = new Contact();

      webrequest.presbrId = "" + this.profileService.loginuser?.prescriber?.presbrId;
      webrequest.address.address1 = this.newaddress.address.address1;
      webrequest.address.address2 = this.newaddress.address.address2;
      webrequest.address.city = this.newaddress.address.city;
      webrequest.address.state = this.newaddress.address.state;
      webrequest.address.zip1 = this.newaddress.address.zip1;
      webrequest.address.zip2 = this.newaddress.address.zip2;
      webrequest.address.zip3 = this.newaddress.address.zip3;
      webrequest.address.country = this.newaddress.address.country;
      webrequest.contact.phone1 = this.newaddress.contact.phone1;
      webrequest.contact.fax = this.newaddress.contact.fax;
      webrequest.contact.email = this.newaddress.contact.email;

      this.manageprescriberservice.postChangeDefaultAddress(webrequest).then((data: changeDefaultAddressResponse) => {
        if (data.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
          this.newAddressPrescriberName = this.profileService.loginuser?.prescriber?.firstName + ' ' + this.profileService.loginuser?.prescriber?.lastName;
          this.prefferedAddress1 = "" + webrequest.address.address1; +" " + webrequest.address.address2;
          this.prefferedAddress2 = "" + webrequest.address.city + ", " + webrequest.address.state + " " + webrequest.address.zip1;

          this.successAddressChangePopup();
        }
        else {
          this.newAddressPrescriberName = "We are unable to process your request at this time. Please try again later.";
          this.faliurePopup();
        }
      });
    }
    else {
      // this.onResetTile();
    }
  }

  successAddressChangePopup() {
    this.addressChangeSuccessful = true;
    this.blnShowAlternateAddress = false;
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    setTimeout(() => {
      this.renderer.removeChild(this.vanish.nativeElement.parentNode, this.vanish.nativeElement);
      this.addressChangeSuccessful = undefined;
    }, 3000);
    //remove this user from the list

  }

  faliurePopup() {
    this.addressChangeSuccessful = false;
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    setTimeout(() => {
      this.renderer.removeChild(this.vanishFailedScenario.nativeElement.parentNode, this.vanishFailedScenario.nativeElement);
      this.addressChangeSuccessful = undefined;
    }, 3000);
    //remove this user from the list

  }

  successPopup() {
    this.popup = false;
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    setTimeout(() => {
      this.renderer.removeChild(this.vanishSuccessScenario2.nativeElement.parentNode, this.vanishSuccessScenario2.nativeElement);
      this.popup = undefined;
    }, 3000);
    //remove this user from the list

  }

  removeStaffmember() {
    let request = new changeAdminRequest();
    request.adminPresbrId = this.removeMember?.presbrId;
    request.adminUpdateStatus = 103;
    request.presbrPriSecRoleId = this.removeMember?.presbrPriSecRoleId;

    this.presciberHelper.changeAdminStatus(request).then((response: Status) => {
      this.blnLoader = false;
      debugger;
      if (response.dbStatusCode == APPCONSTANTS.API_RESPONSE_DB_SUCCESS_CODE) {
        // Add success scenario  
        this.successPopup();
        this.ngOnInit();
      } else if (response.dbStatusCode == APPCONSTANTS.API_RESPONSE_DB_FAILURE_CODE) {
        this.tryagainType=1;
        this.modalService.open(this.tryAgainPopupTemplateRef, { modalDialogClass: 'tryAgainPopup', size: 'xs' ,backdrop:'static'});
      } else {
        // error in all cases
        this.newAddressPrescriberName = "We are unable to process your request at this time. Please try again later.";
        this.faliurePopup();
      }
    }, (error: any) => {
      this.blnLoader = false;
    }).catch((error: any) => {
      this.blnLoader = false;
    });
  }

  denyRequest(data: PresbrSupportStaff) {
    debugger;
    let request = new changeAdminRequest();
    request.adminPresbrId = data?.presbrId;
    request.adminUpdateStatus = 105;
    request.presbrPriSecRoleId = data?.presbrPriSecRoleId;

    this.presciberHelper.changeAdminStatus(request).then((response: Status) => {
      this.blnLoader = false;
      debugger;
      if (response.dbStatusCode == APPCONSTANTS.API_RESPONSE_DB_SUCCESS_CODE) {
        // Add success scenario  
        this.newAddressPrescriberName=" Access request for "+data?.firstName+" "+data.lastName+" has been successfully denied.";
        this.successPopup();
        this.ngOnInit();
      } else if (response.dbStatusCode == APPCONSTANTS.API_RESPONSE_DB_FAILURE_CODE) {
        this.tryagainType=2;
        this.tryagainData=data;
        this.modalService.open(this.tryAgainPopupTemplateRef, { modalDialogClass: 'tryAgainPopup', size: 'xs' ,backdrop:'static'});
      } else {
        this.newAddressPrescriberName = "We are unable to process your request at this time. Please try again later.";
        this.faliurePopup();
        // error in all cases
      }
    }, (error: any) => {
      this.blnLoader = false;
    }).catch((error: any) => {
      this.blnLoader = false;
    });
  }
  
  approveRequest(data: PresbrSupportStaff) {
    debugger;
    let request = new changeAdminRequest();
    request.adminPresbrId = data?.presbrId;
    request.adminUpdateStatus = 100;
    request.presbrPriSecRoleId = data?.presbrPriSecRoleId;

    this.presciberHelper.changeAdminStatus(request).then((response: Status) => {
      this.blnLoader = false;
      debugger;
      if (response.dbStatusCode == APPCONSTANTS.API_RESPONSE_DB_SUCCESS_CODE) {
        // Add success scenario  
        this.newAddressPrescriberName=" Access request for "+data?.firstName+" "+data.lastName+" has been successfully approved.";
        this.successPopup();
        this.ngOnInit();
      } else if (response.dbStatusCode == APPCONSTANTS.API_RESPONSE_DB_FAILURE_CODE) {
        this.tryagainType=3;
        this.tryagainData=data;
        this.modalService.open(this.tryAgainPopupTemplateRef, { modalDialogClass: 'tryAgainPopup', size: 'xs' ,backdrop:'static'});
      } else {
        this.newAddressPrescriberName = "We are unable to process your request at this time. Please try again later.";
        this.faliurePopup();
        // error in all cases
      }
    }, (error: any) => {
      this.blnLoader = false;
    }).catch((error: any) => {
      this.blnLoader = false;
    });
  }

  tryAgain(){
    switch(this.tryagainType){
      case 1: 
      this.removeStaffmember();
      break;
      case 2:
      this.denyRequest(this.tryagainData);
      break;
      case 3:
      this.approveRequest(this.tryagainData);  
      break;
    }

  }
}
