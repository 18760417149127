<div>
    <div Class="back1">
        <div class="container-fluid " class="back2">
            <div>
                <h2 style="    color: #fff;
                padding-top: 50px;
                margin-left: 7.6%;">Banner Message Pannel</h2>
            </div>
            <div class="container-fluid c1">
                <div>Use the form below to display a banner notice message in PreCheck MyScript home page.</div>
            </div>
            <div style="padding-top:12px;margin: 0px;">
                <div class="tab-content justify-content-center">
                    <div class="tab-pane fade show active" style="    display: flex;
                    justify-content: center;" role="tabpanel">
                        <div class="card card_size old_card">
                            <div class="card-body" style="padding: 30px;">
                                <div class="row">
                                    <div class="col-md-12">
                                        <p class="paragraph fontbold nopadding ng-binding" style="margin-bottom: 5px;">
                                            Please enter the message to be displayed.
                                        </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-12 col-sm-12">
                                        <form [formGroup]="bannerForm" id="fax-verfication">
                                            <input formControlName="bannerMsgField" type="text"
                                                class="form-control msg_default input_box">
                                            <div
                                                style="position: relative;height: 20px;align-items: center;display: flex;">
                                                <span class="error-msg"
                                                    *ngIf="bannerForm.controls.bannerMsgField.errors?.['required'] && show">Message
                                                    is required.</span>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer" style="border: none;background-color: rgba(242, 246, 249, 1);">
                                <div class="container c5">
                                    <div class="container-fluid ng-scope">
                                        <div class="row" style="text-align: end;">
                                            <div class="col-9" style="padding:10px 0px">
                                                <form [formGroup]="bannerForm">
                                                    <div class="toggle-swicth">
                                                        <label class="toggle-switch1">
                                                            <input type="checkbox" formControlName="bannerMsgbln">
                                                            <span class="slider1">
                                                                <div *ngIf="bannerForm.get('bannerMsgbln')?.value"
                                                                    style="display: flex;justify-content: start;width: 100%;height: 100%;align-items: center;">
                                                                    <span style="padding-left: 20px;">Yes</span>
                                                                </div>
                                                                <div *ngIf="!bannerForm.get('bannerMsgbln')?.value"
                                                                    style="display: flex;justify-content: end;width: 100%;height: 100%;align-items: center;">
                                                                    <span style="padding-right: 20px;">No</span>
                                                                </div>
                                                            </span>
                                                        </label>
                                                    </div>
                                                </form>
                                            </div>
                                            <div class="col-3">
                                                <button type="submit" (click)="onMessageSubmit()"
                                                    class="btn primary btn_primary search_button font_bold_no_color"
                                                    style="max-width:175px;width: 100%;">Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<ng-template #errorPopup let-modal>
    <div class="content-card text-center">
        <div class="card-content">
            <i class="fa fa-exclamation-circle"></i>
            <p class="large" style="padding-top: 20px;">
                <span>An error occured. please try again later.</span>
            </p>
            <br />
            <button class="btn btn-primary" (click)="modal.dismiss();">Ok</button>
        </div>
    </div>
</ng-template>