import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Router } from '@angular/router';

import {TokenService} from 'src/app/utility/auth.guard';
import {APPCONSTANTS, USER_ROLE, BOOLEAN_FLAG} from 'src/app/constants/appconstants';
import { environment } from 'src/environments/environment';
import { Loginwebresponse, Prescriber } from 'src/app/modals/login/loginwebresponse';
import { Loginuser } from 'src/app/modals/login/loginviewmodal';
import { CookieService } from 'ngx-cookie-service';
import { updateBannerRequest } from 'src/app/modals/bannerMsg/updateBannerRequest';



@Injectable({
  providedIn: 'root'
})
export class ProfileService implements TokenService {

  constructor(private http:HttpClient, private router:Router, private cookieService: CookieService){
  }

  
  private _features : Features = new Features;
  public get features() : Features{
    return this._features;
  }
  
  
  private _appLogin: APPCONSTANTS = APPCONSTANTS.APP_NOT_INITIALIZED;
  public get appLogin() : APPCONSTANTS {
    return this._appLogin;
  }
  public set appLogin(v : APPCONSTANTS) {
    this._appLogin = v;
  }

  
 /* private _loginuser : Loginuser|undefined;
  public get loginuser() : Loginuser | undefined{
    return this._loginuser;
  }*/
  private _loginuser !: Loginuser;
  public get loginuser() : Loginuser{
    return this._loginuser;
  }
  
  private _USER_ROLE !: USER_ROLE;
  public get USER_ROLE() : USER_ROLE {
    return this._USER_ROLE;
  }
  private setUserRole(role:USER_ROLE){
    this._USER_ROLE = role;
    this._features=new Features();
    switch(role){
      case USER_ROLE.ADMIN:
        this._features.membersearch=true;
        this._features.epaflow=true;
        this._features.checkcoverageflow=true;
        this._features.manageprescribers=true;
        //removeds as per the user story US7534822
        // this._features.benefitinquiry=true;
        break;
      case USER_ROLE.PRESCRIBER:
        this._features.membersearch=true;
        this._features.epaflow=true;
        this._features.checkcoverageflow=true;
        this._features.prescriberprofile=true;
        break;
      case USER_ROLE.ADVISOR:
        this._features.membersearch=true;
        this._features.checkcoverageflow=true;
        break;
      case USER_ROLE.PHARMACIST:
        this._features.membersearch=true;
        this._features.checkcoverageflow=true;
        break;
      case USER_ROLE.SPECIALTYPHARMACIST:
        this._features.epaflow=true;
        break;
      case USER_ROLE.SUPPORT:
        //Support login is currently through technical error page upon login. Hence no capabilities been added.
        break;
    }
    if(this.loginuser?.prescriber?.userType?.toLowerCase() == "i" && role != USER_ROLE.SUPPORT){
      if(role == USER_ROLE.ADMIN || environment.ohid_proviosioning.indexOf(this.loginuser.prescriber.optumId)!=-1)
        this._features.provisioning=true;
      if(environment.ohid_controlpanel.indexOf(this.loginuser.prescriber.optumId)!=-1)
        this._features.controlpannel=true;
      if(environment.ohid_bannerpanel.indexOf(this.loginuser.prescriber.optumId)!=-1)
        this._features.bannerpannel=true;
      
    }
  }

  private getLoggedInUser(webResponse: Loginwebresponse):Prescriber | undefined{
    if(Array.isArray(webResponse.prescribers) && webResponse.prescribers.length>0)
    {
        for(let i:number=0;i<webResponse.prescribers.length;i++){
          if(webResponse.prescribers[i].approveInd == BOOLEAN_FLAG.YES)
            return webResponse.prescribers[i];
        }
        return undefined;
    } 
    else 
      return undefined;

  }

  private getDefaultPrescriber(webResponse: Loginwebresponse, uuid:string): Prescriber | undefined{
    if(Array.isArray(webResponse.prescribers) && webResponse.prescribers.length>0)
    {
        for(let i:number=0;i<webResponse.prescribers.length;i++){
          if(webResponse.prescribers[i].uuid != uuid)
            return webResponse.prescribers[i];
        }
        return undefined;
    } 
    else 
      return undefined;
  }

  private setLoginUser(webResponse: Loginwebresponse) : APPCONSTANTS{
      let user:Loginuser = new Loginuser();
      let loggedInUser:Prescriber | undefined = this.getLoggedInUser(webResponse);
      if(loggedInUser?.roleTypeId!=undefined)
      {
          if(loggedInUser.roleTypeId == USER_ROLE.ADMIN.toString())
          {
            user.defaultPrescriber = this.getDefaultPrescriber(webResponse, loggedInUser.uuid);
            if(user.defaultPrescriber!=undefined)
            {
              user.prescriber=loggedInUser;
              this._loginuser=user;
              this.setUserRole(USER_ROLE.ADMIN);
              return APPCONSTANTS.APP_INITIALIZED;
            }
            else
              return APPCONSTANTS.APP_LOGIN_NO_DEFAULT_PRESCRIBER;
          } else {
              if(loggedInUser.roleTypeId == USER_ROLE.PRESCRIBER.toString() 
              || loggedInUser.roleTypeId == USER_ROLE.ADVISOR.toString() 
              || loggedInUser.roleTypeId == USER_ROLE.SUPPORT.toString()
              || loggedInUser.roleTypeId == USER_ROLE.PHARMACIST.toString()
              || loggedInUser.roleTypeId == USER_ROLE.SPECIALTYPHARMACIST.toString())
              {
                user.prescriber=loggedInUser;
                switch(loggedInUser.roleTypeId){
                  case USER_ROLE.PRESCRIBER.toString():
                    this._loginuser = user;
                    this.setUserRole(USER_ROLE.PRESCRIBER);
                    break;
                  case USER_ROLE.ADVISOR.toString():
                    this._loginuser = user;
                    this.setUserRole(USER_ROLE.ADVISOR);
                    break;
                  case USER_ROLE.SUPPORT.toString():
                    //this._loginUser = user;
                    this.setUserRole(USER_ROLE.SUPPORT);
                    return APPCONSTANTS.APP_INITIALIZATION_ERROR;
                  case USER_ROLE.PHARMACIST.toString():
                    this._loginuser = user;
                    this.setUserRole(USER_ROLE.PHARMACIST);
                    break;
                  case USER_ROLE.SPECIALTYPHARMACIST.toString():
                    this._loginuser = user;
                    this.setUserRole(USER_ROLE.SPECIALTYPHARMACIST);
                    break;
                  default:
                    this._loginuser = user;
                    this.setUserRole(USER_ROLE.NO_ROLE);
                    return APPCONSTANTS.APP_LOGIN_ROLES_MISSING;
                }
                return APPCONSTANTS.APP_INITIALIZED;
              }
              return APPCONSTANTS.APP_INITIALIZATION_ERROR;
          }
      } else {
        return APPCONSTANTS.APP_LOGIN_USER_NOT_FOUND;
      }
    }
  

  readJWTTokenAndUserDetails(accessCode:String, blnAppInitialization:boolean=false):Promise<boolean>{
    const promise:Promise<boolean> = new Promise<boolean>((resolve:any,reject:any)=>{
      let header=new HttpHeaders({"endpoint":environment.loginUrl,"X-CSRF-Token":this.getCsrfToken()});
      this.http.post(environment.loginProxyurl,{"code":accessCode,"redirect_url":environment.sso_redirectUri},{headers:header}).subscribe((response:Loginwebresponse)=>{
        sessionStorage.setItem('loginuserdata',JSON.stringify(response));
        this.appLogin=this.setLoginUser(response);
        if(this.appLogin == APPCONSTANTS.APP_INITIALIZED)
        {
          //this.logger.userAction(`Login successfull for OHID:${response.ohid}`);
          resolve(true);  
        }
        else
        {
          if(this.appLogin == APPCONSTANTS.APP_LOGIN_USER_NOT_FOUND)
          {
            sessionStorage.setItem("selfregistration",JSON.stringify({uuid:response.uuid,ohid:response.ohid}));
            //this.logger.userAction(`Started registration for ${response.ohid}`);
            this.router.navigateByUrl('/register'); 
          }
          else
          {
            //this.logger.error(`Login error for ${response.ohid}, error code:${this.appLogin}`);
            this.router.navigateByUrl("/error");
          }
          reject(false);
        }
      },(error:any)=>{
        if(!blnAppInitialization){
          if(error.status==403){
            this.appLogin = APPCONSTANTS.APP_LOGIN_FORBIDDEN;
            sessionStorage.clear();
            this.router.navigateByUrl("/sso");
            reject(false);
          }
          else
          {
            this.appLogin = APPCONSTANTS.APP_INITIALIZATION_ERROR;
            sessionStorage.clear();
            this.router.navigateByUrl("/error");
            reject(false);
          }
          //this.logger.error(`Login error for access code:${accessCode}`);
        }
        reject(false);
      });
    });
    return promise;
  }

  public getCsrfToken(): string {
    return this.cookieService.get('XSRF-TOKEN');
  }
  // tokenId: string
  logoutUser(): Promise<any> {
    debugger;    
    const promise: Promise<any> = new Promise<any>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.logoutUrl.replace("{redirect_uri}",environment.sso_redirectUri).replace("{some_random_string}",environment.logout_random_string),"X-CSRF-Token":this.getCsrfToken()});
      this.http.get(environment.logoutProxyurl,{headers:header}).subscribe((response: any) => {
        if (response != undefined){
          sessionStorage.clear();
          this.cookieService.deleteAll('/');
          resolve(response);
        } else {
          resolve(response);
        }
      }, (error: any) => {
        // this.logger.error(error);
        reject(error);
      });
    });
    return promise;
    
  }

  getBannerMessage(): Promise<any> {
    const promise: Promise<any> = new Promise<any>((resolve, reject) => {
      let header = new HttpHeaders({ "endpoint": environment.bannerMessageUrl, "X-CSRF-Token": this.getCsrfToken() });
      this.http.get(environment.proxyurl,{ headers: header }).subscribe((response: any) => {
        debugger;
        if (response.message != undefined && response.status != undefined
          && response.status.statusCode != undefined && response.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
          resolve(response);
        } else {
          resolve(response);
        }
      }, (error: any) => {
        reject(error);
      });
    });
    return promise;
  }

  postBannerMessage(webrequest: updateBannerRequest): Promise<any> {
    const promise: Promise<any> = new Promise<any>((resolve, reject) => {
      let header = new HttpHeaders({ "endpoint": environment.updateBannerMessageUrl, "X-CSRF-Token": this.getCsrfToken() });
      this.http.post(environment.proxyurl, webrequest, { headers: header }).subscribe((response: any) => {
        debugger;
        if (response.status != undefined
          && response.status.statusCode != undefined && response.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
          resolve(response);
        } else {
          resolve(response);
        }
      }, (error: any) => {
        reject(error);
      });
    });
    return promise;
  }

  
}



export class Features{
  membersearch:boolean=false;
  epaflow:boolean=false;
  checkcoverageflow:boolean=false;
  manageprescribers:boolean=false;
  prescriberprofile:boolean=false;
  provisioning:boolean=false;
  controlpannel:boolean=false;
  benefitinquiry:boolean=false;
  bannerpannel:boolean=false;
}