import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {StringToSentenceCase} from 'src/app/pipes/stringconvertions/stringconversions.pipe';
 import { PrescriberSearchCriteria, State } from 'src/app/modals/prescriberSearch/prescriberviewmodal';
import { PrescriberDemographs, PrescriberSearchItem, PrescriberDetailWebResponse, PrescriberAlternateAddress, PrescriberDetailItem, PrescriberFavoriteResponse, PrescriberFavoriteWebResponse, PrescriberSearchRootObject, VerifyProviderResponse, supportStaffResponse } from 'src/app/modals/prescriberSearch/prescriberSearchwebresponse';
import { PrescriberDetailWebRequest, PrescriberFavoriteWebRequest, VerifyProviderRequest, changeAdminRequest, supportStaffRequest } from 'src/app/modals/prescriberSearch/prescriberSearchweberequest';
import { PrescriberIdentifier } from 'src/app/modals/prescriberSearch/prescriberSearchwebresponse';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { ResponseRootObj, Status } from 'src/app/modals/webresponsestatus';
import { LoggerService } from '../logger/logger.service';
import { APPCONSTANTS, PRESCRIBER_QUALIFIER, STATES } from 'src/app/constants/appconstants';
import { addPrescriberRequest, addPrescriberResponse } from 'src/app/modals/addPrescribers/addPrescriberRequest';
import { ProfileService } from '../profile/profile.service';

@Injectable({
  providedIn: 'root'
})
export class PrescriberSearchService {

  savedPrescribers:BehaviorSubject <PrescriberFavoriteWebResponse[]> = new BehaviorSubject <PrescriberFavoriteWebResponse[]>([]);
  
   states!:State[];

  
  private _SELECTED_PRESCRIBER_KEY : string="selectedPrescriber";
  public get SELECTED_PRESCRIBER_KEY() : string {
    return this._SELECTED_PRESCRIBER_KEY;
  }

  
  private _PRESCRIBER_RESULTS : string = "prescribersearchresults";
  public get PRESCRIBER_RESULTS() : string {
    return this._PRESCRIBER_RESULTS;
  }
  
  
  private _SEARCH_CRITERIA_KEY : string="prescribersearchcriteria";
  public get SEARCH_CRITERIA_KEY() : string {
    return this._SEARCH_CRITERIA_KEY;
  }

  
  private _SELECTED_PRESCRIBER_DETAIL_KEY : string="prescriberdetail";
  public get SELECTED_PRESCRIBER_DETAIL_KEY() : string {
    return this._SELECTED_PRESCRIBER_DETAIL_KEY;
  }
  public set SELECTED_PRESCRIBER_DETAIL_KEY(v : string) {
    this._SELECTED_PRESCRIBER_DETAIL_KEY = v;
  }
  
  

  private mapPrescriberDetails(webrequest:PrescriberDetailWebRequest,details:PrescriberDetailWebResponse):void{
    details.response.prescriberDetailItem.instanceId=webrequest.instanceId;
    /************************Bring primary address to front in the alternatives addresses************************** */
    for(let i:number=0;i<details.response.prescriberDetailItem.alternateAddresses.length;i++){
      if(details.response.prescriberDetailItem.alternateAddresses[i].qualifier!=undefined
        && details.response.prescriberDetailItem.alternateAddresses[i].qualifier.trim()?.toLowerCase()=="primary"){
          let primaryAddress:any = details.response.prescriberDetailItem.alternateAddresses.splice(i,1);
          details.response.prescriberDetailItem.alternateAddresses.splice(0,0,primaryAddress[0]);
          break;
        }
    }
    /**************Push the default address of a prescriber details item into alternative addresses list******************* */
    if(details.response.prescriberDetailItem.address!=undefined && Array.isArray(details.response.prescriberDetailItem.alternateAddresses)
      && details.response.prescriberDetailItem.contact!=undefined && details.response.prescriberDetailItem.contact.fax!=undefined && details.response.prescriberDetailItem.contact.fax.length==10){
      let blnSameFaxAddressInAlt:boolean=false;
      details.response.prescriberDetailItem.alternateAddresses.forEach((address:PrescriberAlternateAddress)=>{
        if(address.contact!=undefined && address.contact.fax!=undefined && address.contact.fax==details.response.prescriberDetailItem.contact.fax){
          blnSameFaxAddressInAlt=true;
        }
      });
      if(!blnSameFaxAddressInAlt){
        const defaultAddress:PrescriberAlternateAddress = new PrescriberAlternateAddress();
        defaultAddress.contact=details.response.prescriberDetailItem.contact;
        defaultAddress.address=details.response.prescriberDetailItem.address;
        defaultAddress.qualifier="default";
        details.response.prescriberDetailItem.alternateAddresses.push(defaultAddress);
      }
    }
  }
  

  constructor( private http:HttpClient,
    private sentenseCasePipe:StringToSentenceCase, private logger: LoggerService,private profileService:ProfileService) { 
    this.states=[]
    STATES.forEach((value:string,key:string)=>{
     this.states.push({name:key,code:value});
    });
  }

 /* getPrescriberSearch(object:PrescriberSearchCriteria){

    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    }
    return this.http.post<any>(environment.prescriberSearchUrl,object,httpOptions);
  }*/

   fetchPrescriberSearch(webrequest:PrescriberSearchCriteria):Promise<PrescriberSearchRootObject>{
    const promise:Promise<PrescriberSearchRootObject> = new Promise<PrescriberSearchRootObject>((resolve,reject)=>{
      let header=new HttpHeaders({"endpoint":environment.prescriberSearchUrl,"X-CSRF-Token":this.profileService.getCsrfToken()});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        debugger;
        if(response!=undefined && response.status!=undefined && response.status.statusCode!=undefined
          && response.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
          && response.status.respCode!=undefined && response.status.respCode == APPCONSTANTS.API_RESPONSE_SUCCESS_CODE){
            resolve(response);
          } else {
            this.logger.log(response);
          reject(response);
          }
      },(error:any)=>{
        this.logger.error(error);
          reject(error);
      });
    });
    return promise;
  }

  getPrescriberDetail(webrequest:PrescriberDetailWebRequest):Promise<PrescriberDetailWebResponse>{
    const promise:Promise<PrescriberDetailWebResponse> = new Promise<PrescriberDetailWebResponse>((resolve,reject)=>{
      let header=new HttpHeaders({"endpoint":environment.prescriberDetailUrl,"X-CSRF-Token":this.profileService.getCsrfToken()});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        if(response!=undefined && response.status!=undefined 
          && response.status.statusCode!=undefined
          && response.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT?.toLowerCase()
          && response.status.respCode!=undefined 
          && (response.status.respCode == APPCONSTANTS.API_RESPONSE_SUCCESS_CODE || response.status.respCode == APPCONSTANTS.API_RESPONSE_NO_RECORDS)){
            if(response.response!=undefined)
            {
              this.mapPrescriberDetails(webrequest, response);
            }
            resolve(response);
          } else {
            this.logger.log(response);
          reject(response);
          }
      },(error:any)=>{
        this.logger.error(error);
          reject(error);
      });
    });

    return promise;
  }

  storePrescriberResults(results:PrescriberSearchItem[]):void{
    if(Array.isArray(results)){
      sessionStorage.setItem(this.PRESCRIBER_RESULTS,JSON.stringify(results));
    }
  }

  readPrescriberResults():PrescriberSearchItem[] | undefined{
    const results:string | null = sessionStorage.getItem(this.PRESCRIBER_RESULTS);
    if(results!=null && results.length>0){
      return JSON.parse(results);
    }
    return undefined;
  }
  clearPrescriberResults():void{
    sessionStorage.removeItem(this.PRESCRIBER_RESULTS);
  }

  storePrescriberSearchCriteria(prescriber:PrescriberSearchCriteria):void{
    if(prescriber!=undefined){
      sessionStorage.setItem(this.SEARCH_CRITERIA_KEY, JSON.stringify(prescriber));
    }
  }

  readPrescriberSearchCriteria():PrescriberSearchCriteria | undefined{
    const prescriber:string | null = sessionStorage.getItem(this.SEARCH_CRITERIA_KEY);
    if(prescriber!=null && prescriber.length>0)
    {
      return JSON.parse(prescriber)
    }
    return undefined;
  }

  clearPrescriberSearchCriteri():void{
    sessionStorage.removeItem(this.SEARCH_CRITERIA_KEY);
  }

  storeSelectedPrescriber(prescriber:PrescriberSearchItem | undefined):void{
    if(prescriber!=undefined && prescriber!=null)
      sessionStorage.setItem('selectedPrescriber',JSON.stringify(prescriber));
  }

  readSelectedPrescriber():PrescriberSearchItem | undefined{
    const prescriber:string | null = sessionStorage.getItem(this.SELECTED_PRESCRIBER_KEY);
    if(prescriber != null){
      return JSON.parse(prescriber);
    }
    return undefined;
  }

  clearSelectedPrescriber():void{
    sessionStorage.removeItem(this.SELECTED_PRESCRIBER_KEY);
  }

  storeSelectedPrescriberDetail(prescriberdetail:PrescriberDetailItem):void{
    debugger;
    if(prescriberdetail!=undefined){
      sessionStorage.setItem(this.SELECTED_PRESCRIBER_DETAIL_KEY,JSON.stringify(prescriberdetail));
    }
  }

  readSelectedPrescriberDetail(): PrescriberDetailItem | undefined{
    const prescriber:string | null = sessionStorage.getItem(this.SELECTED_PRESCRIBER_DETAIL_KEY);
    if(prescriber!=null){
      return JSON.parse(prescriber);
    }
    return undefined;
  }

  clearSelectedPrescriberDetail():void{
    sessionStorage.removeItem(this.SELECTED_PRESCRIBER_DETAIL_KEY);
  }

  clearSession():void{
    this.clearPrescriberResults();
    this.clearPrescriberSearchCriteri();
    this.clearSelectedPrescriber();
    this.clearSelectedPrescriberDetail();
  }

  getSelectedPrescriberFullName(givenPrescriber?:PrescriberDemographs):string{
    const prescriber: PrescriberDemographs | undefined = (givenPrescriber!=undefined)?givenPrescriber:this.readSelectedPrescriber();
    if(prescriber!=undefined){
      return prescriber.lastName+" "+prescriber.firstName;
    }
    return "";
  }

  getSelectedPrescriberCompleteName(givenPrescriber?:PrescriberSearchItem):string{
    const prescriber: PrescriberSearchItem | undefined = (givenPrescriber!=undefined)?givenPrescriber:this.readSelectedPrescriber();
    if(prescriber!=undefined ){
      if(prescriber.middleInitial!=undefined)
        return this.sentenseCasePipe.transform(prescriber.firstName)+" "+prescriber.middleInitial+" "+ this.sentenseCasePipe.transform(prescriber.lastName);
      else
      return this.sentenseCasePipe.transform(prescriber.firstName)+" "+ this.sentenseCasePipe.transform(prescriber.lastName);
    }
    return "";
  }

  getSelectedPrescriberDetailNPI(givenPrescriber?:PrescriberDetailItem,qualifier:PRESCRIBER_QUALIFIER=PRESCRIBER_QUALIFIER.NPI):string{
    if(givenPrescriber!=undefined && Array.isArray(givenPrescriber.identifiers)){
      for(let i:number=0;i<givenPrescriber.identifiers.length;i++){
        if(givenPrescriber.identifiers[i].qualifier==qualifier)
          return givenPrescriber.identifiers[i].id;
      }
    }
    return "";
  } 

  findPrescriberNPI(prescriberIdentifiers:PrescriberIdentifier[],qualifier:PRESCRIBER_QUALIFIER=PRESCRIBER_QUALIFIER.NPI):string{
    for(let i:number=0;i<prescriberIdentifiers.length;i++){
      if(prescriberIdentifiers[i].qualifier==qualifier)
        return prescriberIdentifiers[i].id;
    }
    return "";
  }

  getSelectedPrescriberNPI(givenPrescriber?:PrescriberSearchItem,qualifier:PRESCRIBER_QUALIFIER=PRESCRIBER_QUALIFIER.NPI):string{
   
    const prescriber: PrescriberSearchItem | undefined = (givenPrescriber!=undefined)?givenPrescriber:this.readSelectedPrescriber();
    if(prescriber!=undefined && Array.isArray(prescriber.identifiers)){
      return this.findPrescriberNPI(prescriber.identifiers);
    }
    return "";
  }

  getAlternativeAddressLine3(altAddress:PrescriberAlternateAddress):string{
    if(altAddress!=undefined && altAddress.address!=undefined){
      if(altAddress.address.city!=undefined && altAddress.address.city.length>0){
        return this.sentenseCasePipe.transform(altAddress.address.city)+", "+altAddress.address.state+" "+altAddress.address.zip1;
      } else {
        return altAddress.address.state+" "+altAddress.address.zip1;
      }
    }
    return "";
  }

  getSelectedPrescriberAddress3(givenPrescriber?:PrescriberSearchItem):string{
    const prescriber: PrescriberSearchItem | undefined = (givenPrescriber!=undefined)?givenPrescriber:this.readSelectedPrescriber();
    if(prescriber!=undefined && typeof prescriber.address=="object"){
      if(prescriber.address.city!=undefined && prescriber.address.city.length>0){
        return this.sentenseCasePipe.transform(prescriber.address.city)+", "+prescriber.address.state+" "+prescriber.address.zip1;
      } else{
        return prescriber.address.state+" "+prescriber.address.zip1;
      }
    }
    return "";
  }

  getPrescriberValidAlternativeAddresses(addresses:PrescriberAlternateAddress[]):PrescriberAlternateAddress[]{
    if(Array.isArray(addresses) && addresses.length>0){
      return addresses.filter((address:PrescriberAlternateAddress)=>{
        return address.contact!=undefined && address.contact.fax!=undefined && address.contact.fax.length==10?true:false;
      });
    }
    return [];
  }


  getFavouritePrescribers(presbrId:string,UUID:string):Promise<PrescriberFavoriteResponse>{
    
    const promise:Promise<PrescriberFavoriteResponse> = new Promise<PrescriberFavoriteResponse>((resolve,reject)=>{
      let header=new HttpHeaders({"endpoint":environment.prescriberDetailUrl,"X-CSRF-Token":this.profileService.getCsrfToken()});
      this.http.post(environment.favouritePrescribersUrl.replace("{presbrId}",presbrId).replace("{UUID}",UUID),{headers:header}).subscribe((response:any)=>{
        if(response.status!=undefined 
          && response.status.statusCode!=undefined && response.status.statusCode?.toLowerCase()==APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
          && response.status.dbStatusCode!=undefined && response.status.dbStatusCode==APPCONSTANTS.API_RESPONSE_SUCCESS_NBR){
            this.savedPrescribers.next(response.prescriberFavoriteList);
            //console.log("presres",response.prescriberFavoriteList);
            //console.log("res",response);
            resolve(response);
          } else {
            this.savedPrescribers.next([]);
            resolve(response);
          }
      }, (error:any)=>{
        this.savedPrescribers.next([]);
        this.logger.error(error);
          reject(error);
      });
    });
    return promise;
  }
  
  public getCachedFavouritePrescribers():PrescriberFavoriteWebResponse[]{
    return this.savedPrescribers.getValue();
  }

  public isFavouritePrescriber(npi:string):boolean{
    
    //console.log("NPI To check:"+npi);
    //console.log(this.getCachedFavouritePrescribers());
    return this.getCachedFavouritePrescribers().filter((prescrber:any)=> prescrber.npi == npi).length>0?true:false;
  }

  public getFavouritePrescriber(npi:string): PrescriberFavoriteWebResponse | undefined{
    
    if(this.getCachedFavouritePrescribers().length>0){
     const prescribers = this.getCachedFavouritePrescribers().filter((data)=>data.npi == npi);
      //console.log("cache");
      //console.log(this.getCachedFavouritePrescribers());
     return prescribers.length>0?prescribers[0]:undefined;
    }
    return undefined;
  }


  public addFavouritePrescriber(prescrber:PrescriberFavoriteWebRequest):Promise<ResponseRootObj>{
    const promise:Promise<ResponseRootObj> = new Promise<ResponseRootObj>((resolve,reject)=>{
      this.http.post(environment.addFavouritePrescriberUrl,prescrber).subscribe((response:any)=>{
        //console.log(response);
        if(response.status!=undefined 
          && response.status.statusCode!=undefined && response.status.statusCode?.toLowerCase()==APPCONSTANTS.API_RESPONSE_SUCCESS_TXT){
            resolve(response);
          } else {
            this.logger.log(response);
          reject(response);
          }
      }, (error:any)=>{
        this.logger.error(error);
          reject(error);
      });
    });
    return promise;
  }


  
  public removeFavouritePrescriber(presbrFavId:number):Promise<Status>{
    const promise:Promise<Status> = new Promise<Status>((resolve,reject)=>{
      this.http.post(environment.removeFavouritePrescriberUrl.replace("{presbrFavId}",presbrFavId.toString()),null).subscribe((response:any)=>{
        
        if(response.status!=undefined 
          && response.status.statusCode!=undefined && response.status.statusCode?.toLowerCase()==APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
          && response.status.dbStatusCode!=undefined && response.status.dbStatusCode?.toLowerCase()==APPCONSTANTS.API_RESPONSE_DB_SUCCESS_CODE){
            resolve(response.status);
          } else {
            reject(response.status);
          }
      }, (error:any)=>{
        
        this.logger.error(error);
          reject(error);
      });
    });
    return promise;
  }

  addPrescriber(webrequest:addPrescriberRequest):Promise<addPrescriberResponse>{
    debugger;
    const promise:Promise<addPrescriberResponse> = new Promise<addPrescriberResponse>((resolve,reject)=>{
      let header=new HttpHeaders({"endpoint":environment.addPrescribersUrl,"X-CSRF-Token":this.profileService.getCsrfToken()});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        debugger;
        for(let i=0;i<response.addprescriberslist.length;i++){
          debugger;
        if(response!=undefined && response.addprescriberslist[i].status!=undefined && response.addprescriberslist[i].status.statusCode!=undefined
          && response.addprescriberslist[i].status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT){
            resolve(response);
          } else {
            this.logger.log(response);
          reject(response);
          }
        }
      },(error:any)=>{
        this.logger.error(error);
          reject(error);
      });
    });
    return promise;
  }


  getSupportStaff(webrequest:supportStaffRequest):Promise<supportStaffResponse>{
    debugger;
    const promise:Promise<supportStaffResponse> = new Promise<supportStaffResponse>((resolve,reject)=>{
      let header=new HttpHeaders({"endpoint":environment.getSupportStaffUrl,"X-CSRF-Token":this.profileService.getCsrfToken()});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        debugger;
        if(response!=undefined && response.status!=undefined && response.status.statusCode!=undefined
          && response.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
          && response.status.dbStatusCode!=undefined && response.status.dbStatusCode == APPCONSTANTS.API_RESPONSE_DB_SUCCESS_CODE){
            resolve(response);
          } else {
            this.logger.log(response);
          reject(response);
          }
      },(error:any)=>{
        this.logger.error(error);
          reject(error);
      });
    });
    return promise;
  }

  changeAdminStatus(webrequest:changeAdminRequest):Promise<Status>{
    debugger;
    const promise:Promise<Status> = new Promise<Status>((resolve,reject)=>{
      let header=new HttpHeaders({"endpoint":environment.changeAdminStatus,"X-CSRF-Token":this.profileService.getCsrfToken()});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        debugger;
        if(response!=undefined && response.statusCode!=undefined
          && response.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
          && response.dbStatusCode!=undefined){
            resolve(response);
          } else {
            this.logger.log(response);
          reject(response);
          }
      },(error:any)=>{
        this.logger.error(error);
          reject(error);
      });
    });
    return promise;
  }


}