<div class="link_nav row">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 text-center nav-bar-title">
                <span href="#"><img id="u142_img" class="img u142_img" src="assets/images/u142.png">
                </span>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid" style="position: relative;top:165px;" *ngIf="!deactivated">
    <div class="row" style="text-align: center;">
        <div class="col-6" style="text-align: right; padding-right: 25px;">
            <img class="img" src="assets/images/errorpageimage.png"
                style="position: relative;top:0px;width: 320px;height: 320px;">
        </div>
        <div id="genericerror" class="col-6" style="text-align: left;padding-left: 15px;padding-right: 15px;">
            <h1 class="head_txt" id="header_txt">We are experiencing<br>
                technical difficulties</h1>
            <p class="paragraph_text">
                <span id="info_txt">Please refresh the page in case it's a temporary<br> glitch, try again later or call
                    1-866-842-3278 if you <br> need immediate assistance.</span>
            </p>
            <button id="tryagain" class="btn btn-primary primary try_again_btn" (click)="loginToPreCheckMyScript()">Try
                again</button>
        </div>
        <div id="appdowntime" class="col-sm-6" style="text-align: left; display: none;">
            <h1 id="header_txt"></h1>
            <p class="paragraph" style="padding-top: 10px;padding-bottom: 20px;">
                <span id="info_txt">
                </span>
            </p>
        </div>
    </div>
</div>


<div class="container-fluid" style="position: relative;top:175px;" *ngIf="deactivated">

    <div class="row" style="text-align: center;">
        <div class="col-sm-4" style="text-align: right; padding-right: 0px;">
            <img class="img" src="assets/images/errorpageimage.png"
                style="position: relative;top:0px;width: 320px;height: 320px;">
        </div>
        <div id="genericerror" class="col-8" style="text-align: left;margin-top: 75px;padding-left: 35px;">
            <h1 style="margin-top: 20px;margin-bottom: 10px;line-height: 1.1;">Your account has been deactivated. <br>Please click <span (click)="reactivateAccount()" class="clear"
                    id="btnreactivation">here</span> to reactivate yourself.<br>Please call 1-866-842-3278 for
                assistance.
            </h1>

        </div>
    </div>
</div>

 <ng-template #reactivate let-modal>
    <div class="container-fluid nopadding">
        <div  style="display: flex;justify-content: space-between;min-height: 16.43px;padding: 15px;border-bottom: 1px solid #e5e5e5;">
            <h4 class="modal-title" id="myModalLabel">Activation</h4>
            <button type="button" class="close" (click)="modal.dismiss()" aria-label="Close" style="border: none;background: #fff;"><span
                aria-hidden="true">×</span></button>
        </div>
        <div class="container-fluid" style="padding: 15px 30px !important;">
            <div class="row">
                <div class="col-4 text-left">
                    Optum ID
                </div>
                <div class="col-8 text-left" id="optumid">{{optID}}</div>
            </div>
            <div class="row">
                <div class="col-12"><br></div>
            </div>
            <div class="row" *ngIf="prescrole">
                <div class="col-4 text-left">
                    Primary NPI
                </div>
                <div class="col-8 text-left" id="primarynpi">{{npi}}</div>
            </div>
            <div class="row" *ngIf="!prescrole">
                <div class="col-4 text-left">
                    Pharmacy Name\NPI
                </div>
                <div class="col-8 text-left" id="primarynpi">{{pharmacyName}}</div>
            </div>

        </div>
        <div style="padding: 15px;text-align: right;border-top: 1px solid #e5e5e5;" *ngIf="prescrole">
            <!-- <button type="button" class="btn btn-light" style="font-size: 14px;border-radius: 4px;color: #333;border-color: #ccc;" (click)="changeNPI()">Change
                NPI</button> -->
            <button type="button" class="btn btn-primary" style="font-size: 14px;border-radius: 4px;margin-left: 10px;" data-dismiss="modal"
                (click)="continueNPI()">Continue</button>
        </div>
        <div style="padding: 15px;text-align: right;border-top: 1px solid #e5e5e5;" *ngIf="!prescrole">
            <button type="button" class="btn btn-primary" style="font-size: 14px;border-radius: 4px;margin-left: 10px;" data-dismiss="modal"
                (click)="continueNPI()">Continue\Continue Pharmacy</button>
        </div>

    </div>

</ng-template>


    
