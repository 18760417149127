import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BOOLEAN_FLAG, VIEWALL_SUBMITTEDPA_STATUS_LABELS } from 'src/app/constants/appconstants';
import { Drugsearchwebrequest, MemberSearchItem } from 'src/app/modals/drugSearch/drugsearchwebrequest';
import { Drugsearchwebresponse, MemberNtfyResponse } from 'src/app/modals/drugSearch/drugsearchwebresponse';
import { DrugSearchWebResponse, SavedPAResponse, SavedPAResumeDetails } from 'src/app/modals/epa/priorauthresponse';
import { MemberSearchV5Item } from 'src/app/modals/membersearch/membersearchwebresponse';
import { memberNotificationsRequest } from 'src/app/modals/pahistory/notificationhistorywebrequest';
import { MemberPAHistorywebrequest } from 'src/app/modals/pahistory/pahistorywebrequest';
import { PAHistorywebresponse, PAResponseHistory } from 'src/app/modals/pahistory/pahistorywebresponse';
import { NO_ERRORS_SCHEMA } from '@angular/core';
import { getRecentPrescriberDetailsRequest } from 'src/app/modals/viewallnotificationscalls/getRecentPrescriberDetailsRequest';
import { viewAllNotificationsRequest } from 'src/app/modals/viewallnotificationscalls/viewAllNotificationsRequest';
import { NotificationResponse, viewAllNotificationsResponse } from 'src/app/modals/viewallnotificationscalls/viewAllNotificationsResponse';
import { DatetoDisplayString, StandardDateToServertime, TimeStampToStandardDate } from 'src/app/pipes/dateconversion/dateconversion.pipe';
import { EpaprocessorService } from 'src/app/services/epa/epaprocessor.service';
import { MemberDetailService } from 'src/app/services/memberdetail/memberdetail.service';
import { PahistoryService } from 'src/app/services/pahistory/pahistory.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { NotificationsService } from 'src/app/services/viewAllNotificationsServices/notifications.service';
import { MembersearchService } from 'src/app/services/membersearch/membersearch.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'memberviewall',
  templateUrl: './memberviewall.component.html',
  styleUrls: ['./memberviewall.component.css']
})
export class MemberViewallComponent implements OnInit {
  resumeDetails?: SavedPAResumeDetails;
  memberSearchResult!: MemberSearchV5Item | MemberSearchItem | undefined;
  blnLoading: boolean | undefined;
  sourceData!: PAResponseHistory[];
  notificationlist: string[] = [];
  private _fromDate: Date;
  public get fromDate(): Date {
    return this._fromDate;
  }
  @Input()
  public set fromDate(v: Date) {
    this._fromDate = v;
  }


  private _toDate: Date;
  public get toDate(): Date {
    return this._toDate;
  }
  @Input()
  public set toDate(v: Date) {
    this._toDate = v;
  }

  
  @Input()
  maxResults!: number;
  constructor(private profileService: ProfileService, private memberSearchHelper: MembersearchService,private pahistory:PahistoryService, private standardDateToServertime:StandardDateToServertime, private notificaionsService:MemberDetailService, private dateToDisplayStringConversion: DatetoDisplayString, 
    private router: Router, private epaServiceHelper: EpaprocessorService,private timestampTostandardDate: TimeStampToStandardDate) {
    let currentDate: Date = new Date();
    //Represent 12AM of next date. Meaning, It considers complete 24h of Today.
    currentDate.setDate(currentDate.getDate() + 1);
    this._toDate = currentDate;

    //Resenting to represent today's date.
    currentDate = new Date();
    //This history will be defaulted to last 30 days from now if no dates are supplied.
    currentDate.setDate(currentDate.getDate() - 30);
    this._fromDate = currentDate;


  }
  ntfsID!: String;
  dltNtfyType: any = 'delete';
  userRole!: string;
  userID!: string;
  useruuid!: string;
  quesArry: any;
  questionArry: any;
  answArry: any;
  attachmentArry: any;
  submittedPaHistory:PAResponseHistory[] =[];
  numberofnotifications!: any;
  numberofnewnotifications: number = 0;
  allNotifications !: any;
  @Input() tabledata!: any;
  @Input() memberId!: string;
  
  @Output() newNotifications = new EventEmitter<any>();

  ngOnInit(): void {
    this.memberSearchResult = this.memberSearchHelper.getSelectedMember();
    this.getNotifications();
  }

  getNotifications() {
    debugger;
    let webrequest: MemberPAHistorywebrequest = new MemberPAHistorywebrequest();
      webrequest.fromDate = this.dateToDisplayStringConversion.transform(this.fromDate);
      webrequest.toDate = this.dateToDisplayStringConversion.transform(this.toDate);
      webrequest.memberDOB = this.memberSearchResult?.dateOfBirth.indexOf('/')!=-1?this.standardDateToServertime.transform(""+this.memberSearchResult?.dateOfBirth):this.memberSearchResult?.dateOfBirth;
      webrequest.memberFirstName = this.memberSearchResult?.firstName;
      webrequest.memberLastName = this.memberSearchResult?.lastName;
      webrequest.memberId = this.memberSearchResult?.memberId;
      webrequest.instanceId = this.memberSearchResult?.instanceId;
      webrequest.carrierId = this.memberSearchResult?.carrierId;
      webrequest.groupId = this.memberSearchResult?.groupId;
      webrequest.accountId = this.memberSearchResult?.accountId;
      webrequest.resultsFromPAS = true;
      webrequest.providerId = this.profileService.loginuser.prescriber?.uuid;
      if(this.profileService.loginuser.prescriber?.roleTypeId==20){
        webrequest.adminPresbrID= this.profileService.loginuser.prescriber?.presbrId; 
  }

    this.sourceData = [];
    //this.resetPaginationDefaults();
    this.pahistory.getMemberSubmittedPAHistory(webrequest).then((response: PAHistorywebresponse) => {
      this.blnLoading = false;
      if (this.memberId != undefined) {
        this.sourceData = response.paResponseHistory.filter((item) => item.memberId == this.memberId);
      } else {
        this.sourceData = response.paResponseHistory;
      }
      for(let i=0;i<this.sourceData.length;i++) {
        debugger;
        let request: memberNotificationsRequest = new memberNotificationsRequest();
        request.memberId = this.sourceData[i].memberId;
        request.presbrId = this.profileService.loginuser?.prescriber?.presbrId;
        request.paStatus = this.sourceData[i].paStatus;
        request.paCaseId = this.sourceData[i].caseId;
        request.drugSearchRequired = "Y";
        request.notificationRequired = "Y";
        request.uuid=this.profileService.loginuser?.prescriber?.uuid;
        request.npi = this.profileService.loginuser?.prescriber?.npi;
        let drugrequest: Drugsearchwebrequest = new Drugsearchwebrequest();
        request.drugSearchRequest = drugrequest;
        drugrequest.ndc = this.sourceData[i].productId;
        drugrequest.lob=''+this.memberSearchResult?.lob;

        drugrequest.instanceId =''+ this.memberSearchResult?.instanceId;
        this.notificaionsService.getMemberNotification(request).then((data: MemberNtfyResponse) => {
          debugger;
          //console.log("Selected ITem:"+response.response.drugSearchItems[0].detailedDrugName);
          //console.log(response);
          //console.log(this.sourceData[i]);
          this.sourceData[i].drugName =''+data.drugSearchResponse.response.drugSearchItems[0].detailedDrugName;
          this.sourceData[i].firstName=''+this.memberSearchResult?.firstName?.toLowerCase();
          this.sourceData[i].lastName=''+this.memberSearchResult?.lastName?.toLowerCase();
          this.sourceData[i].notificationId = data.memberNtfyResponse.notificationId;
          this.sourceData[i].readInd =''+data.memberNtfyResponse.readInd;
          this.sourceData[i].convertedTimestamp = this.timestampTostandardDate.transform(this.sourceData[i].date.toString());
          this.sourceData[i].dateSubmitted = this.timestampTostandardDate.transform(this.sourceData[i].date.toString());
         response.paResponseHistory[i].presbrLstName = response.paResponseHistory[i].prescriberName;
          if(this.sourceData[i].paStatus=='Resolved-Denied'){
            this.sourceData[i].paStatus='Denied';
          }
        }
        );
        
      //this.allNotifications = this.sourceData.length;
      //this.loadHistory();
      }
      
      this.allNotifications = response.paResponseHistory;
      this.getNumberOfNewNotificaions(this.allNotifications);  
      this.numberofnotifications = this.sourceData.length;
      
    });
    console.log(this.sourceData);
  }
  

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return year + '-' + month + '-' + day;

  }

  getDate180DaysBack(): Date {
    const date = new Date();
    date.setDate(date.getDate() - 180);
    return date;
  }
  getTodate(): Date {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    return date;

  }

  changeTimeformat(time: string) {
    var year = time.slice(0, 4);
    var month = time.slice(4, 7);
    var day = time.slice(7, 10);
    return month + '/' + day + '/' + year;
  }

  onViewAllNotificationsClick() {
    this.router.navigateByUrl('/searchMedicationClaims/MemberDetailsViewAll');
  }
  onDelete($event: String) {
    if ($event == 'true')
      this.numberofnotifications = parseInt(this.numberofnotifications) - 1;
    this.allNotifications = this.sourceData.length;
    this.getNumberOfNewNotificaions(this.allNotifications);
  }

  onViewPAClick($event: PAResponseHistory) {
  
    var paResponseHistory = new PAResponseHistory;
    var paResponseHistory = $event;
    if ($event.caseId != undefined) {

      this.epaServiceHelper.resumeCellClickPA("" + $event.caseId).then((response: SavedPAResponse) => {
        console.log(response);

        if (response.paInitiationResponseWeb.body == undefined) {

          this.quesArry = [];
        }
        else {
          this.quesArry = response.paInitiationResponseWeb.body.parequest.request.solicitedModel.questionSet.question;
        }
        //console.log("data",response);
        if (response.paResumeDetails != undefined) {

          this.attachmentArry = response.paResumeDetails.paAttachment;
          //console.log("attachments",this.attachmentArry);
        }
        else {
          this.attachmentArry = [];
        }

        sessionStorage.setItem("pAdetails", JSON.stringify(paResponseHistory));
        //console.log("quesArry",this.quesArry);
        sessionStorage.setItem("Array", JSON.stringify(this.quesArry));
        //sessionStorage.setItem("Modal",JSON.stringify(response.paInitiationResponseWeb.body.parequest.request.solicitedModel.questionSet));
        sessionStorage.setItem("Attachements", JSON.stringify(this.attachmentArry));
        this.router.navigateByUrl("/PriorAuthorizationDetails");

      });
    }
    else {
      sessionStorage.setItem("pAdetails", JSON.stringify(paResponseHistory));
      this.router.navigateByUrl("/PriorAuthorizationDetails");
    }
  }

  getNumberOfNewNotificaions(notficationArray: any) {

    this.numberofnewnotifications = 0;
    for (let i = 0; i < notficationArray.length; i++) {
      if (notficationArray[i].readInd == "N") {
        this.numberofnewnotifications++;
      }
    } debugger;
    this.newNotifications.emit(this.numberofnewnotifications);
  }

  //end brace  
}
