import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { COVERAGE_MESSAGE_TYPE, APPCONSTANTS, USER_ROLE, PERSONGENDER_DESC, CLAIM_STATUS_TYPE, PRESCRIBER_QUALIFIER } from 'src/app/constants/appconstants';
import { BANNER_TYPE, ClaimSummaryDetails } from 'src/app/modals/claimsummary/claimsummary';
import { DurSegment } from 'src/app/modals/drugSearch/Trailadjudicationwebrequest';
import { LocalMessages, ScenarioClaim, Trailadjudicationwebresponse, alternativePharmacie } from 'src/app/modals/drugSearch/Trailadjudicationwebresponse';
import { SelectedMedicine } from 'src/app/modals/drugSearch/drugsearchwebresponse';
import { SteponePharmacyDetailsRequest } from 'src/app/modals/registration/SteponePharmacyDetailsRequest';
import { PharmacyDetailItem, SteponePharmacyDetailsResponse } from 'src/app/modals/registration/SteponePharmacyDetailsResponse';
import { ServertimeToStandardDate, TimeStampToStandardDate } from 'src/app/pipes/dateconversion/dateconversion.pipe';
import { CalculateQuantityService, QtyCalculated } from 'src/app/services/Quantity/calculate-quantity.service';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { registrationService } from 'src/app/services/registrationSteps/registration.service';

@Component({
  selector: 'app-claimsummary',
  templateUrl: './claimsummary.component.html',
  styleUrls: ['./claimsummary.component.css']
})
export class ClaimsummaryComponent implements OnInit{
  
  claimsummaryDetailsrequest!: ClaimSummaryDetails;
  updatingDOS = 0;
  claimResponse!: SelectedMedicine;
  // quantityService: any;
  dosValue!: string;
  validAlternatePharmacies!:alternativePharmacie[];
  userrole!:USER_ROLE;
  

  private _scenarioDrugIndex = 0;
  public get scenarioDrugIndex() {
    return this._scenarioDrugIndex;
  }
  public set scenarioDrugIndex(value) {
    // this._scenarioDrugIndex = value;
    // this.loadClaimSummary();
    // call the print function
  }

  altpharmacyItem!:PharmacyDetailItem;

  public setpharmacyId(value:any) {
    var pharmacy = new SteponePharmacyDetailsRequest();
            pharmacy.pharmacyIdQualifier = PRESCRIBER_QUALIFIER.NPI;
            pharmacy.instanceId = this.claimResponse.trailadjudicationwebresponse.response.instanceId;
            pharmacy.pharmacyId = this.claimResponse.trailadjudicationwebresponse.prefAlternativesWebResponse.alternatePharmacies[this.claimResponse.trailadjudicationwebresponse.prefAlternativesWebResponse.alternatePharmacies.findIndex(item => item.serviceProviderId==value)].serviceProviderId;
            this.phamracyDetail.postPharmacyDetails(pharmacy).then((data: SteponePharmacyDetailsResponse) => {
              debugger;
              if (data.status.statusCode == APPCONSTANTS.API_RESPONSE_SUCCESS_CODE) {
                this.altpharmacyItem = data.pharmacyDetailItems[0];
                             }
              this.phamcyIndex=this.claimResponse.trailadjudicationwebresponse.prefAlternativesWebResponse.alternatePharmacies.findIndex(item => item.serviceProviderId==value)+1;
            });
  }

  private _phamcyIndex = 0;
  public get phamcyIndex() {
    return this._phamcyIndex;
  }
  public set phamcyIndex(value) {
    this._phamcyIndex = value;
    debugger;
    this.loadClaimSummary();
  }

  constructor(private router: Router, private logger: LoggerService,private profileService: ProfileService,private servertimetostandarddate: ServertimeToStandardDate,
    private timestampTostandardDate: TimeStampToStandardDate, private quantityService: CalculateQuantityService, private phamracyDetail:registrationService ){

  }
  ngOnInit(): void {
    this.userrole = this.profileService.USER_ROLE;
    sessionStorage.setItem("lastPageBeforeReload", this.router.url);
    if(this.profileService.USER_ROLE==12){
      sessionStorage.setItem("blnSelectPrescriberInCheckCoverage","true");
    }
    // sessionStorage.setItem("lastPageBeforeReload", "/home");
    this.loadClaimSummary();
  }

  loadClaimSummary(): void {
    window.scroll(0, 0);
    debugger;
    if(sessionStorage.getItem('selectedMedicine')!=undefined){
      this.claimResponse = JSON.parse("" + sessionStorage.getItem('selectedMedicine'));
    }
    this.getValidAlternatePharmacies(this.claimResponse);
    this.claimsummaryDetailsrequest = new ClaimSummaryDetails();
    this.claimsummaryDetailsrequest.scenariopharmacyIndex=this.phamcyIndex;
debugger;
    // We have alternate pharmacies in response
    // if(this.claimResponse!=undefined && this.claimResponse.trailadjudicationwebresponse.prefAlternativesWebResponse.alternatePharmacies.length>0){
    //   this.phamcyIndex=0;
    // }
    let scenarioClaim=this.getScenarioClaim(this.claimResponse.trailadjudicationwebresponse);

    this.updateBannerMsgByClaimType(this.claimsummaryDetailsrequest, this.claimResponse.trailadjudicationwebresponse, this.claimResponse.selectedMember.lob, scenarioClaim);
        this.setShowPricing(this.claimsummaryDetailsrequest, this.claimResponse.trailadjudicationwebresponse,scenarioClaim);
        this.mapSummaryDetailsFromSelectedMedicine(this.claimsummaryDetailsrequest, this.claimResponse,scenarioClaim);
        this.mapSummaryDetailsFromTrailData(this.claimsummaryDetailsrequest, this.claimResponse,scenarioClaim);
        this.mapMemberDataFromSelectedMedicine(this.claimsummaryDetailsrequest, this.claimResponse);
        this.mapDurRejectData(this.claimsummaryDetailsrequest,this.claimResponse);
        debugger;
  }

  getScenarioClaim(trailadjudicationwebresponse: Trailadjudicationwebresponse): ScenarioClaim {
    // if(this.phamcyIndex!=0 && this._scenarioDrugIndex!=0){
      return trailadjudicationwebresponse.prefAlternativesWebResponse.scenarioClaims
              .filter((item:ScenarioClaim)=>item.scenarioPharmacy==this._phamcyIndex)[0];
    // }

    // return trailadjudicationwebresponse.prefAlternativesWebResponse.scenarioClaims[0];
  }


  goToSelectMember() {
    //console.log("Redirecting to Select Member");
    this.logger.userAction("Redirecting to Select Member")
    if(this.profileService.USER_ROLE==12)
      this.router.navigateByUrl("/PriorAuthchecker/MemberSearch");
    else
      this.router.navigateByUrl("/PriorAuthChecker/MemberSearch");

  }

  goToSelectMedication() {
    // this.modalService.open(this.drugConfirmModal, { modalDialogClass: 'drugConfirmModal' });
    sessionStorage.setItem('selectedMedicine', JSON.stringify(this.claimResponse));
    //console.log("Redirecting to Select Medication");
    this.logger.userAction("Redirecting to Select Medication")
    if(this.profileService.USER_ROLE==12)
      this.router.navigateByUrl("/PriorAuthchecker/DrugSearch");
    else
      this.router.navigateByUrl("/PriorAuthChecker/DrugSearch");  }

  private updateBannerMsgByClaimType(request: ClaimSummaryDetails, trailResponse: Trailadjudicationwebresponse, lob: string, scenarioClaim:ScenarioClaim): void {
   debugger;
    // Trail Adjudication Error Response Handling
    //Eroro scenario 1
    if (trailResponse.status.customErrorCode == "1") {
      request.bannerType = BANNER_TYPE.RED;//"Error1Red";
      request.bannerMessage = COVERAGE_MESSAGE_TYPE.COVERAGE_ERROR;
    }

    //Eroro scenario 2
    else if ((trailResponse.status.statusCode != undefined && trailResponse.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) && trailResponse.status.respCode == APPCONSTANTS.API_RESPONSE_SUCCESS_CODE && (trailResponse.status.responseDescriptionCode != "No records found")) {
      // Claim Summary Scenarios
      // 1st Transistion Benefit
      if (scenarioClaim.responseStatusSegment.rejectInformation.length == 0 && scenarioClaim.responseStatusSegment.transactionResponseStatus != undefined && (APPCONSTANTS.RTB_TRAILRESPONSE_PAID.indexOf(scenarioClaim.responseStatusSegment.transactionResponseStatus?.toLowerCase()) != -1)) {
        //if condition will be added later trailResponse.response.transitionBenefitsMessage!=undefined
        if (trailResponse.response.transitionBenefitsMessage != undefined && trailResponse.response.transitionBenefitsMessage != "") {
          request.bannerType = BANNER_TYPE.GREEN; //"TransistionPaidGreen";
          request.bannerMessage = trailResponse.response.transitionBenefitsMessage.trim().replace(/’/g, '\'');
        } else {
          
          //2nd Medlimit message for opioid without PA 
          if (scenarioClaim.responseMessageSegment.message.trim()?.toLowerCase() == COVERAGE_MESSAGE_TYPE.OPIOID_MORPHINE?.toLowerCase()) {
            // banner color:
            //   1. noPAWithNoAlts (Green banner as paid) (When Submitted claim is not rejected)
            //   2. notCovered (Red) (When submitted claim is rejected in real claim scenario
            request.bannerType = BANNER_TYPE.GREEN; //"DisplayMedLimitBannerNoPAGreen";
            request.bannerMessage = scenarioClaim.responseMessageSegment.message+" Consider an alternative if provided.".trim().replace(/’/g, '\'');
          }

          //3nd Medlimit message for opioid with PA 
          else if (scenarioClaim.responseMessageSegment.message.trim()?.toLowerCase() == COVERAGE_MESSAGE_TYPE.OPIOID_MORPHINE?.toLowerCase() && (scenarioClaim.responseInsuranceSegment.lineOfBusinessCode?.toLowerCase() == "uhccs" || scenarioClaim.responseInsuranceSegment.lineOfBusinessCode?.toLowerCase() == "uhcmr" || scenarioClaim.responseInsuranceSegment.lineOfBusinessCode?.toLowerCase() == "uhcei" || scenarioClaim.responseInsuranceSegment.lineOfBusinessCode?.toLowerCase() == "orxcom" || scenarioClaim.responseInsuranceSegment.lineOfBusinessCode?.toLowerCase() == "orxmed" || scenarioClaim.responseInsuranceSegment.lineOfBusinessCode=="")) {
            request.bannerType = BANNER_TYPE.YELLOW; //"DisplayMedLimitBannerPAYellow";
            request.bannerMessage = scenarioClaim.responseMessageSegment.message.trim().replace(/’/g, '\'');
          }

          //4th Prenatal message for opioid    
          else if (scenarioClaim.responseMessageSegment.message.trim().replace(/’/g, '\'')?.toLowerCase() == COVERAGE_MESSAGE_TYPE.OPIOID_PRENATAL?.toLowerCase() && (scenarioClaim.responseInsuranceSegment.lineOfBusinessCode?.toLowerCase() == "uhccs" || scenarioClaim.responseInsuranceSegment.lineOfBusinessCode?.toLowerCase() == "uhcei" || scenarioClaim.responseInsuranceSegment.lineOfBusinessCode?.toLowerCase() == "orxcom" || scenarioClaim.responseInsuranceSegment.lineOfBusinessCode?.toLowerCase() == "orxmed")) {
            request.bannerType = BANNER_TYPE.YELLOW; //"DisplayPrenatalBannerYellow";
            request.bannerMessage = scenarioClaim.responseMessageSegment.message+" Consider an alternative if provided.".trim().replace(/’/g, '\'');
          }

          //5th Benzo message for opioid    
          else if (scenarioClaim.responseMessageSegment.message.trim()?.toLowerCase() == COVERAGE_MESSAGE_TYPE.OPIOID_BENZODIAZEPINE?.toLowerCase() && (scenarioClaim.responseInsuranceSegment.lineOfBusinessCode?.toLowerCase() == "uhccs" || scenarioClaim.responseInsuranceSegment.lineOfBusinessCode?.toLowerCase() == "uhcei" || scenarioClaim.responseInsuranceSegment.lineOfBusinessCode?.toLowerCase() == "uhcmr" || scenarioClaim.responseInsuranceSegment.lineOfBusinessCode?.toLowerCase() == "orxcom" || scenarioClaim.responseInsuranceSegment.lineOfBusinessCode?.toLowerCase() == "orxmed")) {
            request.bannerType = BANNER_TYPE.YELLOW; //"DisplayBenzoBannerYellow";
            request.bannerMessage = scenarioClaim.responseMessageSegment.message+" Consider an alternative if provided.".trim().replace(/’/g, '\'');
          }

          //6th Matt message for opioid    
          else if (scenarioClaim.responseMessageSegment.message.trim()?.toLowerCase() == COVERAGE_MESSAGE_TYPE.OPIOID_MAT?.toLowerCase()) {
            request.bannerType = BANNER_TYPE.YELLOW; //"DisplayMatBannerYellow";
            request.bannerMessage = scenarioClaim.responseMessageSegment.message+" Consider an alternative if provided.".trim().replace(/’/g, '\'');
          }
          // Scenario7 (Opioid Not Covered)
          else if (scenarioClaim.responseMessageSegment.message.trim()?.toLowerCase() == COVERAGE_MESSAGE_TYPE.OPIOID_NOT_COVERED1 || scenarioClaim.responseMessageSegment.message.trim()?.toLowerCase() == COVERAGE_MESSAGE_TYPE.OPIOID_NOT_COVERED2 || scenarioClaim.responseMessageSegment.message.trim()?.toLowerCase() == COVERAGE_MESSAGE_TYPE.OPIOID_NOT_COVERED3){
            request.bannerType = BANNER_TYPE.RED;//"DisplayNotCoveredAcetBannerRed";
            request.bannerMessage = scenarioClaim.responseMessageSegment.message.trim().replace(/’/g, '\'');
          } 
          //Paid claim 
          else {
            // banner color:
            // 1. noPAWithNoAlts (Green banner as paid) (When Submitted claim is not rejected)
            // 2. notCovered (Red) (When submitted claim is rejected

            request.bannerType = BANNER_TYPE.GREEN; //"DisplayNoPABannerGreen";
            request.bannerMessage = scenarioClaim.responseMessageSegment.message
            // +" Consider an alternative if provided."
            .trim().replace(/’/g, '\'');
          }
        }
      }
      //Scenario8 (acetaminophen opiod)
      else if (scenarioClaim.responseMessageSegment.message.trim()?.toLowerCase() == COVERAGE_MESSAGE_TYPE.OPIOID_ACETAMINOPHEN?.toLowerCase()) {
        request.bannerType = BANNER_TYPE.RED;//"DisplayNotCoveredAcetBannerRed";
        request.bannerMessage = scenarioClaim.responseMessageSegment.message.trim().replace(/’/g, '\'');
        //console.log("DisplayNotCoveredAcetBannerRed");
      }
      //Scenario8 Not covered with Acetaminophen Opioid
      else if(scenarioClaim.responseMessageSegment.message.trim()?.toLowerCase() == COVERAGE_MESSAGE_TYPE.OPIOID_ACETAMINOPHEN?.toLowerCase() && (scenarioClaim.responseInsuranceSegment.lineOfBusinessCode?.toLowerCase() == "uhccs" || scenarioClaim.responseInsuranceSegment.lineOfBusinessCode?.toLowerCase() == "uhcmr" || scenarioClaim.responseInsuranceSegment.lineOfBusinessCode?.toLowerCase() == "uhcei" || scenarioClaim.responseInsuranceSegment.lineOfBusinessCode?.toLowerCase() == "orxcom" || scenarioClaim.responseInsuranceSegment.lineOfBusinessCode?.toLowerCase() == "orxmed")){
        request.bannerType = BANNER_TYPE.RED;//"DisplayNotCoveredAcetBannerRed";
        request.bannerMessage = scenarioClaim.responseMessageSegment.message+" Consider an alternative if provided.".trim().replace(/’/g, '\'');
      }
      else {
        //conditions for DrugStatus 

        if (scenarioClaim.responseMessageSegment.message.trim()?.toLowerCase() == COVERAGE_MESSAGE_TYPE.PRODUCT_EXCLUDED_IN_PLAN1?.toLowerCase() || scenarioClaim.responseMessageSegment.message.trim()?.toLowerCase() == COVERAGE_MESSAGE_TYPE.PRODUCT_EXCLUDED_IN_PLAN2?.toLowerCase() || scenarioClaim.responseMessageSegment.message.trim()?.toLowerCase() == COVERAGE_MESSAGE_TYPE.PRODUCT_EXCLUDED_IN_PLAN3?.toLowerCase()) {
          request.drugStatus = 'R';
        }
        else {
          request.drugStatus = 'F';
        }
        //Scenario9 (Not covered with reject 70)

        if (scenarioClaim.responseStatusSegment.rejectInformation.map(item=>item.rejectCode).indexOf('70') != -1 && request.drugStatus?.toLowerCase() == "r") {

          request.bannerType = BANNER_TYPE.RED; //"DisplayNotCoveredR70BannerRed";
          request.bannerMessage = scenarioClaim.responseMessageSegment.message.trim().replace(/’/g, '\'');
        }
        //Scenario10 (Not covered with reject 70)

        else if (scenarioClaim.responseStatusSegment.rejectInformation.map(item=>item.rejectCode).indexOf('70') != -1 && request.drugStatus?.toLowerCase() != "r") {
          request.bannerType = BANNER_TYPE.YELLOW; //"DisplayNotCoveredR70WithPABannerYellow";
          request.bannerMessage = scenarioClaim.responseMessageSegment.message.trim().replace(/’/g, '\'');

        }

        //Scenario11 ( Reject code 50)

        else if (scenarioClaim.responseStatusSegment.rejectInformation.map(item=>item.rejectCode).indexOf('50') != -1) {
          request.bannerType = BANNER_TYPE.RED; //"DisplayNotCoveredR50BannerRed";
          request.bannerMessage = scenarioClaim.responseMessageSegment.message.trim().replace(/’/g, '\'');
        }

        //Scenario12 ( Require PA with reject code 75)

        else if (scenarioClaim.responseStatusSegment.rejectInformation.map(item=>item.rejectCode).indexOf('75') != -1 || scenarioClaim.responseStatusSegment.rejectInformation.map(item=>item.rejectCode).indexOf('608') != -1) {
          request.bannerType = BANNER_TYPE.YELLOW; //"DisplayPABannerYellow";
          request.bannerMessage = scenarioClaim.responseMessageSegment.message.trim().replace(/’/g, '\'');
        }

        //Scenario13 ( Reject 76)

        else if (scenarioClaim.responseStatusSegment.rejectInformation.map(item=>item.rejectCode).indexOf('76') != -1) {
          request.bannerType = BANNER_TYPE.RED; //"DisplayNotCoveredR76BannerRed";
          request.bannerMessage = scenarioClaim.responseMessageSegment.message.trim().replace(/’/g, '\'');
        }

        //Scenario14 ( Reject 80)

        else if (scenarioClaim.responseStatusSegment.rejectInformation.map(item=>item.rejectCode).indexOf('80') != -1) {
          request.bannerType = BANNER_TYPE.YELLOW; //"DisplayNotCoveredR80WithPABannerYellow";
          request.bannerMessage = scenarioClaim.responseMessageSegment.message.trim().replace(/’/g, '\'');
        }
        // Scenario15 ( Rest all are not covered)

        else {

          request.bannerType = BANNER_TYPE.RED; //"DisplayNotCoveredBannerRed";
          request.bannerMessage = scenarioClaim.responseMessageSegment.message.trim().replace(/’/g, '\'');
        }
      }

      //scenario 15 brandpeffered,requirePA, Initiate PA
      if (scenarioClaim.responseClaimSegment.brandPreferred == true) {
        request.brandPreferred = true;
      }
      if ((scenarioClaim.responseStatusSegment.requiresPriorAuthorization == true) || ((scenarioClaim.responseStatusSegment.rejectInformation.map(item=>item.rejectCode).indexOf('70') != -1) && (trailResponse.response.drugStatus?.toLowerCase() != 'r') && (scenarioClaim.responseMessageSegment.message.trim()?.toLowerCase() == COVERAGE_MESSAGE_TYPE.PRODUCT_NOT_COVERED_IN_PLAN))) {
        request.requiresPA = true;
        request.initiatePALnk = true;
        sessionStorage.setItem('editProvider','true');
      }
      if ((this.profileService.loginuser?.prescriber?.roleTypeId == USER_ROLE.ADVISOR.toString())) {
        request.initiatePALnk = false;
      }

    }

    //Eroro scenario 3
    else if (trailResponse.status.responseDescriptionCode != undefined) {
      request.bannerMessage = trailResponse.status.responseDescriptionCode;
      request.bannerType = BANNER_TYPE.RED; //"Error3Red";
    }

    //Eroro scenario 4
    else if (trailResponse.status.dbStatusCode == '7') {
      request.bannerType = BANNER_TYPE.RED; //"Error4Red";
      request.bannerMessage = COVERAGE_MESSAGE_TYPE.BPG_NOT_MAPPEDTO_CARRIER;
    }

    //Eroro scenario 6 

    else if (trailResponse.prefAlternativesWebResponse == undefined && trailResponse.invocationContext == undefined && trailResponse.serviceContext == undefined && trailResponse.integrationStatus == undefined) {
      request.bannerType = BANNER_TYPE.RED; //"Error6Red";
      request.bannerMessage = COVERAGE_MESSAGE_TYPE.COVERAGE_ERROR;
    }


    //scenario 5
    else {
      request.bannerType = BANNER_TYPE.RED; //"Error5Red";
      request.bannerMessage = trailResponse.status.statusDesc;
    }
    // End Error Response Handling
  }

  private setShowPricing(request: ClaimSummaryDetails, trailResponse: Trailadjudicationwebresponse, scenarioClaim:ScenarioClaim): void {
    //scenario 16 show pricing
    if (trailResponse.prefAlternativesWebResponse != undefined && scenarioClaim.responsePricingSegment.showPricing == true) {

      if (parseInt(scenarioClaim.responsePricingSegment.patientPayAmount) > 0) {
        request.PatientPayAmount = "$" + scenarioClaim.responsePricingSegment.patientPayAmount;
      }
      else if(parseFloat(scenarioClaim.responsePricingSegment.patientPayAmount) == 0.00){
        request.PatientPayAmount = "$ 0.00";
      }
      else {
        request.PatientPayAmount = "N/A";
      }
    }
    else {
      request.PatientPayAmount = "N/A";
    }
    // //console.log("ppa", request.PatientPayAmount);
debugger;
    if (trailResponse.prefAlternativesWebResponse != undefined && scenarioClaim.responsePricingSegment.showPricing == true) {
      if (parseInt(scenarioClaim.responsePricingSegment.planPayAmount) > 0) {
        request.ProviderTotalAmountDue = "$" + scenarioClaim.responsePricingSegment.planPayAmount;
      }
      else if(parseFloat(scenarioClaim.responsePricingSegment.planPayAmount) == 0.00){
        request.ProviderTotalAmountDue = "$ 0.00";
      }
      else {
        request.ProviderTotalAmountDue = "N/A";
      }
    }
    else {
      request.ProviderTotalAmountDue = "N/A";
    }

    if (trailResponse.prefAlternativesWebResponse != undefined && scenarioClaim.responsePricingSegment.showPricing == true) {

      if (parseInt(scenarioClaim.responsePricingSegment.approvedProfessionalFee) > 0) {
        request.ApprovedProfessionalFee = "$" + scenarioClaim.responsePricingSegment.approvedProfessionalFee;
      }
      else if(parseFloat(scenarioClaim.responsePricingSegment.approvedProfessionalFee) == 0.00){
        request.ApprovedProfessionalFee = "$ 0.00";
      }
      else {
        request.ApprovedProfessionalFee = "N/A";
      }
    }
    else {
      request.ApprovedProfessionalFee = "N/A";
    }
    if (trailResponse.prefAlternativesWebResponse != undefined && scenarioClaim.responsePricingSegment.showPricing == true) {

      if (parseInt(scenarioClaim.responsePricingSegment.approvedClaimIgredientCost) > 0) {
        request.ApprovedClaimIngredientCost = "$" + scenarioClaim.responsePricingSegment.approvedClaimIgredientCost;
      }
      else if(parseFloat(scenarioClaim.responsePricingSegment.approvedClaimIgredientCost) == 0.00){
        request.ApprovedClaimIngredientCost = "$ 0.00";
      }
      else {
        request.ApprovedClaimIngredientCost = "N/A";
      }
    }
    else {
      request.ApprovedClaimIngredientCost = "N/A";
    }
    //console.log("ptad", request.ProviderTotalAmountDue);
    //console.log("amount", request.PatientPayAmount);
    //console.log("anotheramount", request.ProviderTotalAmountDue);
  }

  private mapSummaryDetailsFromTrailData(request: ClaimSummaryDetails, item: SelectedMedicine, scenarioClaim:ScenarioClaim): void {
    debugger;
    // Setting other fields for claim summary page
    //request.DateofService = this.timestampTostandardDate.transform(item.trailadjudicationwebrequest.dateOfService); 
    request.DateofService = this.servertimetostandarddate.transform(item.trailadjudicationwebrequest.dateOfService); 
    request.DaysSupply = item.trailadjudicationwebrequest.daysSupply; //item.Days;
    
    request.PlaceofService = item.trailadjudicationwebrequest.placeOfService; //item.placeOfservice;
    if(item.trailadjudicationwebresponse.response.diagnosisCode!=undefined && item.trailadjudicationwebresponse.response.diagnosisCodeDescription!=undefined)
      request.DiagnosisCode =  item.trailadjudicationwebresponse.response.diagnosisCode+" "+item.trailadjudicationwebresponse.response.diagnosisCodeDescription; 
    else
      request.DiagnosisCode = item.trailadjudicationwebrequest.diagnosisCode; //item.DiagnosisCode;
    
      debugger;
    if(item.trailadjudicationwebrequest.productServiceId == scenarioClaim.responseClaimSegment.productOrServiceId){
      //Same drug returned from trail response. Submitted drug has not been replaced with some other drug during real time benefit check.
      request.QuantityDesc = item.quantytyDesc;
      request.QuantityEntered = item.Quantity;
      request.MedicationName = item.trailadjudicationwebrequest.drugName; //item.MedStrengthName;
      request.ProductID = item.trailadjudicationwebrequest.productServiceId; //item.MedStrength;
      request.QuantityCalculated = Number.parseInt(item.quantytyCalculate);     
    } else {
      //Submitted drug is replace by some other drug during real time benefit check.
      request.MedicationName = scenarioClaim.responseClaimSegment.productLabelName; 
      request.ProductID = scenarioClaim.responseClaimSegment.productOrServiceId; 
      request.QuantityEntered = item.Quantity;
      debugger;
      let drugDescriptionWithDispensedQty:QtyCalculated = this.quantityService.quantityCalculate(scenarioClaim.responseClaimSegment.quantityDispensed, item.drugSearchItem);
      request.QuantityCalculated = parseFloat(drugDescriptionWithDispensedQty.qtycaliculated);   
      request.QuantityDesc = drugDescriptionWithDispensedQty.qtydesc;
    }
    
    debugger;
    request.Name = item.trailadjudicationwebrequest.firstName + " " + item.trailadjudicationwebrequest.lastName; //item.selectedMember.firstName + " " + item.selectedMember.lastName;
    request.MemberID = item.trailadjudicationwebrequest.cardholderId; //item.selectedMember.memberId;
    let formattedDate = this.servertimetostandarddate.transform(item.trailadjudicationwebrequest.dateOfBirth); //this.servertimetostandarddate.transform(item.selectedMember.dateOfBirth);
    request.DateOfBirth = formattedDate;
    this.dosValue = request.DateofService;
    if(item.selectedMember.isMedD!=undefined){
      request.isMedD=item.selectedMember.isMedD;
    }else{
      request.isMedD=false;
    }

    if(scenarioClaim.responseInsuranceSegment.medDBeginPhase!=undefined){
      request.beginPhase=scenarioClaim.responseInsuranceSegment.medDBeginPhase==""?"Not Available":scenarioClaim.responseInsuranceSegment.medDBeginPhase;
    }else{
      request.beginPhase="Not Available";
    }

    if(scenarioClaim.responseInsuranceSegment.medDEndPhase!=undefined){
      request.endPhase=scenarioClaim.responseInsuranceSegment.medDEndPhase==""?"Not Available":scenarioClaim.responseInsuranceSegment.medDEndPhase;
    }else{
      request.endPhase="Not Available";
    }
  }

  private mapSummaryDetailsFromSelectedMedicine(request: ClaimSummaryDetails, item: SelectedMedicine, scenarioClaim: ScenarioClaim): void {
    if (scenarioClaim.responseMessageSegment != undefined && scenarioClaim.responseMessageSegment.localMessageInformation.length > 0) {
      request.localMessages = scenarioClaim.responseMessageSegment.localMessageInformation;
    }
    if (item.trailadjudicationwebresponse.response != undefined) {
      request.BinPCNGroup = item.trailadjudicationwebresponse.response.bin + "/" + item.trailadjudicationwebresponse.response.pcn + "/" + item.trailadjudicationwebresponse.response.groupId;
    } else {
      request.BinPCNGroup = "N/A"
    }

    if(item.pharmacySearchItem!=undefined && scenarioClaim.responseHeaderSegment.serviceProviderId==item.pharmacySearchItem?.npi){
      request.ContractedNetworkProvider = ""+item.pharmacySearchItem?.pharmacyName;
      request.ContractedNetworkProviderAddress = item.pharmacySearchItem?.physicalLocation.address1 + " " + item.pharmacySearchItem?.physicalLocation.city + ", " + item.pharmacySearchItem?.physicalLocation.state + " " + item.pharmacySearchItem?.physicalLocation.zip;
    }
    else if(item.pharmacyDetailItem!=undefined && scenarioClaim.responseHeaderSegment.serviceProviderId== this.profileService?.loginuser?.prescriber?.npi){
      request.ContractedNetworkProvider = ""+item.pharmacyDetailItem?.pharmacyName;
      request.ContractedNetworkProviderAddress = item.pharmacyDetailItem?.storeAddress.address1 + " " + item.pharmacyDetailItem?.storeAddress.city + ", " + item.pharmacyDetailItem?.storeAddress.state + " " + item.pharmacyDetailItem?.storeAddress.zip1;
    }
   else{
      this.claimsummaryDetailsrequest.ContractedNetworkProvider = this.altpharmacyItem.pharmacyName;
      this.claimsummaryDetailsrequest.ContractedNetworkProviderAddress = this.altpharmacyItem.storeAddress.address1 + " " + this.altpharmacyItem.storeAddress.city + ", " + this.altpharmacyItem.storeAddress.state + " " + this.altpharmacyItem.storeAddress.zip1;

    }
    request.claimGroupId = item.clmgroupId;
    request.dateOfLastTrail = item.dateOfLastTrial;
  }

  private mapMemberDataFromSelectedMedicine(request: ClaimSummaryDetails, item: SelectedMedicine): void {
    if (item.memberDetailResponse != undefined && item.memberDetailResponse.memberItems[0] != undefined) {
      request.Phone = (item.memberDetailResponse.memberItems[0].contact.phoneNumber != undefined && item.memberDetailResponse.memberItems[0].contact.phoneNumber.length > 0) ? item.memberDetailResponse.memberItems[0].contact.phoneNumber : "N/A";
      if (item.memberDetailResponse.memberItems[0].demographics.gender != undefined && item.memberDetailResponse.memberItems[0].demographics.gender.length > 0) {
        const gender: string | undefined = PERSONGENDER_DESC.get(item.memberDetailResponse.memberItems[0].demographics.gender.toUpperCase());
        if (gender != undefined)
          request.Gender = gender;
        else
          request.Gender = "---";
      }
     
       if(item.memberDetailResponse.memberItems[0].contact.address2!="" && item.memberDetailResponse.memberItems[0].contact.address3!=""){
        debugger;
        request.Address = item.memberDetailResponse.memberItems[0].contact.address1 + ", "
        + item.memberDetailResponse.memberItems[0].contact.address2 + ", "
        + item.memberDetailResponse.memberItems[0].contact.address3 + ", "
        + item.memberDetailResponse.memberItems[0].contact.city + ", "
        + item.memberDetailResponse.memberItems[0].contact.state + ", "
        + item.memberDetailResponse.memberItems[0].contact.zip;
       }
       else if(item.memberDetailResponse.memberItems[0].contact.address2=="" && item.memberDetailResponse.memberItems[0].contact.address3!=""){
        request.Address = item.memberDetailResponse.memberItems[0].contact.address1 + ", "
        + item.memberDetailResponse.memberItems[0].contact.address3 + ", "
        + item.memberDetailResponse.memberItems[0].contact.city + ", "
        + item.memberDetailResponse.memberItems[0].contact.state + ", "
        + item.memberDetailResponse.memberItems[0].contact.zip;
      }
      else if(item.memberDetailResponse.memberItems[0].contact.address3=="" && item.memberDetailResponse.memberItems[0].contact.address2!=""){
        request.Address = item.memberDetailResponse.memberItems[0].contact.address1 + ", "
        + item.memberDetailResponse.memberItems[0].contact.address2 + ", "
        + item.memberDetailResponse.memberItems[0].contact.city + ", "
        + item.memberDetailResponse.memberItems[0].contact.state + ", "
        + item.memberDetailResponse.memberItems[0].contact.zip;
      }
       else{
        debugger;
        request.Address = item.memberDetailResponse.memberItems[0].contact.address1 + ", "
        + item.memberDetailResponse.memberItems[0].contact.city + ", "
        + item.memberDetailResponse.memberItems[0].contact.state + ", "
        + item.memberDetailResponse.memberItems[0].contact.zip;
       }
    } else {
      if (item.selectedMember != undefined) {
        if (item.selectedMember.gender != undefined && item.selectedMember.gender.length > 0) {
          const gender: string | undefined = PERSONGENDER_DESC.get(item.selectedMember.gender.toUpperCase());
          if (gender != undefined)
            request.Gender = gender;
          else
            request.Gender = "---";
        } else {
          request.Gender = "---";
        }
       
        if(item.selectedMember.address.address2!="" && item.selectedMember.address.address3!=""){
         debugger;
          request.Address = item.selectedMember.address.address1 + ", "
          + item.selectedMember.address.address2 + " , "
          + item.selectedMember.address.address3 + " , "
          + item.selectedMember.address.city + " ,"
          + item.selectedMember.address.state + ", "
          + item.selectedMember.address.zip1;
        }
        else if(item.selectedMember.address.address2=="" && item.selectedMember.address.address3!=""){
          request.Address = item.selectedMember.address.address1 + ", "
          + item.selectedMember.address.address3 + " , "
          + item.selectedMember.address.city + " ,"
          + item.selectedMember.address.state + ", "
          + item.selectedMember.address.zip1;
        }
        else if(item.selectedMember.address.address3=="" && item.selectedMember.address.address2!=""){
          request.Address = item.selectedMember.address.address1 + ", "
          + item.selectedMember.address.address2 + " , "
          + item.selectedMember.address.city + " ,"
          + item.selectedMember.address.state + ", "
          + item.selectedMember.address.zip1;
        }
        else{
          debugger;
          request.Address = item.selectedMember.address.address1 + ", "
          + item.selectedMember.address.city + " ,"
          + item.selectedMember.address.state + ", "
          + item.selectedMember.address.zip1;
        }
      } else {
        request.Gender = "---";
        request.Address = "---";
      }
    }
  }

  private showUnFilledSoftRejectsInTrailResponse(request:ClaimSummaryDetails, durSegments:Array<DurSegment>, blnDurRejectCodeExists:boolean=true):void{
    //request.durReject.isDurCodesValid=false;
    request.durReject.isDurReject = false;
    request.durReject.durMessages = [];
    request.durReject.durAllRejects=[];
    request.durReject.durSoftRejects=[];
    request.durReject.durInvalidSoftRejects=[];
    
    if(durSegments!=undefined && Array.isArray(durSegments) && durSegments.length>0){
      request.localMessages.reverse();
      let blnAllSoftRejects:boolean=true;
      //request.durReject.durMessages.push(request.bannerMessage);

      request.localMessages.reverse();

      for(let durIndex:number=0;durIndex<durSegments.length;durIndex++){
        const durItem:DurSegment = durSegments[durIndex];
        let blnSoftRejectIdentified:boolean = false;

        request.durReject.durAllRejects.push(JSON.parse(JSON.stringify(durItem)));
        
        if(durItem.response == APPCONSTANTS.RTB_DUR_RESPONSE_NONSOFTREJECT){
          blnAllSoftRejects=false;
          continue;
        } else {
          request.durReject.isDurReject=true;
        }

        if(durItem.response == APPCONSTANTS.RTB_DUR_RESPONSE_SOFTREJECT){

          if((durItem.reasonSrvcCd!=undefined && durItem.reasonSrvcCd.length>0 && durItem.reasonSrvcCd!=APPCONSTANTS.RTB_DUR_REASONSRVCCD_NONSOFTREJECT)
            && request.claimStsTypId<=CLAIM_STATUS_TYPE.RE_EXECUTE && blnDurRejectCodeExists
            && ((durItem.prfsnlSrvcCd==undefined || durItem.prfsnlSrvcCd.length==0) || (durItem.rsltSrvcCd==undefined || durItem.rsltSrvcCd.length==0)))
            {
              blnSoftRejectIdentified=true;          
              request.durReject.durSoftRejects.push(JSON.parse(JSON.stringify(durItem)));
            } else {
              request.durReject.durInvalidSoftRejects.push(JSON.parse(JSON.stringify(durItem)));
              if(request.claimStsTypId>CLAIM_STATUS_TYPE.RE_EXECUTE || !blnDurRejectCodeExists){
                if(request.durReject.durMessages.length==1 && durItem.durMessage!=undefined 
                  && durItem.durMessage.length>0 && request.durReject.durMessages.indexOf(durItem.durMessage)==-1)
                  request.durReject.durMessages.push(durItem.durMessage);
              }
            }
          } 
          else 
          {
            if(request.durReject.durMessages.length==1 && durItem.durMessage!=undefined 
            && durItem.durMessage.length>0 && request.durReject.durMessages.indexOf(durItem.durMessage)==-1)
              request.durReject.durMessages.push(durItem.durMessage);
          
            if(durItem.prfsnlSrvcCd!=undefined && durItem.prfsnlSrvcCd.length!=0 && durItem.rsltSrvcCd!=undefined && durItem.rsltSrvcCd.length!=0){
              request.durReject.durInvalidSoftRejects.push(JSON.parse(JSON.stringify(durItem)));  
            } else {
              blnAllSoftRejects=false;
            }
          }
        
          if(durItem.additionalText!=undefined && durItem.additionalText.length>0 && request.localMessages.find((msg:LocalMessages)=>msg.localMessageCodeText==durItem.additionalText)==undefined)
            request.localMessages.push({localMessageCodeText:durItem.additionalText,additionalField:[],localMessageCode:"",localMessageSeq:""});
          if(durItem.freeText!=undefined && durItem.freeText.length>0 && request.localMessages.find((msg:LocalMessages)=>msg.localMessageCodeText==durItem.freeText)==undefined)
            request.localMessages.push({localMessageCodeText:durItem.freeText,additionalField:[],localMessageCode:"",localMessageSeq:""});
      }
      // if(request.durReject.durSoftRejects.length==0){
      //   request.durReject.isDurCodesValid=true;
      // }

      request.localMessages.reverse();
      if(blnDurRejectCodeExists){
        if(request.bannerType == BANNER_TYPE.RED && blnAllSoftRejects){
          request.bannerType = BANNER_TYPE.YELLOW;
        }
      }
    }
  }
  
  private accumulateDurSegmentsIntoEveryTrailResponse(medicine: SelectedMedicine, durSourceSegment:DurSegment[]=[]):void{
    /*******************DUR Handling***************** */
    if(Array.isArray(medicine.trailadjudicationwebresponse.response.rejectCode) && medicine.trailadjudicationwebresponse.response.rejectCode.length>0){
        if(medicine.trailadjudicationwebresponse.response.rejectCode.indexOf(APPCONSTANTS.RTB_DUR_REJECT_CODE)!=-1){
          //Trail claim is rejected with 88 reject code. Taking latest DUR segments received from trail response.
          durSourceSegment = medicine.trailadjudicationwebresponse.response.durSegment; 
        } else {
          //Trail claim is rejected during recent trail with some other reason (Not reject code 88). 
          //Nullifying any dur segments available in the trail response.
          durSourceSegment =[];
        }
    }
    medicine.trailadjudicationwebresponse.response.durSegment = durSourceSegment;
    /*******************DUR Handling***************** */
  }

  mapDurRejectData(request: ClaimSummaryDetails, medicine: SelectedMedicine): void {

    if(request!=undefined){

      request.claimStsTypId = (request.claimStsTypId==undefined)?CLAIM_STATUS_TYPE.UN_SAVED:request.claimStsTypId;
      
      switch(request.claimStsTypId){
        case CLAIM_STATUS_TYPE.UN_SAVED:
          if(medicine.trailadjudicationwebresponse!=undefined && medicine.trailadjudicationwebresponse.status!=undefined
            && medicine.trailadjudicationwebresponse.status.statusCode!=undefined && medicine.trailadjudicationwebresponse.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
            && medicine.trailadjudicationwebresponse.status.respCode == APPCONSTANTS.API_RESPONSE_SUCCESS_CODE){
              this.accumulateDurSegmentsIntoEveryTrailResponse(medicine,medicine.trailadjudicationwebrequest.durSegment);
              this.showUnFilledSoftRejectsInTrailResponse(request, medicine.trailadjudicationwebresponse.response.durSegment,  medicine.trailadjudicationwebresponse.response.rejectCode.length>0 &&  medicine.trailadjudicationwebresponse.response.rejectCode.indexOf(APPCONSTANTS.RTB_DUR_REJECT_CODE)!=-1);
          }        
        break;
        // case CLAIM_STATUS_TYPE.SAVED:
        // case CLAIM_STATUS_TYPE.RE_EXECUTE:
        //   if(medicine.saveClaimResponseLstClaim!=undefined && medicine.saveClaimResponseLstClaim.status!=undefined
        //     && medicine.saveClaimResponseLstClaim.status.statusCode!=undefined && medicine.saveClaimResponseLstClaim.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
        //     && medicine.saveClaimResponseLstClaim.status.dbStatusCode!=undefined && medicine.saveClaimResponseLstClaim.status.dbStatusCode == APPCONSTANTS.API_RESPONSE_DB_SUCCESS_CODE
        //     && medicine.saveClaimResponseLstClaim.claimDetailId!=undefined && medicine.saveClaimResponseLstClaim.claimDetailId.toString().length>0 
        //     && medicine.blnSavedAfterTrail){
        //       this.accumulateDurSegmentsIntoEveryTrailResponse(medicine, medicine.saveClaimResponseLstClaim.durSegment);
        //       this.showUnFilledSoftRejectsInTrailResponse(request, medicine.trailadjudicationwebresponse.response.durSegment,  medicine.trailadjudicationwebresponse.response.rejectCode.length>0 &&  medicine.trailadjudicationwebresponse.response.rejectCode.indexOf(APPCONSTANTS.RTB_DUR_REJECT_CODE)!=-1);
        //     }
        // break;
      }
    }
  }

  getValidAlternatePharmacies(claimResponse: SelectedMedicine) {
    if(claimResponse.trailadjudicationwebresponse.prefAlternativesWebResponse.alternatePharmacies.length>0){
      this.validAlternatePharmacies=claimResponse.trailadjudicationwebresponse.prefAlternativesWebResponse.alternatePharmacies.filter(a=>claimResponse.trailadjudicationwebresponse.prefAlternativesWebResponse.scenarioClaims.some(sc=>sc.responseHeaderSegment.serviceProviderId==a.serviceProviderId));
    }else{
      this.validAlternatePharmacies=[];
    }
    console.log("Valid pharmacies:");
    console.log(this.validAlternatePharmacies);
  }
}

