import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Membersearchwebresponse, FavouriteMembersWebResponse, FavouriteMember, MemberSearchV5Item } from 'src/app/modals/membersearch/membersearchwebresponse';
import { MemberSearchItem } from 'src/app/modals/drugSearch/drugsearchwebrequest';
import { Membersearchwebrequest } from 'src/app/modals/membersearch/membersearchwebrequest';
import { environment } from 'src/environments/environment';
import { Status } from 'src/app/modals/webresponsestatus';
import { BehaviorSubject } from 'rxjs';
import { LoggerService } from '../logger/logger.service';
import { APPCONSTANTS } from 'src/app/constants/appconstants';
import { MedDmemberRequest } from 'src/app/modals/membersearch/medDmemberwebrequest';
import { MedDmemberResponse, MedicareMemberPartDdetail, MedicareMemberPartDitem } from 'src/app/modals/membersearch/medDmemberwebresponse';
import { MemberDetailwebrequest } from 'src/app/modals/membersearch/memberdetailwebrequest';
import { ProfileService } from '../profile/profile.service';
//import { MemberDetailWebResponse } from 'src/app/modals/membersearch/memberdetailwebresponse';

@Injectable({
  providedIn: 'root'
})
export class MembersearchService {

  //private savedMembers:FavouriteMember[]=[];  

  savedMembers: BehaviorSubject<FavouriteMember[]> = new BehaviorSubject<FavouriteMember[]>([]);
  medDmembers: BehaviorSubject<MedicareMemberPartDdetail[]> = new BehaviorSubject<MedicareMemberPartDdetail[]>([]);

  constructor(private http: HttpClient, private logger: LoggerService, private profileService: ProfileService) { }

  storeSelectedMember(member: MemberSearchV5Item | MemberSearchItem): void {
    if (member != undefined && member != null)
      sessionStorage.setItem('memberdetails', JSON.stringify(member));
  }

  getSelectedMember(): MemberSearchV5Item | MemberSearchItem | undefined {
    let memberJson: string | null = sessionStorage.getItem('memberdetails');

    try {
      if (memberJson != null) {
        const member: MemberSearchV5Item = JSON.parse(memberJson);
        return member;
      }
    } catch (e) {
      //console.log("Error in parsing the selected member from browser session.");
    }

    return undefined;
  }

  clearSelectedMember(): void {
    sessionStorage.removeItem('memberdetails');
  }

  searchForMember(webrequest: Membersearchwebrequest): Promise<Membersearchwebresponse> {
    const promise: Promise<Membersearchwebresponse> = new Promise<Membersearchwebresponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.memberSearchUrl,"X-CSRF-Token":this.profileService.getCsrfToken()});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {

        if (response.status != undefined
          && response.status.statusCode != undefined
          && (response.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT ||
            response.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_WARNING_TXT ||
            response.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_INFO_TXT)
        ) {
          resolve(response);
          console.log(response);

        } else {
          this.logger.log(response);
          reject(response);
          console.log(response);

        }
      }, (error: any) => {
        this.logger.error(error);
        reject(error);
        
      });
    });
    return promise;
  }

  checkMedDmember(webrequest: MedDmemberRequest): Promise<MedDmemberResponse> {
    const promise: Promise<MedDmemberResponse> = new Promise<MedDmemberResponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.checkMmdPartDMemberUrl,"X-CSRF-Token":this.profileService.getCsrfToken()});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        
        if (response.response!=undefined &&response.status != undefined
          && response.status.statusCode != undefined && response.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
          this.medDmembers.next(response.response.medicareMemberPartDDetail);
          resolve(response);
        } else {
           this.medDmembers.next([]);
          this.logger.log(response);
          resolve(response);
        }
      }, (error: any) => {
         this.medDmembers.next([]);
        this.logger.error(error);
        reject(error);
      });
    });
    return promise;
  }

  getFavouriteMembers(presbrId: string): Promise<FavouriteMembersWebResponse> {
    
    const promise: Promise<FavouriteMembersWebResponse> = new Promise<FavouriteMembersWebResponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.favouriteMembersUrl.replace("{presbrId}", presbrId),"X-CSRF-Token":this.profileService.getCsrfToken()});
      this.http.post(environment.proxyurl, null,{headers:header}).subscribe((response: any) => {
        
        if (response.status != undefined
          && response.status.statusCode != undefined && response.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
          && response.status.dbStatusCode != undefined && response.status.dbStatusCode == APPCONSTANTS.API_RESPONSE_SUCCESS_NBR) {
          this.savedMembers.next(response.memberSearchFavoriteList);
          resolve(response);
        } else {
          this.savedMembers.next([]);
          resolve(response);
        }
      }, (error: any) => {
        this.savedMembers.next([]);
        this.logger.error(error);
        reject(error);
      });
    });
    return promise;
  }

  public getCachedFavouriteMembers(): FavouriteMember[] {
    return this.savedMembers.getValue();
  }

  // public getMedMembers():MedicareMemberPartDdetail[] {
  //   return this.medDmembers.getValue();   
  // }

  public getFavouriteMember(memberId: string): FavouriteMember | undefined {
    if (this.getCachedFavouriteMembers().length > 0) {
      const members: FavouriteMember[] = this.getCachedFavouriteMembers().filter((member: FavouriteMember) => member.memberId == memberId);
      return members.length > 0 ? members[0] : undefined;
    }
    return undefined;
  }

  public isFavouriteMember(memberId: string): boolean {
    return this.getCachedFavouriteMembers().filter((member: FavouriteMember) => member.memberId == memberId).length > 0 ? true : false;
  }

  public addFavouriteMember(member: FavouriteMember): Promise<Status> {
    
    const promise: Promise<Status> = new Promise<Status>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.addFavouriteMemberUrl,"X-CSRF-Token":this.profileService.getCsrfToken()});
      this.http.post(environment.proxyurl, member,{headers:header}).subscribe((response: any) => {
        if (response.status != undefined
          && response.status.statusCode != undefined && response.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
          resolve(response.status);
        } else {
          this.logger.log(response);
          reject(response.status);
        }
      }, (error: any) => {
        this.logger.error(error);
        reject(error);
      });
    });
    return promise;
  }

  public removeFavouriteMember(mbrSearchId: number): Promise<Status> {
    const promise: Promise<Status> = new Promise<Status>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.removeFavouriteMemberUrl.replace("{mbrSearchId}", mbrSearchId.toString()),"X-CSRF-Token":this.profileService.getCsrfToken()});
      this.http.post(environment.proxyurl, null,{headers:header}).subscribe((response: any) => {
        
        if (response.status != undefined
          && response.status.statusCode != undefined && response.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
          && response.status.dbStatusCode != undefined && response.status.dbStatusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_DB_SUCCESS_CODE) {
          resolve(response.status);
        } else {
          this.logger.log(response);
          reject(response.status);
        }
      }, (error: any) => {
        
        this.logger.error(error);
        reject(error);
      });
    });
    return promise;
  }
 
  // isMedDMember(memberId: string):boolean {
  //   return this.getMedMembers().filter((member: MedicareMemberPartDdetail) => member.memberId == memberId).length > 0 ? true : false;

  // }
  /*getMemberDetails(webrequest: MemberDetailwebrequest): Promise<MemberDetailWebResponse> {
    const promise: Promise<MemberDetailWebResponse> = new Promise<MemberDetailWebResponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.getMemberDetailsUrl});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        if (response.status != undefined
          && response.status.statusCode != undefined
          && (response.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT ||
            response.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_WARNING_TXT ||
            response.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_INFO_TXT)
        ) {
          resolve(response);
        } else {
          this.logger.log(response);
          reject(response);
        }
      }, (error: any) => {
        this.logger.error(error);
          reject(error);
      });
    });
    return promise;
  }*/
}
