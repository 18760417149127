import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BOOLEAN_FLAG } from 'src/app/constants/appconstants';
import { SavedPAResponse } from 'src/app/modals/epa/priorauthresponse';

import { getRecentPrescriberDetailsRequest } from 'src/app/modals/viewallnotificationscalls/getRecentPrescriberDetailsRequest';
import { viewAllNotificationsRequest } from 'src/app/modals/viewallnotificationscalls/viewAllNotificationsRequest';
import { NotificationResponse, viewAllNotificationsResponse } from 'src/app/modals/viewallnotificationscalls/viewAllNotificationsResponse';
import { TimeStampToStandardDate } from 'src/app/pipes/dateconversion/dateconversion.pipe';
import { EpaprocessorService } from 'src/app/services/epa/epaprocessor.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { NotificationsService } from 'src/app/services/viewAllNotificationsServices/notifications.service';

@Component({
  selector: 'viewallnotifications',
  templateUrl: './viewallnotifications.component.html',
  styleUrls: ['./viewallnotifications.component.css']
})
export class ViewallnotificationsComponent implements OnInit {
  constructor(private profileService: ProfileService, private router: Router, private notificaionsService: NotificationsService, private epaServiceHelper: EpaprocessorService,private timestampTostandardDate: TimeStampToStandardDate,private modalService: NgbModal) {
    if (this.profileService.loginuser != undefined) {
      this.role = this.profileService.USER_ROLE;
      this.userRole = "" + this.profileService.loginuser?.prescriber?.roleTypeId;
      this.userID = "" + this.profileService.loginuser?.prescriber?.presbrId;
      this.useruuid = "" + this.profileService.loginuser?.prescriber?.uuid;
    }

  }
  ntfsID!: String;
  dltNtfyType: any = 'delete';
  userRole!: string;
  userID!: string;
  useruuid!: string;
  quesArry: any;
  questionArry: any;
  answArry: any;
  attachmentArry: any;


  numberofnotifications!: any;
  numberofnewnotifications: number = 0;

  allNotifications !: any;

  changePrescriberId!: String;

  role!: number;

  associatedUsers!: any;
  viewallprescribers: string = 'View all prescribers';

  @Output() newNotifications = new EventEmitter<any>();

    @ViewChild("errorsavedpa", { static: false })
  errorsavedpaErrorElementRef?: ElementRef<HTMLElement>;

  ngOnInit(): void {
    sessionStorage.setItem('paBackToPage','home');
    var apiParams = new viewAllNotificationsRequest();
    apiParams.toDate = this.formatDate(this.getTodate());
    apiParams.fromDate = this.formatDate(this.getDate180DaysBack());
    apiParams.maxCount = '10';
    apiParams.loginRoleId = this.userRole;
    if (this.role == 20) {
      apiParams.adminPresbrId = this.userID;
      apiParams.viewAllPresbrs = BOOLEAN_FLAG.YES;
      apiParams.presbrId = '';
    }
    else if (this.role == 10 || this.role == 13) {
      apiParams.presbrId = this.userID;
      apiParams.viewAllPresbrs = BOOLEAN_FLAG.NO;
      apiParams.adminPresbrId = '';
    }

    this.getNotifications(apiParams);


  }

  getNotifications(params: any) {
    this.notificaionsService.postGetNotifications(params).then((data: viewAllNotificationsResponse) => {
      if (data.status.statusCode == "Success") {
        //convert capital to lower and change time for display purpose  
        for (let i = 0; i < data.notificationResponse.length; i++) {
          data.notificationResponse[i].firstName = data.notificationResponse[i].firstName?.toLowerCase();
          data.notificationResponse[i].lastName = data.notificationResponse[i].lastName?.toLowerCase();
          data.notificationResponse[i].convertedTimestamp = this.timestampTostandardDate.transform(data.notificationResponse[i].date.toString());
        }
        this.allNotifications = data.notificationResponse;
        this.numberofnotifications = data.notificationResponse.length;
        this.getNumberOfNewNotificaions(this.allNotifications);

      }
    });
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return year + '-' + month + '-' + day;

  }

  getDate180DaysBack(): Date {
    const date = new Date();
    date.setDate(date.getDate() - 180);
    return date;
  }
  getTodate(): Date {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    return date;

  }

  // changeTimeformat(time: string) {
  //   var year = time.slice(0, 4);
  //   var month = time.slice(5, 7);
  //   var day = time.slice(8, 10);
  //   return month + '/' + day + '/' + year;
  // }

  onViewAllNotificationsClick() {
    this.router.navigateByUrl('/notifications/ViewAll');
  }
  changePrescriber($event: String) {
    this.changePrescriberId = $event;
    this.allNotifications = undefined;
    this.numberofnotifications = undefined;

    var apiParams = new viewAllNotificationsRequest();
    apiParams.toDate = this.formatDate(this.getTodate());
    apiParams.fromDate = this.formatDate(this.getDate180DaysBack());
    apiParams.maxCount = '10';
    apiParams.loginRoleId = this.userRole
    apiParams.adminPresbrId = this.userID
    if ($event != this.viewallprescribers) {
      apiParams.viewAllPresbrs = BOOLEAN_FLAG.NO;
      apiParams.presbrId = '' + $event;
    }
    else if ($event == this.viewallprescribers) {
      apiParams.viewAllPresbrs = BOOLEAN_FLAG.YES;
      apiParams.presbrId = '';
    }
    this.getNotifications(apiParams);


  }

  onDelete($event: String) {
    if ($event == 'true')
      this.numberofnotifications = parseInt(this.numberofnotifications) - 1;
    this.getNumberOfNewNotificaions(this.allNotifications);
  }

  onViewPAClick($event: NotificationResponse) {
    debugger;
    var paResponseHistory = new NotificationResponse;
    paResponseHistory = $event;
    if ($event.caseId != undefined) {

      this.epaServiceHelper.resumeCellClickPA("" + $event.caseId).then((response: SavedPAResponse) => {
        console.log(response);

        if (response.paInitiationResponseWeb.body == undefined) {

          this.quesArry = [];
        }
        else {
          this.quesArry = response.paInitiationResponseWeb.body.parequest.request.solicitedModel.questionSet.question;
        }
        //console.log("data",response);
        if (response.paResumeDetails != undefined) {

          this.attachmentArry = response.paResumeDetails.paAttachment;
          //console.log("attachments",this.attachmentArry);
        }
        else {
          this.attachmentArry = [];
        }

        sessionStorage.setItem("pAdetails", JSON.stringify(paResponseHistory));
        //console.log("quesArry",this.quesArry);
        sessionStorage.setItem("Array", JSON.stringify(this.quesArry));
        //sessionStorage.setItem("Modal",JSON.stringify(response.paInitiationResponseWeb.body.parequest.request.solicitedModel.questionSet));
        sessionStorage.setItem("Attachements", JSON.stringify(this.attachmentArry));
        this.router.navigateByUrl("/PriorAuthorizationDetails");

      }, (error: any) => {
        this.modalService.open(this.errorsavedpaErrorElementRef, { modalDialogClass: 'errorsavedpa', size: 'xs',backdrop:'static' });
      }).catch((reason: any) => {
        this.modalService.open(this.errorsavedpaErrorElementRef, { modalDialogClass: 'errorsavedpa', size: 'xs',backdrop:'static' });
      });
    }
    else {
      sessionStorage.setItem("pAdetails", JSON.stringify(paResponseHistory));
      this.router.navigateByUrl("/PriorAuthorizationDetails");
    }
  }
  getNumberOfNewNotificaions(notficationArray: any) {

    this.numberofnewnotifications = 0;
    for (let i = 0; i < notficationArray.length; i++) {
      if (notficationArray[i].readInd == "N") {
        this.numberofnewnotifications++;
      }
    } debugger;
    this.newNotifications.emit(this.numberofnewnotifications);
  }
  goto(routeUrl:string){
    this.router.navigateByUrl("/"+routeUrl);
  }

  //end brace  
}
