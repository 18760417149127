import { Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { BOOLEAN_FLAG, USER_ROLE } from 'src/app/constants/appconstants';
import { SavedPAResponse } from 'src/app/modals/epa/priorauthresponse';
import { viewAllNotificationsRequest } from 'src/app/modals/viewallnotificationscalls/viewAllNotificationsRequest';
import { NotificationResponse, viewAllNotificationsResponse } from 'src/app/modals/viewallnotificationscalls/viewAllNotificationsResponse';
import { TimeStampToStandardDate } from 'src/app/pipes/dateconversion/dateconversion.pipe';
import { EpaprocessorService } from 'src/app/services/epa/epaprocessor.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { NotificationsService } from 'src/app/services/viewAllNotificationsServices/notifications.service';


@Component({
  selector: 'deletednotificationpage',
  templateUrl: './deletednotificationpage.component.html',
  styleUrls: ['./deletednotificationpage.component.css']
})
export class DeletednotificationpageComponent implements OnInit {
  unfilterednotifications: NotificationResponse[]=[];

  constructor(private fb: FormBuilder, private deletedNtfs: NotificationsService, private router: Router, private profileService: ProfileService,private epaServiceHelper:EpaprocessorService,private timestampTostandardDate: TimeStampToStandardDate) {
    if (this.profileService.loginuser != undefined) {
      this.role = this.profileService.USER_ROLE;
      this.userRole = "" + this.profileService.loginuser?.prescriber?.roleTypeId;
      this.userID = "" + this.profileService.loginuser?.prescriber?.presbrId;
      this.useruuid = "" + this.profileService.loginuser?.prescriber?.uuid;
    }
  }
  errorRetreivingdata: boolean = false;

  allNotifications!: any;
  role!: USER_ROLE;
  dltNtfyType: any = 'permanent';
  viewallprescribers: string = 'View all prescribers';
  userSelectedStatus: String = 'ViewAll';
  searchText:String='';
  userRole!: string;
  userID!: string;
  useruuid!: string;

  quesArry:any;
  questionArry:any;
  answArry:any;
  attachmentArry:any;



  ngOnInit(): void {

    sessionStorage.setItem("lastPageBeforeReload", this.router.url);
    sessionStorage.setItem('paBackToPage','deleted');

      var apiParams = new viewAllNotificationsRequest();
      apiParams.toDate = this.formatDate(this.getTodate());
      apiParams.fromDate = this.formatDate(this.getDate7DaysBack());
      apiParams.maxCount = '';
      apiParams.loginRoleId = this.userRole;
      if (this.role == 20) {
        apiParams.adminPresbrId = this.userID
        apiParams.viewAllPresbrs = BOOLEAN_FLAG.YES;
        apiParams.presbrId = '';
      }
      else if (this.role == 10 || this.role == 13) {
        apiParams.presbrId = this.userID;
        apiParams.viewAllPresbrs = BOOLEAN_FLAG.NO;
        apiParams.adminPresbrId = '';
      }

      this.getDeletedNotification(apiParams);


  }

  getDeletedNotification(params: any) {

    this.deletedNtfs.postGetDeletedNotifications(params).then((data: viewAllNotificationsResponse) => {
      if (data.status.statusCode == "Success") {
        //convert capital to lower and change time for display purpose  
        for (let i = 0; i < data.notificationResponse.length; i++) {
          data.notificationResponse[i].firstName = data.notificationResponse[i].firstName?.toLowerCase();
          data.notificationResponse[i].lastName = data.notificationResponse[i].lastName?.toLowerCase();
          data.notificationResponse[i].convertedTimestamp = this.timestampTostandardDate.transform(data.notificationResponse[i].date.toString());
        }

        this.unfilterednotifications = data.notificationResponse;
        //to add search text to all notifications 
        for (let i = 0; i < this.unfilterednotifications.length; i++) {
          if (this.unfilterednotifications[i].paStatus == 'SavedPA') {
            this.unfilterednotifications[i].msg = "Saved PA Request Expiring A saved prior authorization request for " + this.unfilterednotifications[i].firstName + ' ' + this.unfilterednotifications[i].lastName + "'s for " + this.unfilterednotifications[i].drugName + " is expiring soon. Resume"
          }
          else if (this.unfilterednotifications[i].paStatus == 'Cancelled' || this.unfilterednotifications[i].paStatus == 'Denied' || this.unfilterednotifications[i].paStatus == 'Approved') {
            this.unfilterednotifications[i].msg = " PA Request " + this.unfilterednotifications[i].paStatus + " " + this.unfilterednotifications[i].firstName + ' ' + this.unfilterednotifications[i].lastName + "'s prior authorization request for " + this.unfilterednotifications[i].drugName + " has been " + this.unfilterednotifications[i].paStatus + ". View prior auth details"

          }
        }
        this.allNotifications=this.unfilterednotifications;
        this.onFilterSearch();
        
      }
      else {

        this.errorRetreivingdata = true;
      }

    });
  }


  // changeTimeformat(time: string) {
  //   var year = time.slice(0, 4);
  //   var month = time.slice(5, 7);
  //   var day = time.slice(8, 10);
  //   return month + '/' + day + '/' + year;
  // }


  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return year + '-' + month + '-' + day;

  }

  getDate7DaysBack(): Date {
    const date = new Date();
    date.setDate(date.getDate() - 7);
    return date;
  }

  getTodate(): Date {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    return date;

  }

  onBackClick() {
    this.router.navigateByUrl('/notifications/ViewAll');
  }

  changePrescriber($event: String) {
    this.allNotifications = undefined;

      var apiParams = new viewAllNotificationsRequest();
      apiParams.toDate = this.formatDate(this.getTodate());
      apiParams.fromDate = this.formatDate(this.getDate7DaysBack());
      apiParams.maxCount = '';
      apiParams.loginRoleId = this.userRole;
      apiParams.adminPresbrId = this.userID;
      if ($event != this.viewallprescribers) {
        apiParams.viewAllPresbrs = BOOLEAN_FLAG.NO;
        apiParams.presbrId = '' + $event;
      }
      else if ($event == this.viewallprescribers) {
        apiParams.viewAllPresbrs = BOOLEAN_FLAG.YES;
        apiParams.presbrId = '';
      }
      this.getDeletedNotification(apiParams);


  }

  onFilterSearch() {

    //to filter by status   
    this.allNotifications = undefined;
    var storeNtfs: Array<any> = this.unfilterednotifications;
    var emptarray: Array<any> = [];

    if (this.userSelectedStatus != 'ViewAll') {
      for (let i = 0; i < storeNtfs.length; i++) {
        if (storeNtfs[i].paStatus == this.userSelectedStatus) {
          emptarray.push(storeNtfs[i]);
        }
      }
      if (this.searchText != '') {
        emptarray = emptarray.filter(item => item.msg?.toLowerCase().includes(this.searchText?.toLowerCase()))
      }
      this.allNotifications = emptarray;
    }
    else if (this.userSelectedStatus == 'ViewAll') {
      if (this.searchText != '') {
        storeNtfs = storeNtfs.filter(item => item.msg?.toLowerCase().includes(this.searchText?.toLowerCase()))
        this.allNotifications=storeNtfs;
      }
      else{
        this.allNotifications = this.unfilterednotifications;
      }

    }
  }

  onViewPAClick($event:NotificationResponse){

    var paResponseHistory= new NotificationResponse;
    paResponseHistory=$event;
    if($event.caseId!=undefined){

    this.epaServiceHelper.resumeCellClickPA(""+$event.caseId).then((response:SavedPAResponse) => {
      console.log(response);
      
      if(response.paInitiationResponseWeb.body==undefined){
        
        this.quesArry = [];
      }
      else {
   this.quesArry= response.paInitiationResponseWeb.body.parequest.request.solicitedModel.questionSet.question;
     }
   //console.log("data",response);
   if(response.paResumeDetails!=undefined){
     
   this.attachmentArry = response.paResumeDetails.paAttachment;
   //console.log("attachments",this.attachmentArry);
   }
   else{
     this.attachmentArry=[];
   }

    sessionStorage.setItem("pAdetails",JSON.stringify(paResponseHistory));
   //console.log("quesArry",this.quesArry);
   sessionStorage.setItem("Array",JSON.stringify(this.quesArry));
  //sessionStorage.setItem("Modal",JSON.stringify(response.paInitiationResponseWeb.body.parequest.request.solicitedModel.questionSet));
   sessionStorage.setItem("Attachements",JSON.stringify(this.attachmentArry));
   this.router.navigateByUrl("/PriorAuthorizationDetails");
    
});   
    }
    else{
      sessionStorage.setItem("pAdetails",JSON.stringify(paResponseHistory));
      this.router.navigateByUrl("/PriorAuthorizationDetails");
    }
  }

}
