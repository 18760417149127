import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { APPCONSTANTS, PRESCRIBER_QUALIFIER, DRUG_QUALIFIER_LABEL, DRUG_QUALIFIER,  USER_ROLE_Label, USER_ROLE } from 'src/app/constants/appconstants';
import { StandardDateToServertime, TimeStampToStandardDate } from 'src/app/pipes/dateconversion/dateconversion.pipe';
import { MemberDetailWebRequesthemi, MemberDetailwebrequest } from 'src/app/modals/membersearch/memberdetailwebrequest';
//import { MemberDetailWebResponse } from 'src/app/modals/membersearch/memberdetailwebresponse';
import { Membersearchwebrequest } from 'src/app/modals/membersearch/membersearchwebrequest';
import { MembersearchService } from '../membersearch/membersearch.service';
import { FavouriteMember, MemberSearchV5Item, Membersearchwebresponse } from 'src/app/modals/membersearch/membersearchwebresponse';
import { LoggerService } from '../logger/logger.service';
import { ProfileService } from '../profile/profile.service';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { SavedPARequest } from 'src/app/modals/epa/priorauthrequest';
import { SavedPAResponse, SavedPAResumeDetails } from 'src/app/modals/epa/priorauthresponse';
import { ResubmitSavedPA } from 'src/app/modals/epa/priorauthresponse';
import { DrugSearchItem ,AdditionalField,Drugsearchwebresponse, MemberNtfyResponse} from 'src/app/modals/drugSearch/drugsearchwebresponse';
import { Drugsearchwebrequest, MemberSearchItem, SelectedMedicine } from 'src/app/modals/drugSearch/drugsearchwebrequest';
import { memberNotificationsRequest } from 'src/app/modals/pahistory/notificationhistorywebrequest';
import { MemberDetailWebResponsehemi } from 'src/app/modals/membersearch/memberdetailresponsehemi';
@Injectable({
    providedIn: 'root'
  })
  export class MemberDetailService {
    memberSearchResult!: MemberSearchV5Item | MemberSearchItem | undefined;
    resumeDetails?: SavedPAResumeDetails;
    private resubmitPASubscription!:Subscription;
    url = environment.proxyurl
    private resumePASubscription!: Subscription;
    private paSubmittedResumeSubscription!: Subscription;
    constructor(private http: HttpClient, private profileService: ProfileService,
        //private prescriberSearch: PrescriberSearchService,
        private timeStampToStandardDate: TimeStampToStandardDate,
        //private calculateQtyService: CalculateQuantityService,
    private standardDateToServertime: StandardDateToServertime,
    private memberSearchHelper: MembersearchService, private logger: LoggerService) { }


    /*getMemberDetails(webrequest: MemberDetailwebrequest): Promise<MemberDetailWebResponse> {
    const promise: Promise<MemberDetailWebResponse> = new Promise<MemberDetailWebResponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.getMemberDetailsUrl});
      //this.http.post(environment.getMemberDetailsUrl, webrequest).subscribe((response: any)
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        debugger;
        if (response.status != undefined
          && response.status.statusCode != undefined
          && (response.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT ||
            response.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_WARNING_TXT ||
            response.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_INFO_TXT)
        ) {
          resolve(response);
        } else {
          this.logger.log(response);
          reject(response);
        }
      }, (error: any) => {
        this.logger.error(error);
          reject(error);
      });
    });
    return promise;
  }*/
  getMemberDetailshemi(webrequest: MemberDetailWebRequesthemi): Promise<MemberDetailWebResponsehemi> {
    const promise: Promise<MemberDetailWebResponsehemi> = new Promise<MemberDetailWebResponsehemi>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.getMemberDetailshemiUrl,"X-CSRF-Token":this.profileService.getCsrfToken()});
      //this.http.post(environment.getMemberDetailsUrl, webrequest).subscribe((response: any)
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        debugger;
        if (response.status != undefined
          && response.status.statusCode != undefined
          && (response.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT ||
            response.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_WARNING_TXT ||
            response.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_INFO_TXT)
        ) {
          resolve(response);
        } else {
          this.logger.log(response);
          reject(response);
        }
      }, (error: any) => {
        this.logger.error(error);
          reject(error);
      });
    });
    return promise;
  }
      getMemberNotification(webrequest:memberNotificationsRequest): Promise<MemberNtfyResponse> {
        const promise: Promise<MemberNtfyResponse> = new Promise<MemberNtfyResponse>((resolve, reject) => {
          //let requests: memberNotificationsRequest[] = [];
          //const request: memberNotificationsRequest = this.getMemberNotificationsRequest();
          let header=new HttpHeaders({"endpoint":environment.memberNotificationsUrl,"X-CSRF-Token":this.profileService.getCsrfToken()});
          this.http.post(environment.proxyurl,webrequest,{headers:header}).subscribe((response: any) => {
            if (response.status != undefined
              && response.status.statusCode != undefined
              && (response.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT ||
                response.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_WARNING_TXT ||
                response.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_INFO_TXT)
            ) {
              debugger;
              if(response.drugSearchResponse.response != undefined && response.drugSearchResponse.response.drugSearchItems != undefined &&Array.isArray(response.drugSearchResponse.response.drugSearchItems)){
                response.drugSearchResponse.response.drugSearchItems.forEach((item: DrugSearchItem) => {
                item.detailedDrugName = this.getDrugDetailedName(item);});
              }
              resolve(response);
            } else {
              this.logger.log(response);
              reject(response);
            }
          }, (error: any) => {
            this.logger.error(error);
            reject(error);
          });
        });
        return promise;
      }
      getDrugSearchItems(ndc: Drugsearchwebrequest): Promise<Drugsearchwebresponse> {
        const promise: Promise<Drugsearchwebresponse> = new Promise<Drugsearchwebresponse>((resolve, reject) => {
          let header=new HttpHeaders({"endpoint":environment.getDrugSearchDetailsUrl,"X-CSRF-Token":this.profileService.getCsrfToken()});
          this.http.post(environment.proxyurl, ndc,{headers:header}).subscribe((response: any) => {
            if (response.status != undefined
              && response.status.statusCode != undefined
              && (response.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT ||
                response.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_WARNING_TXT ||
                response.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_INFO_TXT)
            ) {
              debugger;
              if (response.response != undefined && response.response.drugSearchItems != undefined && Array.isArray(response.response.drugSearchItems)) {
                response.response.drugSearchItems.forEach((item: DrugSearchItem) => {
                  item.detailedDrugName = this.getDrugDetailedName(item);
                });
              }
              resolve(response);
            } else {
              this.logger.log(response);
              reject(response);
            }
          }, (error: any) => {
            this.logger.error(error);
            reject(error);
          });
        });
        return promise;
      }
    
    
     /*  getMemberNotificationsRequest(): memberNotificationsRequest{
        let webrequest: memberNotificationsRequest = new memberNotificationsRequest();
        let drugSearchRequestObject: Drugsearchwebrequest = new Drugsearchwebrequest();
        webrequest.memberId ="" +this.memberSearchResult?.memberId;
      webrequest.presbrId =""+ this.profileService.loginuser.prescriber?.presbrId;
      webrequest.npi =""+this.profileService.loginuser.prescriber?.npi;
      webrequest.paCaseId = ""+this.resumeDetails?.paCaseId;
      webrequest.uuid = ""+this.profileService.loginuser.UUID;
      webrequest.drugSearchRequest = drugSearchRequestObject;
      webrequest.drugSearchRequired = true;
      webrequest.notificationRequired = true;
      return webrequest;
      }*/

      resumeSavedPA(paReferenceId: string, uuid: string): Promise<SavedPAResponse> {
        const promise: Promise<SavedPAResponse> = new Promise<SavedPAResponse>((resolve, reject) => {
          //this.http.get('./assets/mockresponses/resumeSavedPAResponse.json').subscribe((response:any)=>{
            let header=new HttpHeaders({"endpoint":environment.getSavedPADetailsUrl.replace("{parefId}", paReferenceId).replace("{uuid}", uuid),"X-CSRF-Token":this.profileService.getCsrfToken()});
          this.resumePASubscription = this.http.post(environment.proxyurl, null,{headers:header}).subscribe((response: any) => {
            if (response != undefined && response.status != undefined
              && response.status.statusCode != undefined && response.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
              resolve(response);
            } else {
              this.logger.log(response);
              reject(response);
            }
          }, (error: any) => {
            this.logger.error(error);
            reject(error);
          });
        });
        return promise;
      } 
      resubmitSavedPA(paReferenceId:string):Promise<ResubmitSavedPA>{
        const promise:Promise<ResubmitSavedPA> = new Promise<ResubmitSavedPA>((resolve,reject)=>{
          //this.http.get('./assets/mockresponses/resumeSavedPAResponse.json').subscribe((response:any)=>{
            let header=new HttpHeaders({"endpoint":environment.getResubmitSavedPADetailsUrl.replace("{parefId}",paReferenceId),"X-CSRF-Token":this.profileService.getCsrfToken()});
          this.resubmitPASubscription =  this.http.post(environment.proxyurl,null,{headers:header}).subscribe((response:any)=>{
            if(response!=undefined && response.status!=undefined 
              && response.status.statusCode!=undefined && response.status.statusCode?.toLowerCase()==APPCONSTANTS.API_RESPONSE_SUCCESS_TXT){
                resolve(response);
              } else {
                reject(response);
              }
          },(error:any)=>{
              reject(error);
          });
        });
        return promise;
      }
      resumeCellClickPA(paCaseID: string): Promise<SavedPAResponse> {
        const promise: Promise<SavedPAResponse> = new Promise<SavedPAResponse>((resolve, reject) => {
          //this.http.get('./assets/mockresponses/resumeSavedPAResponse.json').subscribe((response:any)=>{
            let header=new HttpHeaders({"endpoint":environment.viewQuestionSetUrl.replace("{paCaseID}", paCaseID),"X-CSRF-Token":this.profileService.getCsrfToken()});
          this.paSubmittedResumeSubscription = this.http.post(environment.proxyurl, null,{headers:header}).subscribe((response: any) => {
            if (response != undefined && response.status != undefined
              && response.status.statusCode != undefined && response.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
              resolve(response);
            } else {
              this.logger.log(response);
              reject(response);
            }
          }, (error: any) => {
            this.logger.error(error);
            reject(error);
          });
        });
        return promise;
      }
      
      getDrugDetailedName(drugSearchItem: DrugSearchItem): string {

        let otcCode: string = "";
        let packageSize: string = "";
        let packageSizeUnitOfMeasure: string = "";
        let packageDesc: string = "";
        let labelName: string = "";
    
        if (drugSearchItem.otcCode != undefined && (drugSearchItem.otcCode?.toLowerCase() == 'p' || drugSearchItem.otcCode?.toLowerCase() == 'o')) {
          otcCode = " (OTC)";
        }
    
        if (drugSearchItem.prodNameAbbr != undefined) {
          //removes special characters equivalent to whitespace into whitespaces.
          labelName = drugSearchItem.prodNameAbbr.replace(/\s+/g, " ").trim();
        }
    
        if (drugSearchItem.additionalField.length > 0) {
    
          drugSearchItem.additionalField.forEach((field: AdditionalField) => {
            switch (field.fieldDescription?.toLowerCase()) {
              case 'packagesizeunitofmeasure':
                packageSizeUnitOfMeasure = field.fieldStringValue;
                break;
              case 'packagedesc':
                packageDesc = this.sentenceCase(field.fieldStringValue);
                break;
              case 'packagesize':
                if (field.fieldDecimalValue != undefined) {
                  packageSize = parseFloat(field.fieldDecimalValue).toString();
                }
                break;
            }
          });
          if (packageDesc.trim().length > 0) {
            packageDesc += '(s)';
          } else {
            packageDesc = '';
          }
        } else {
          return '';
        }
    
        if (drugSearchItem.breakableIndicator != undefined && drugSearchItem.breakableIndicator?.toLowerCase() == 'b') {
          return labelName + otcCode;
        }
        else {
          if (drugSearchItem.breakableIndicator != undefined && drugSearchItem.breakableIndicator?.toLowerCase() == 'u') {
            if (packageSize.trim().length > 0 && packageSizeUnitOfMeasure.trim().length > 0 && packageDesc.trim().length > 0) {
              return labelName + ', ' + packageSize + ' ' + packageSizeUnitOfMeasure + ' ' + packageDesc + otcCode;
            }
            else if (packageSizeUnitOfMeasure.trim().length == 0 && packageDesc.trim().length == 0) {
              return labelName + otcCode;
            }
            else if (packageSizeUnitOfMeasure.trim().length > 0 && packageDesc.trim().length > 0 && packageSize.trim().length == 0) {
              return labelName + ', ' + packageSizeUnitOfMeasure + ' ' + packageDesc + otcCode;
            }
            else if (packageSize.trim().length > 0 && packageSizeUnitOfMeasure.trim().length > 0 && packageDesc.trim().length == 0) {
              return labelName + ', ' + packageSize + ' ' + packageSizeUnitOfMeasure + otcCode;
            }
            else if (packageDesc.trim().length > 0 && packageSizeUnitOfMeasure.trim().length == 0 && packageSize.trim().length == 0) {
              return labelName + ', ' + packageDesc + otcCode;
            }
            else if (packageDesc.trim().length > 0 && packageSize.trim().length > 0 && packageSizeUnitOfMeasure.trim().length == 0) {
              return labelName + ', ' + packageDesc + otcCode;
            }
            else if (packageSizeUnitOfMeasure.trim().length > 0 && packageSize.trim().length == 0 && packageDesc.trim().length == 0) {
              return labelName + otcCode;
            }
            else {
              return labelName + otcCode;
            }
          }
          else {
            return labelName + otcCode;
          }
        }
    
        return '';
      }
      private sentenceCase(str: string): string {
        if ((str === null) || (str === ''))
          return "";
        else
          str = str.toString();
    
        return str.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1)?.toLowerCase(); });
      }
    
    }