import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { APPCONSTANTS, USER_ROLE } from 'src/app/constants/appconstants';
import { Prescriber, optumIDSearchResponse } from 'src/app/modals/internalcontrol/optumIDSearchResponse';
import { updatePresbrDetailsRequest } from 'src/app/modals/internalcontrol/updatePresbrDetailsRequest';
import { updatePresbrDetailsResponse } from 'src/app/modals/internalcontrol/updatePresbrDetailsResponse';
import { TimeStampToStandardDate } from 'src/app/pipes/dateconversion/dateconversion.pipe';
import { InternalcontrolService } from 'src/app/services/internalcontrolpannel/internalcontrol.service';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { ProfileService } from 'src/app/services/profile/profile.service';

@Component({
  selector: 'app-internalcontrolpannel',
  templateUrl: './internalcontrolpannel.component.html',
  styleUrls: ['./internalcontrolpannel.component.css'],
  animations: [
    trigger('fade',
      [
        state('void', style({
          opacity: 0,
          //transform:'translateX(20px)'//
        })),
        transition(':enter', [
          animate(1000)
        ])
      ])
  ]
})
export class InternalcontrolpannelComponent implements OnInit {

  @ViewChild("errorPopup", { static: true })
  errorPopupTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("successPopup", { static: true })
  successPopupTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("LoadingContent1")
  loadingContent?: TemplateRef<HTMLElement>;

  ngOnInit(): void {
    sessionStorage.setItem("lastPageBeforeReload", this.router.url);
  }
  constructor(private router: Router, private loggerService: LoggerService, private profileService: ProfileService, private fb: FormBuilder, private icpservice: InternalcontrolService, private timestamptostandarddate: TimeStampToStandardDate, private modalService: NgbModal) {

  }
  userNotFound: boolean = true;
  displayData: optumIDSearchResponse | undefined;
  role!: string;
  addressLine3!: string;
  editClicked: boolean = false;
  formSubmitted: boolean = false;
  searchSubmitted: boolean = false;
  userIDForm = this.fb.group({
    userID: ['', [Validators.pattern(/^[^\[\]`~\\|{}^]*$/), Validators.required, Validators.minLength(6)]],
  });
  editDetailsForm = this.fb.group({
    NPIFeild: ['', [Validators.pattern('[0-9]*$'), Validators.minLength(10), Validators.maxLength(10), Validators.required]],
    internalFeild: [true],
    statusFeild: [true],
  });
  show: boolean = false;
  show2: boolean = false;
  show3: boolean = false;
  clickOutside: boolean = true;
  advisorRole:boolean=false;
  inputEntered() {
    if (this.userIDForm.value?.userID != '') { }
    this.show = true;
  }
  clickedout() {
    this.clickOutside = true;
  }
  clickedin() {
    this.clickOutside = false;
  }
  getRole(role: string): string {
    var roletype: string = '';
    if (USER_ROLE.ADMIN.toString() == role) {
      roletype = 'Admin';
    }
    else if (USER_ROLE.PRESCRIBER.toString() == role) {
      roletype = 'Prescriber';
    }
    else if (USER_ROLE.PHARMACIST.toString() == role) {
      roletype = 'Pharmacy';
    }
    else if (USER_ROLE.SUPPORT.toString() == role) {
      roletype = 'Support';
    }
    else if (USER_ROLE.ADVISOR.toString() == role) {
      roletype = 'Advisor';
    }
    else if (USER_ROLE.SPECIALTYPHARMACIST.toString() == role) {
      roletype = 'Pharmacy Tech';
    }
    else if (USER_ROLE.SMAPPRESCRIBER.toString() == role) {
      roletype = 'SMAP Prescriber';
    }
    else if (USER_ROLE.SMAPADMIN.toString() == role) {
      roletype = 'SMAP Admin';
    }
    else if (USER_ROLE.SMAPSUPERADMIN.toString() == role) {
      roletype = 'SMAP Super Admin';
    }
    else if (USER_ROLE.SMAPCLAIMSUB.toString() == role) {
      roletype = 'SMAP Claim Sub';
    }

    return roletype;

  }

  getAddress() {
    debugger;
    // if (this.displayData != undefined && this.displayData.prescriber.city != undefined ) {
    //   if ( this.displayData.prescriber.city == "" || this.displayData.prescriber.city.length == 0) {
    //     this.addressLine3 = this.displayData.prescriber.stateProvince + " " + this.displayData.prescriber.postalCode;
    //   }
    //   else if (this.displayData.prescriber.city.length > 0 && this.displayData.prescriber.stateProvince.length > 0) {
    //     this.addressLine3 = this.displayData.prescriber.city?.toLowerCase() + ", " + this.displayData.prescriber.stateProvince + " " + this.displayData.prescriber.postalCode;
    //   }
    // }
    if (this.displayData != undefined) {
      if (this.displayData.prescriber.city == undefined) {
        if (this.displayData.prescriber.stateProvince == undefined ) {
          this.displayData.prescriber.stateProvince = '';
        }
        if (this.displayData.prescriber.postalCode == undefined) {
          this.displayData.prescriber.postalCode = '';
        }
        this.addressLine3 = this.displayData.prescriber.stateProvince + " " + this.displayData.prescriber.postalCode;
      }
      else if (this.displayData.prescriber.city != undefined) {
        if (this.displayData.prescriber.stateProvince == undefined) {
          this.displayData.prescriber.stateProvince = '';
        }
        if (this.displayData.prescriber.postalCode == undefined) {
          this.displayData.prescriber.postalCode = '';
        }
        if (this.displayData.prescriber.city == "" || this.displayData.prescriber.city.length == 0) {
          this.addressLine3 = this.displayData.prescriber.stateProvince + " " + this.displayData.prescriber.postalCode;
        }
        else if (this.displayData.prescriber.city.length > 0 && this.displayData.prescriber.stateProvince.length > 0) {
          this.addressLine3 = this.displayData.prescriber.city?.toLowerCase() + ", " + this.displayData.prescriber.stateProvince + " " + this.displayData.prescriber.postalCode;
        }
      }
    }
  }
  onSubmit() {
    this.editClicked = false;
    this.searchSubmitted = true;
    if (this.userIDForm.valid == true) {
      this.modalService.open(this.loadingContent, { size: 'md' ,backdrop:'static'});
      var optumID = '' + this.userIDForm.value?.userID;
      this.icpservice.postOptumIdSearch(optumID).then((data: optumIDSearchResponse) => {
        if (data.status.dbStatusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
          this.displayData = data;
          this.role = this.getRole(this.displayData.prescriber.roleTypeId);
          if(this.role=='Advisor'){
            this.advisorRole=true;
          }
          this.getAddress();
          this.editDetailsForm.get('NPIFeild')?.setValue(this.displayData.prescriber.npi);
          console.log(this.displayData);
          this.userNotFound = false;
        }
        else {
          this.userNotFound = true;
          this.addressLine3 = '';
          this.displayData = undefined;
          this.role = '';
        }
        this.modalService.dismissAll();
        this.searchSubmitted = false;
      });
    }



  }
  editUserDetails() {
    this.editClicked = true;
    this.editDetailsForm.get('NPIFeild')?.setValue('' + this.displayData?.prescriber.npi);
    this.editDetailsForm.get('internalFeild')?.setValue(this.displayData?.prescriber.userType == 'I' ? true : false);
    this.editDetailsForm.get('statusFeild')?.setValue(this.displayData?.prescriber.approveInd == 'Y' ? true : false);
  }
  onCancelClick() {
    this.editClicked = false;
  }
  onSaveClick() {
    this.formSubmitted = true;
    if (this.editDetailsForm.valid == true) {
      this.show2 = false;
      var webrequest = new updatePresbrDetailsRequest();
      webrequest.firstName = '' + this.displayData?.prescriber.firstName;
      webrequest.lastName = '' + this.displayData?.prescriber.lastName;

      webrequest.presbrId = '' + this.displayData?.prescriber.presbrId;
      webrequest.roleTypeId = '' + this.displayData?.prescriber.roleTypeId;
      webrequest.userType = '' + this.displayData?.prescriber.userType;
      webrequest.npi = '' + this.editDetailsForm.get('NPIFeild')?.value;
      webrequest.approveInd = this.editDetailsForm.get('statusFeild')?.value == true ? 'Y' : 'N';
      webrequest.userType = this.editDetailsForm.get('internalFeild')?.value == true ? 'I' : 'E';


      this.icpservice.postupdatePresbrDetails(webrequest).then((data: updatePresbrDetailsResponse) => {
        if (data.dbStatusDesc == "Updated successfully!") {
          console.log("Updated successfully!");
          this.editClicked = false;
          if (this.displayData != undefined) {
            this.displayData.prescriber.npi = '' + this.editDetailsForm.get('NPIFeild')?.value;
            this.displayData.prescriber.approveInd = this.editDetailsForm.get('statusFeild')?.value == true ? 'Y' : 'N';
            this.displayData.prescriber.userType = this.editDetailsForm.get('internalFeild')?.value == true ? 'I' : 'E';
          }
          this.formSubmitted = false;
          this.modalService.open(this.successPopupTemplateRef, { size: 'md',backdrop:'static'});
        }
        else {
          console.log("Failed to update!");
          this.modalService.open(this.errorPopupTemplateRef, { size: 'md',backdrop:'static'});
        }

      });
    }
    else {
      this.show2 = true;
    }
  }

  onToggle(event: Event): void {
    const isChecked = (event.target as HTMLInputElement).checked;
    console.log(isChecked ? 'Yes' : 'No');
  }
  onFocus() {
    this.show3 = true;
  }
  onBlur() {
    this.show3 = false;
  }

}
