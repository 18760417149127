import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TableCellData, TableClickEvent, TableHeaderCellData, TableRowData } from 'src/app/modals/datatable/datatable';
import { DrugSearchItem, Drugsearchwebresponse, FavDrugStatusResponse, FavoriteDrugsDetail, FavoriteDrugsDetailResponse } from 'src/app/modals/drugSearch/drugsearchwebresponse';
import { DrugsearchService } from 'src/app/services/drugsearch/drugsearch.service';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { debounceTime, distinctUntilChanged, map, switchMap, take, tap } from 'rxjs/operators';
import { Drugsearchwebrequest, MedicationPrescribed, RemoveFavRootObject } from 'src/app/modals/drugSearch/drugsearchwebrequest';
import { environment } from 'src/environments/environment';
import { MembersearchService } from 'src/app/services/membersearch/membersearch.service';

@Component({
  selector: 'drugsearch',
  templateUrl: './drugsearch.component.html',
  styleUrls: ['./drugsearch.component.css']
})
export class DrugsearchComponent implements OnInit {
  @ViewChild("errorPopup", { static: true })
  errorPopupTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("drugNameTemplate", { static: true })
  drugNameTemplateRef?: TemplateRef<any>

  @ViewChild("actionRemove", { static: true })
  actionRemove?: TemplateRef<any>

  @ViewChild("actionSelect", { static: true })
  actionSelect?: TemplateRef<any>

  @ViewChild("savedSearches")
  savedSearch?: ElementRef<HTMLElement>;

  @ViewChild("LoadingContent")
  loadingContent?: ElementRef<HTMLElement>;


  ngOnInit(): void {
    if (this.profileService.loginuser?.prescriber?.presbrId != undefined && this.profileService.loginuser.prescriber.uuid != undefined) {
      this.drugsearchHelper.getFavouriteDrugs(this.profileService.loginuser.prescriber.presbrId, this.profileService.loginuser.prescriber.uuid).then((data: FavoriteDrugsDetailResponse) => {

        if (data.favoriteDrugsDetail != undefined && data.favoriteDrugsDetail.length > 0) {
          //console.log("FavSearch:" + Object.values(data.favoriteDrugsDetail));
          this.loadSavedSearchHistory(data.favoriteDrugsDetail);
        }
      }, (error: any) => {
        this.modalService.dismissAll();
        this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs',backdrop:'static' });
      }).catch((reason: any) => {
        this.modalService.dismissAll();
        this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs',backdrop:'static' });
      });
    }
  }
  constructor(private memberSearchHelper: MembersearchService  ,private drugsearchHelper: DrugsearchService, private profileService: ProfileService, private modalService: NgbModal, private logger: LoggerService) { }
  savedSearchesDrugCodes: string[] = new Array();
  savedSearchHistory!: TableRowData<FavoriteDrugsDetail>[];
  savedSearchHeaders!: TableHeaderCellData[];
  nosavedMessage!: string;
  searchByNDC = "";
  cacheNDC = "";
  searching = false;
  loading = false;
  blnPharmacySelected: boolean = false;
  blnNDCFocus!: boolean;
  blnInSuffientSearchCriteria: boolean = false;
  blnShowHide: boolean = true;
  blnMemberEdit: boolean = false;
  blnDrugEdit: boolean = false;
  formLoading = false;
  invalidNDC = false;
  blnDrugSearchFormSubmitted: boolean = true;
  dropdown: DrugSearchItem[] = [];
  uniqueArr!: string[];
  medStrengthTitle = "";
  productList: DrugSearchItem[] = [];
  qtyPageToggle: boolean = false;
  currentSelectedDrug!: DrugSearchItem[];

  addMedicationForm: FormGroup = new FormGroup({
    drugSearchModel: new FormControl('', { validators: [Validators.required, Validators.minLength(3)], updateOn: 'blur' }),
    MedStrength: new FormControl('', { validators: [Validators.required] })
  })

  // Getters for formControls
  get drugSearchModel() {
    return this.addMedicationForm.get('drugSearchModel');
  }
  get MedStrength() {
    return this.addMedicationForm.get('MedStrength');
  }

  // To load savedSearch items into DataTable
  private loadSavedSearchHistory(rawData: FavoriteDrugsDetail[]): void {
    //debugger;

    rawData.forEach((history: FavoriteDrugsDetail) => {
      const row: TableRowData<FavoriteDrugsDetail> = new TableRowData<FavoriteDrugsDetail>();
      this.savedSearchesDrugCodes.push(history.drugCoded[0].code);
      const firstCell: TableCellData<FavoriteDrugsDetail> = new TableCellData();
      firstCell.data = history;
      firstCell.template = this.drugNameTemplateRef;
      row.cells.push(firstCell);

      const secondCell: TableCellData<FavoriteDrugsDetail> = new TableCellData();
      secondCell.data = history;
      secondCell.template = this.actionRemove;
      secondCell.clickable = false;
      row.cells.push(secondCell);

      const thirdCell: TableCellData<FavoriteDrugsDetail> = new TableCellData();
      thirdCell.data = history;
      thirdCell.template = this.actionSelect;
      row.cells.push(thirdCell);


      this.savedSearchHistory.push(row);
    });
  }
  
    // Show Recent Searches
     showRecentSeraches() {
      // this.logger.userAction("Opening Recent Searches.");
      // this.recentSearchHistory = [];
      // this.loadRecentSearchHistory(this.recentSearches);
      // this.modalService.open(this.recentSearch, { modalDialogClass: 'content', size: 'lg' });
    }

  // To handling sorting event on savedSearches headers click
  onSavedHeaderClick(headerData: TableClickEvent<TableHeaderCellData>) {

  }
  onSavedCellClick(cellData: TableClickEvent<FavoriteDrugsDetail>) {
    //debugger;
    if (cellData.column == 1 || cellData.event.target.getAttribute('name') == "remove") {
      this.logger.userAction("Removing Favourite Medication");
      //console.log(cellData.cell?.data?.drugDescription);
      this.removefavourite(cellData.cell?.data?.drugCoded[0].code);
    } else {
      // For Setting the Medication Name
      this.addMedicationForm.reset();
      this.logger.userAction("Selecting Favourite Medication");
      var productID: string | undefined = cellData.cell?.data?.drugCoded[0].code;
      //console.log("drugDetails:" + drugDetails);
      this.drugSearchModel?.setValue(cellData.cell?.data?.drugDescription);

      // For Setting the Mediaction Strength
      // var drugBrand!: any;
      // drugBrand = this.productList.find(a => a.displayName == this.addMedicationForm.get('drugSearchModel')?.value)?.brandName;
      let request: Drugsearchwebrequest = new Drugsearchwebrequest();
      request.ndc = "" + productID;
      this.drugsearchHelper.getDrugSearchItems(request).then((response: Drugsearchwebresponse) => {
        ////debugger;
        if (response.status.respCode == '200') {
          this.dropdown = response.response.drugSearchItems;
          this.formLoading = false;
          this.MedStrength?.enable();
          var drugItem: DrugSearchItem[] = this.dropdown.filter(data => data.productId == productID);
          this.MedStrength?.setValue(drugItem[0].productId);
          this.modalService.dismissAll();
          this.onChangeMediactionStrenth();
        } else {
          this.drugSearchModel?.setErrors({ 'incorrect': true });
          this.formLoading = false;
          this.dropdown = [];
        }
      }, (error: any) => {
        this.modalService.dismissAll();
        this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs',backdrop:'static' });
      }).catch((reason: any) => {
        this.modalService.dismissAll();
        this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs',backdrop:'static' });
      });
    }
  }

  showSavedSeraches() {
    debugger;
    this.logger.userAction("Opening Saved Searches.");
    this.savedSearchHistory = [];
    this.reloadSavedSearches();
    this.modalService.open(this.savedSearch, { modalDialogClass: 'content', size: 'lg',backdrop:'static' });
    if (this.savedSearchHistory.length == 0) {

      this.nosavedMessage = "No saved medications";
    }
  }
  reloadSavedSearches() {
    //debugger;
     if (this.profileService.loginuser?.prescriber?.presbrId != undefined && this.profileService.loginuser.prescriber.uuid != undefined) {
       this.drugsearchHelper.getFavouriteDrugs(this.profileService.loginuser.prescriber.presbrId, this.profileService.loginuser.prescriber.uuid).then((data: FavoriteDrugsDetailResponse) => {
 
         if (data.favoriteDrugsDetail != undefined && data.favoriteDrugsDetail.length > 0) {
           //console.log("FavSearch:" + Object.values(data.favoriteDrugsDetail));
           this.loadSavedSearchHistory(data.favoriteDrugsDetail);
         }
       }, (error: any) => {
         this.modalService.dismissAll();
         this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' ,backdrop:'static'});
       }).catch((reason: any) => {
         this.modalService.dismissAll();
         this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs',backdrop:'static' });
       });
     }
   }

  removefavourite(productID: string | undefined) {
    debugger;
    this.logger.userAction("Remove favourite Medication Clicked.");
    var temp: TableRowData<FavoriteDrugsDetail>[];
    temp = [];
    var drugInfoId: string | null | undefined = "";
    this.savedSearchHistory.forEach((element: TableRowData<FavoriteDrugsDetail>) => {
      var tempRow: TableRowData<FavoriteDrugsDetail> = new TableRowData<FavoriteDrugsDetail>();
      element.cells.forEach((item: TableCellData<FavoriteDrugsDetail>) => {
        //console.log(item.data?.drugCoded[0].code + '==' + productID);  //testing
        //console.log(item.data?.drugCoded[0].code == productID);  //testing

        if (item.data?.drugCoded[0].code == productID && drugInfoId == "") {
          //console.log("hello:" + item.data?.drugCoded[0].code + '==' + productID);
          //console.log("Item Data" + item.data?.drugInfoId);
          drugInfoId = item.data?.drugInfoId;

        } else {
          tempRow.cells.push(item);
        }
      });
      temp.push(tempRow);

    });
    this.savedSearchHistory = temp;
    //console.log("UnFavourite The Drug:" + drugInfoId + ":" + productID);



    let webrequest: RemoveFavRootObject = new RemoveFavRootObject();
    let medicationPrescribed: MedicationPrescribed = new MedicationPrescribed();
    medicationPrescribed.drugInfoId = drugInfoId;
    webrequest.medicationPrescribed = medicationPrescribed;
    webrequest.loggedPresbrId = "" + this.profileService.loginuser?.prescriber?.presbrId;
    webrequest.loggedUuid = "" + this.profileService.loginuser?.prescriber?.uuid;

    this.drugsearchHelper.removeFavouriteDrug(webrequest).then((data: FavDrugStatusResponse) => {
      //console.log(data);
      if (data.status.statusCode == "Success") {
        this.savedSearchesDrugCodes = this.savedSearchesDrugCodes.filter(data => data != productID);
        this.savedSearchHistory = [];
        this.reloadSavedSearches();
      }
    }, (error: any) => {
      this.modalService.dismissAll();
      this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs',backdrop:'static' });
    }).catch((reason: any) => {
      this.modalService.dismissAll();
      this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' ,backdrop:'static'});
    });
  }
  searchNDC = (text$: any) =>
    text$.pipe(
      debounceTime(200),
      tap(() => { this.searching = true; this.invalidNDC = false; }),
      distinctUntilChanged(),
      switchMap(async (term: string) => term.length > 2 ? await this.searchTerm(term)
        : []
      ), tap(() => { this.searching = false; }));


  searchTerm(drugBrand: any): Promise<any> {
    const promise: Promise<string[]> = new Promise<string[]>((resolve, reject) => {
      let request: Drugsearchwebrequest = new Drugsearchwebrequest();
      debugger;
      this.invalidNDC = false;
      this.searchByNDC = "";
      this.dropdown = [];
      this.uniqueArr = [];
      this.MedStrength?.reset();
      this.MedStrength?.disable();
      this.medStrengthTitle = "";
      if (isNaN(drugBrand))
        request.drugName = drugBrand;
      else
        request.ndc = drugBrand;
      request.instanceId = ""+this.memberSearchHelper.getSelectedMember()?.instanceId;
      this.drugsearchHelper.getDrugSearchItems(request).then((response: Drugsearchwebresponse) => {
        debugger;
        if (response.status.respCode == '200') {
          //debugger;
          //console.log("Item  found:" + value);
          this.productList = response.response.drugSearchItems;
          // this.selectedMedicine[0].drugSearchItem = response.response.drugSearchItems[0];
          if (this.productList.length > 1) {
            debugger;
            this.uniqueArr = [...new Set(this.productList.map(data => data.productNameExt))];
            resolve(this.uniqueArr.filter(v => v?.toLowerCase().indexOf(drugBrand?.toLowerCase()) > -1).slice(0, 10));
          } else {
            debugger;
            this.uniqueArr = [...new Set(this.productList.map(data => data.productNameExt).concat([...new Set(this.productList.map(data => data.productId))]))];
            // resolve(this.uniqueArr.filter(v => v?.toLowerCase().indexOf(drugBrand?.toLowerCase()) > -1).slice(0, 10)); 
            this.searching = false;
            this.MedStrength?.disable();
            this.dropdown = this.productList;
            if (drugBrand == this.productList[0].productId) {
              this.searchByNDC = this.productList[0].productId;
              this.drugSearchModel?.setValue("" + this.productList[0].productId);
              this.MedStrength?.setValue(this.productList[0].productId);
              this.onChangeNDC(drugBrand);
              // sessionStorage.setItem("DrugDetails",""+this.uniqueArr);

            } else {
              this.drugSearchModel?.setValue("" + this.uniqueArr[0]);
            }
            resolve([]);
          }
        } else {
          //console.log("Not  found:");
          //debugger;
          this.invalidNDC = true;
          this.drugSearchModel?.setErrors({ 'incorrect': true });
          this.formLoading = false;
          this.dropdown = [];
          this.uniqueArr = [];
          this.MedStrength?.reset();
          this.MedStrength?.disable();
          resolve(this.uniqueArr);
        }
      }, (error: any) => {
        //debugger;
        this.modalService.dismissAll();
        this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs',backdrop:'static' });
      }).catch((reason: any) => {
        //debugger;
        this.modalService.dismissAll();
        this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs',backdrop:'static' });
      });


    });
    return promise;
  }

  itemSelected(event: any) {
    //console.log("Selected Event" + JSON.stringify(event));
    debugger;
    let medName:string="";
    if(event.item==undefined){
      medName=this.drugSearchModel?.value;
    }else{
      medName=event.item;
    }
    if(this.uniqueArr.length>0 && this.uniqueArr.filter(v=>v==medName).length>0){
      this.drugSearchModel?.patchValue(medName);
      this.onChangeNDC(medName);
    }else if(medName.length>2){
          this.invalidNDC=true;
          this.drugSearchModel?.setErrors({ 'incorrect': true });
    }
  }
    //  To update form details on selecting Medication Strength
    onChangeMediactionStrenth(productID: string = "") {
      debugger;
      if (productID == "") {
        productID = ""+this.addMedicationForm.get('MedStrength')?.value;
      }
      //console.log("MedStrength:" + productID);
      if (productID == "") {
        //console.log("default" + productID);
        this.qtyPageToggle = false;
        this.currentSelectedDrug = [];
      } else if (productID != "") {
        this.formLoading = true;
        this.modalService.open(this.loadingContent, { modalDialogClass: 'content',windowClass:'loadingContent',backdrop:'static'});
  
        let request: Drugsearchwebrequest = new Drugsearchwebrequest();
        request.ndc = productID;
        request.instanceId = ""+this.memberSearchHelper.getSelectedMember()?.instanceId;
        this.drugsearchHelper.getDrugSearchItems(request).then((response: Drugsearchwebresponse) => {
          //console.log("Selected ITem:" + response);
          this.currentSelectedDrug = response.response.drugSearchItems;
          this.medStrengthTitle = this.currentSelectedDrug[0].detailedDrugName;
          this.formLoading = false;
          this.MedStrength?.enable();
          this.qtyPageToggle = true;
          // this.selectedMedicine[0].drugSearchItem = response.response.drugSearchItems[0];
          // Adding unique items to recent seraches array
          // if (this.recentSearches.length == 0) {
          //   this.recentSearches.push(this.currentSelectedDrug[0]);
          // } else if (this.recentSearches.map((drugItem) => drugItem.productId).indexOf(this.currentSelectedDrug[0].productId) == -1) {
          //   this.recentSearches.push(this.currentSelectedDrug[0]);
         // }
          // End of Adding unique items to recent seraches array
  
          //console.log("Recent:" + JSON.stringify(this.recentSearches.length));
          this.modalService.dismissAll();
          productID = "";
        }, (error: any) => {
          ////debugger;
          this.currentSelectedDrug = this.dropdown.filter((data: DrugSearchItem) => data.productId == productID);
          this.medStrengthTitle = this.currentSelectedDrug[0].detailedDrugName;
          this.formLoading = false;
          this.MedStrength?.enable();
          this.qtyPageToggle = true;
  
          // Adding unique items to recent seraches array
          // if (this.recentSearches.length == 0) {
          //   this.recentSearches.push(this.currentSelectedDrug[0]);
          // } else if (this.recentSearches.map((drugItem) => drugItem.productId).indexOf(this.currentSelectedDrug[0].productId) == -1) {
          //   this.recentSearches.push(this.currentSelectedDrug[0]);
          // }
          // End of Adding unique items to recent seraches array
  
          // console.log("Recent:" + JSON.stringify(this.recentSearches.length));
          this.modalService.dismissAll();
          productID = "";
        }).catch((reason: any) => {
          ////debugger;
          productID = "";
          this.modalService.dismissAll();
          this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' ,backdrop:'static'});
        });
        //  //console.log("Detailed Drug Name" + this.currentSelectedDrug[0].detailedDrugName);
        //console.log(this.currentSelectedDrug);
        //console.log("blank" + productID);
      }
    }
      //  // To update Medication Strength dropdown on selecting NDC 
   onChangeNDC(value: string) {
    this.medStrengthTitle = "";
    if (this.drugSearchModel?.invalid) {
      this.MedStrength?.reset();
      this.MedStrength?.disable();

      this.dropdown = [];
      //console.log("Invalid Value:" + value)
    }else if(this.drugSearchModel?.valid == true) {
      this.cacheNDC = ""+this.drugSearchModel.value;
      this.qtyPageToggle = false;
      if (this.cacheNDC != this.drugSearchModel.value) {
        this.formLoading = true;
      }
      var drugBrand!: any;
      drugBrand =  this.addMedicationForm.get('drugSearchModel')?.value;
      if(isNaN(drugBrand)){
        this.dropdown = this.productList.filter(v=>v.productNameExt==drugBrand);
      }
          if (drugBrand == undefined || this.drugSearchModel?.errors) {
            this.dropdown = [];
            this.MedStrength?.reset();
            this.MedStrength?.disable();
          } else if (this.dropdown.length == 1) {
            this.MedStrength?.disable();
            this.MedStrength?.setValue(this.dropdown[0].productId);
            this.onChangeMediactionStrenth();
          } else {
            this.dropdown.forEach((item: DrugSearchItem) => {
              if (item.productId == this.drugSearchModel?.value) {
                //debugger;
                this.MedStrength?.disable();
                this.MedStrength?.setValue(item.productId);
                this.onChangeMediactionStrenth();
                this.searchByNDC = item.detailedDrugName;
              }
            });
          }
          this.MedStrength?.setValue("");
          this.MedStrength?.enable();
          } else {
      // console.log("Gone to else part:" + value);
    }
    return this.dropdown;
  }

}


