import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { MemberPAHistorywebrequest, PAHistorywebrequest } from './../../modals/pahistory/pahistorywebrequest';
import { MemberPAHistorywebresponse, PAHistorywebresponse } from './../../modals/pahistory/pahistorywebresponse';
import { APPCONSTANTS } from 'src/app/constants/appconstants';
import { environment } from 'src/environments/environment';
import { LoggerService } from '../logger/logger.service';
import { ProfileService } from '../profile/profile.service';

@Injectable({
  providedIn: 'root'
})
export class PahistoryService {

  constructor(private http: HttpClient, private logger: LoggerService, private profileService:ProfileService) { }

  getSubmittedPAHistory(webrequest: PAHistorywebrequest): Promise<PAHistorywebresponse> {
    const promise: Promise<PAHistorywebresponse> = new Promise<PAHistorywebresponse>((resolve, reject) => {

        let header=new HttpHeaders({"endpoint":environment.submittedPAHistoryUrl,"X-CSRF-Token":this.profileService.getCsrfToken()});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any)=>{
        if (response.status != undefined
          && response.status.statusCode != undefined
          && response.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
          && response.status.dbStatusCode != undefined
          && response.status.dbStatusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
        ) {
          resolve(response);
        } else {
          this.logger.log(response);
          reject(response);
        }
      }, (error: any) => {
        this.logger.error(error);
        reject(error);
      });
    });
    return promise;
  }
  getMemberSubmittedPAHistory(webrequest: MemberPAHistorywebrequest): Promise<MemberPAHistorywebresponse> {
    const promise: Promise<MemberPAHistorywebresponse> = new Promise<MemberPAHistorywebresponse>((resolve, reject) => {
      
     // this.http.post(environment.submittedMemberPAHistoryUrl, webrequest).subscribe((response: any) =>
        let header=new HttpHeaders({"endpoint":environment.submittedMemberPAHistoryUrl,"X-CSRF-Token":this.profileService.getCsrfToken()});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any)=>
         {
        if (response.status != undefined
          && response.status.statusCode != undefined
          && response.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
          resolve(response);
        } else {
          this.logger.log(response);
          reject(response);
        }
      }, (error: any) => {
        this.logger.error(error);
        reject(error);
      });
    });
    return promise;
  }
  getSavedPAHistory(webrequest: PAHistorywebrequest): Promise<PAHistorywebresponse> {
    const promise: Promise<PAHistorywebresponse> = new Promise<PAHistorywebresponse>((resolve, reject) => {

        let header=new HttpHeaders({"endpoint":environment.savedPAHistoryUrl,"X-CSRF-Token":this.profileService.getCsrfToken()});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any)=>{
        if (response.status != undefined
          && response.status.statusCode != undefined
          && response.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
          && response.status.dbStatusCode != undefined
          && response.status.dbStatusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
        ) {
          resolve(response);
        } else {
          this.logger.log(response);
          reject(response);
        }
      }, (error: any) => {
        this.logger.error(error);
        reject(error);
      });
    });
    return promise;
  }

  getMemberSavedPAHistory(webrequest: MemberPAHistorywebrequest): Promise<MemberPAHistorywebresponse> {
    const promise: Promise<MemberPAHistorywebresponse> = new Promise<MemberPAHistorywebresponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.savedMemberPAHistoryUrl,"X-CSRF-Token":this.profileService.getCsrfToken()});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any)=>
        {
        //this.http.get("./assets/translation_en.json").subscribe((response:any)=>{
        if (response.status != undefined
          && response.status.statusCode != undefined
          && response.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
          && response.status.dbStatusCode != undefined
          && response.status.dbStatusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
        ) {
          resolve(response);
        } else {
          this.logger.log(response);
          reject(response);
        }
      }, (error: any) => {
        this.logger.error(error);
        reject(error);
      });
    });
    return promise;
  }
}
